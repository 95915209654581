import { setStorage, removeStorage, setToken, setRefreshToken } from '@/core/utils';
export default {
  namespaced: true,
  state: {
    name: '',
    token: '', // token
    userInfo: '', // 用户信息
    refresh_token: ''
  },
  mutations: {
    SET_USER_INFO(state, value) {
      state.userInfo = Object.assign({}, state.userInfo, value);
      setStorage('userInfo', state.userInfo);
    },
    REMOVE_USER_INFO(state, value) {
      state.userInfo = null;
    },
    SET_TOKEN(state, value) {
      state.token = value;
      setToken(state.token);
    },
    SET_REFRESHTOKEN(state, value) {
      state.refresh_token = value;
      setRefreshToken(state.refresh_token);
    },
    SET_NAME: (state, name) => {
      state.name = name;
      localStorage.setItem('name', state.name)
    },
    REMOVE_TOKEN(state, value) {
      state.token = null;
    },
    REMOVE_REFRESHTOKEN(state, value) {
      state.refresh_token = null;
    }
  },
  actions: {
    login({ commit }, userInfo) {
      const { username, password } = userInfo
      return new Promise((resolve, reject) => {
        login({ username: username.trim(), password: password }).then(response => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    setUserInfo({ commit, state, dispatch }, value) {
      commit('SET_USER_INFO', value);
    },
    setToken({ commit, state, dispatch }, value) {
      commit('SET_TOKEN', value);
    },
    setRefreshToken({ commit, state, dispatch }, value) {
      commit('SET_REFRESHTOKEN', value);
    },
    setName({ commit }, name) {
      commit('SET_NAME', name)
    },
    removeToken({ commit, state, dispatch }, value) {
      commit('REMOVE_TOKEN');
    },
    removeRefreshToken({ commit, state, dispatch }, value) {
      commit('REMOVE_REFRESHTOKEN');
    },
    removeUserInfo({ commit, state, dispatch }, value) {
      commit('REMOVE_USER_INFO');
    },
    loginOut({ commit, state, dispatch }, value) {
      commit('REMOVE_USER_INFO');
      commit('REMOVE_TOKEN');
    }
  }
};
