import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/callback',
    // path: '/tyLogin',
    component: () => import('@/views/tyLogin/index'),
    hidden: true
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/search/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/app/404'),
    hidden: true
  },
  {
    path: '/505',
    component: () => import('@/views/app/505'),
    hidden: true
  },
]

export default routes
