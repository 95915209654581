<template>
  <el-skeleton-item variant="image" :style="`height: ${imgHeight}px`" />
</template>

<script>
export default {
  name: 'SkeletonImage',
  props: {
    imgWidth: {
      type: Number,
      default: 200
    },
    imgHeight: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss"></style>
