import Vue from 'vue';
import ElementUI from 'element-ui';
import contentmenu from 'v-contextmenu';
import VueClipboard from 'vue-clipboard2';
import i18n from '@/core/lang';
import * as analytics from './analytics';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/rubyblue.css';
import 'v-contextmenu/dist/index.css';
import { VMdEditor, VMdPreview } from './markdown';
// 注册
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});
Vue.use(contentmenu);
Vue.use(VueClipboard);
Vue.use(VMdEditor);
Vue.use(VMdPreview);
Vue.prototype.$analytics = analytics;
