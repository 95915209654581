import i18n from '../../core/lang';

const attribute = {
  state: {
    color: [
      // 默认的颜色数组
      '#5470c6',
      '#91cc75',
      '#fac858',
      '#ee6666',
      '#73c0de',
      '#3ba272',
      '#fc8452',
      '#9a60b4',
      '#ea7ccc'
    ],
    lineType: [
      {
        name: i18n.t('store.solid'),
        value: 'solid'
      },
      {
        name: i18n.t('store.dashed'),
        value: 'dashed'
      },
      {
        name: i18n.t('store.dotted'),
        value: 'dotted'
      }
    ],
    symbol: [
      // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'none'
      {
        name: i18n.t('store.none'),
        value: 'none',
        icon: ''
      },
      {
        name: i18n.t('store.circle'),
        value: 'circle',
        icon: 'ide-circle'
      },
      {
        name: i18n.t('store.rect'),
        value: 'rect',
        icon: 'ide-rectangle'
      },
      {
        name: i18n.t('store.triangle'),
        value: 'triangle',
        icon: 'ide-triangle'
      },
      {
        name: i18n.t('store.diamond'),
        value: 'diamond',
        icon: 'ide-lingxing'
      }
    ],
    // 线宽
    lineWidth: [
      {
        name: i18n.t('store.single'),
        value: 2
      },
      {
        name: i18n.t('store.double'),
        value: 4
      },
      {
        name: i18n.t('store.quadruple'),
        value: 8
      }
    ],
    styleArray: [
      {
        name: i18n.t('store.unfill'),
        value: ''
      },
      {
        name: i18n.t('store.solid1'),
        value: 'solid'
      },
      {
        name: i18n.t('store.level'),
        value: 'horizontal'
      },
      {
        name: i18n.t('store.degree45'),
        value: 'forward'
      },
      {
        name: i18n.t('store.degree_45'),
        value: 'backward'
      },
      {
        name: i18n.t('store.line'),
        value: 'crossDiag'
      },
      {
        name: i18n.t('store.horizontal'),
        value: 'horizontalCyliner'
      },
      {
        name: i18n.t('store.vertical'),
        value: 'verticalCylinder'
      },
      {
        name: i18n.t('store.ball'),
        value: 'sphere'
      }
    ],
    grapicLineType: [
      {
        name: i18n.t('store.solid'),
        value: 'solid'
      },
      {
        name: i18n.t('store.dashed'),
        value: 'dash'
      },
      {
        name: i18n.t('store.dotted'),
        value: 'dot'
      },
      {
        name: i18n.t('store.dashDotL'),
        value: 'dashDot'
      },
      {
        name: i18n.t('store.dashDotDotLine'),
        value: 'dashDotDotLine'
      }
    ],
    fontNameType: [
      {
        name: i18n.t('store.fangsong')
      },
      {
        name: i18n.t('store.song')
      },
      {
        name: i18n.t('store.black')
      },
      {
        name: i18n.t('store.regular')
      }
    ]
  },
  getters: {
    colorArray(state) {
      return state.color;
    },
    lineTypeArray(state) {
      return state.lineType;
    },
    symbolArray(state) {
      return state.symbol;
    },
    lineWidthArray(state) {
      return state.lineWidth;
    },
    styleArray(state) {
      return state.styleArray;
    },
    grapicLineTypeArray(state) {
      return state.grapicLineType;
    },
    fontNameArray(state) {
      return state.fontNameType;
    }
  },
  mutations: {}
};

export default attribute;
