import Vue from 'vue';
import './global';
import './config';
// import './server';
import * as utils from './utils';
import bus from './eventBus';
import * as analytics from './analytics';
Vue.prototype.$utils = utils;
Vue.prototype.$bus = bus;
Vue.prototype.$analytics = analytics;
