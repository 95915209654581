import i18n from '../../core/lang';
export default {
  namespaced: true,
  state: {
    // 仓库角色
    roles: [
      {
        level: 1,
        value: 'ROLE_PROJECT_READ',
        label: i18n.t('components.role.observer')
      },
      {
        level: 2,
        value: 'ROLE_PROJECT_EDIT',
        label: i18n.t('components.role.developer')
      },
      {
        level: 3,
        value: 'ROLE_PROJECT_MANAGE',
        label: i18n.t('components.role.controller')
      },
      {
        level: 4,
        value: 'ROLE_PROJECT_OWNER',
        label: i18n.t('components.role.owner')
      }
    ],
    // 仓库类型
    types: [],
    // 成员申请状态
    auditStatus: [
      {
        color: 'green',
        value: 'PASS',
        joinType: 'JOIN_VIA_LINK',
        label: i18n.t('store.pass')
      },
      {
        color: '#333',
        value: 'PROCESSING',
        joinType: 'JOIN_VIA_LINK',
        label: i18n.t('store.applying')
      },
      {
        color: 'red',
        value: 'FAIL',
        joinType: 'JOIN_VIA_LINK',
        label: i18n.t('store.unpass')
      },
      {
        color: 'green',
        value: 'PASS',
        joinType: 'ADD_DIRECTLY',
        label: i18n.t('store.invited')
      },
      {
        color: '#333',
        value: 'PROCESSING',
        joinType: 'ADD_DIRECTLY',
        label: i18n.t('store.inviting')
      },
      {
        color: 'red',
        value: 'FAIL',
        joinType: 'ADD_DIRECTLY',
        label: i18n.t('store.refuse')
      }
    ]
  },
  mutations: {
    SET_TYPES: (state, types) => {
      state.types = types;
    }
  },
  actions: {
    setTypes({ commit }, types) {
      commit('SET_TYPES', types);
    }
  }
};
