<template>
  <div class="pagination-wrap" v-if="total">
    <el-pagination
      background
      hide-on-single-page
      :layout="pageLayout"
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="pageSizes"
      :total="total"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    pageLayout: {
      type: String,
      default: 'total, prev, pager, next, jumper'
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 40, 50, 100]
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  },
  methods: {
    currentChange(pageIndex) {
      this.$emit('current-change', pageIndex);
    },
    sizeChange(pageSize) {
      this.$emit('size-change', pageSize);
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-wrap {
  text-align: center;
  margin-top: 40px;
}
::v-deep .el-pagination__editor {
  margin-right: 8px;
}
</style>
