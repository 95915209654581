import Vue from 'vue';
import VueRouter from 'vue-router';
import * as utils from '../core/utils';
import store from '../store/index';
import * as api from '@/service/api';
import staticRoute from './static-route.js';
Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes: staticRoute,
  base: process.env.BASE_URL
});
function addRoutes(arr) {
  const routes = router.getRoutes();
  arr.forEach((i) => {
    // console.log(!routes.find(j => j.path === i.path), i)
    if (!routes.find((j) => j.path === i.path)) {
      router.addRoute(i);
    }
  });
}
let loadingInstance;
const publicPath = ['/support/ticket'];
// 处理跳转重复路由报错
const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const originalReplace = router.replace;
router.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

// 钩子函数
router.beforeEach(async (to, from, next) => {
  console.log('111to.path', to.path, to);
  if (to.path === '/support/ticket/new') {
    let str = '/support/ticket?path=new';
    console.log('工单工单', to.path, to.query, window.history);
    if (to.query.product) {
      str = str + '&product=' + to.query.product;
    }
    if (to.query.software) {
      str = str + '&software=' + to.query.software;
    }
    if (to.query.sysinfo) {
      str = str + '&sysinfo=' + to.query.sysinfo;
    }
    if (to.query.token && to.query.token !== 'undefined') {
      // 客户端携带token覆盖原有的用户信息
      utils.setToken(to.query.token);
      utils.removeRefreshToken();
      const res = await api.userinfo();
      console.log('ressss', res);
      store.dispatch('app/setUserInfo', res.data);
    }
    next(str);
    return;
  } else if (to.query.token && to.query.token !== 'undefined') {
    // 携带token覆盖原有的用户信息
    utils.setToken(to.query.token);
    utils.removeRefreshToken();
    const res = await api.userinfo();
    console.log('resUserInfo', res);
    store.dispatch('app/setUserInfo', res.data);
    next(to.path);
    return;
  }
  // 判断路由是否需要登录
  if (publicPath.includes(to.path)) {
    let ret = '';
    // 未登录给出提示
    if (!utils.isLogin()) {
      if (to.query.path) {
        for (let it in to.query) {
          ret +=
            encodeURIComponent(it) +
            '=' +
            encodeURIComponent(to.query[it]) +
            '&';
        }
      } else {
        ret = 'path=mine';
      }
      let path = to.path + '?' + ret;
      utils.openLogin({ path: path });
      return;
    }
  }
  next(true);
});

router.afterEach((to) => {
  if (loadingInstance) {
    loadingInstance.close();
  }
});

export default { router, addRoutes };
