import Vue from 'vue';
import VueRouter from 'vue-router';
import * as utils from './utils';
import NotFoundComponent from '../views/app/404';
import ErrorComponent from '../views/app/505';
import ViewComponent from '../views/app/200';
let _router = null;
let _routes = [
  {
    path: '/404',
    component: NotFoundComponent
  },
  {
    path: '/505',
    component: ErrorComponent
  },
  {
    path: '/200',
    component: ViewComponent
  }
];

export function addDecorator(_path, _component, _isLogin) {
  if (_component == undefined) {
    _component = ViewComponent;
  }
  _routes.push({
    path: _path,
    component: _component,
    meta: {
      isLogin: _isLogin
    }
  });
}
const publicPath = [
  '/products',
  '/about'
];
export function routerInit() {
  console.log(_routes, '_routes');
  if (_router == null) {
    _router = new VueRouter({
      mode: 'history',
      routes: _routes,
      base: process.env.BASE_URL,
      scrollBehavior(to, from, savedPosition) {
        return {
          x: 0,
          y: 0
        };
      }
    });

    /**
     * 处理跳转重复路由报错
     */
    const originalPush = _router.push;
    _router.push = function push(location) {
      return originalPush.call(this, location).catch(err => err);
    };

    _router.beforeEach((to, from, next) => {
      if (to && to.meta) {
        if (system.token == null && to.meta.isLogin == true) {
          // 判断是否需要登录
          if (!publicPath.includes(to.path)) {
            utils.openLogin();
            return;
          }
        }
      }
      if (_router.before) {
        _router.before(to);
      }
      next(true);
    });

    _router.afterEach(to => {
      if (_router.after) {
        _router.after(to);
      }
    });
  }
  return _router;
}

export function getRouter() {
  return _router;
}
Vue.use(VueRouter);
