import axios from './interceptors';
axios.defaults.timeout = 1000 * 60 * 5;
const baseUrl = '/';
export function get(url, params = {}, headers = {}) {
  let URL = baseUrl + url;
  if (url.includes('http')) {
    URL = url;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(URL, { params, headers })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(err, 'err');
        reject(err);
      });
  });
}

export function post(url, params = {}, headers = {}) {
  let URL = baseUrl + url;
  if (url.includes('http')) {
    URL = url;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(URL, params, { headers })
      .then(res => {
        resolve(res.data);
        // console.log(res, 'res');
        // 报错弹出提示
        // if (res.data.code == 1) {
        //   notify(res.data.message, 'warning');
        // }
      })
      .catch(err => {
        console.log(err, 'error');
        reject(err);
      });
  });
}
