function clone(targetobj) {
  var targetproto = Object.getPrototypeOf(targetobj);
  return Object.assign(Object.create(targetproto), targetobj);
}
export default {
  namespaced: true,
  state: {
    view: 'model', // 工作空间视图-建模|仿真结果查看
    mainView: 'graph', // 视图模式-模型|曲线|拆分
    projectData: null, // 项目数据
    animateData: {
      taskId: null,
      startTime: 0,
      speed: '1'
    }, // 动画数据
    metaData: {}, // 元数据
    modified: false, // 编辑状态
    actions: {}, // 事件集合
    scale: 1 // 视图缩放
  },
  getters: {},
  mutations: {
    VIEW(state, value) {
      state.view = value;
    },
    MAIN_VIEW(state, value) {
      state.mainView = value;
    },
    PROJECT_DATA(state, value) {
      state.projectData = Object.assign({}, state.projectData, value);
    },
    META_DATA(state, value) {
      state.metaData = Object.assign({}, state.metaData, value);
    },
    ACTIONS(state, value) {
      // 复制原型链属性
      state.actions = clone(value);
    },
    SCALE(state, value) {
      state.scale = value;
    },
    ANIMATE(state, value) {
      state.animateData = Object.assign({}, state.animateData, value);
    }
  }
};
