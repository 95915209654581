<template>
  <div class="page">
    <img src="../../assets/images/400.png" />
    <div class="content">
      <div class="title">
        {{ $t('app.message404') }}
      </div>
      <el-button class="btn" type="primary" @click="handleClick">{{
        $t('app.goBack')
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() { },
  methods: {
    handleClick() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped lang="scss">
.page {
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page img {
  height: 300px;
  margin: 50px;
}

.title {
  font-size: 36px;
  font-weight: 600;
}

.btn {
  margin-top: 60px;
  border-radius: 20px;
}

@media screen and (max-width: 700px) {
  .page {
    display: block;
    text-align: center;
  }

  .page img {
    height: 30vw;
    margin: 5vw;
  }

  .title {
    font-size: 5vw;
  }
}</style>
