import * as axios from './axios';
import { decryptDes, getToken } from '@/core/utils';

export function get(url, params) {
  return axios.get(url, params);
}

export function post(url, params) {
  return axios.post(url, params);
}

/**
 * 获取路由信息
 */
export function getHeader() {
  return axios.get('official/view/getTabs');
}

/**
 * 获取界面信息
 * @param {*} router
 */
export function getPage(params) {
  return axios.get('official/view/getPageByRouter', params);
}
/**
 * 获取主页信息
 */
export function getHomePage() {
  return axios.get('official/view/getHomePage');
}
/**
 * 获取案例类型
 */
export function getCaseType() {
  return axios.get('official/case/getCaseTypeList');
}
/**
 * 获取案例列表
 * @param {*} router
 */
export function getCaseList(params) {
  return axios.get('official/case/getCaseListByRouter', params);
}
/**
 * 获取工具箱列表
 */
export function getToolboxList() {
  return axios.get('official/module/getToolboxList');
}
/**
 * 获取模型库列表
 */
export function getLibraryList() {
  return axios.get('official/module/getLibraryList');
}
/**
 * 获取产品下载列表
 */
export function getProducts() {
  return axios.get('official/product/getProducts');
}
/**
 * 获取历史产品下载列表
 */
export function getHistoryProducts() {
  return axios.get('official/product/getHistoryProducts');
}
/**
 * 下载产品
 * @param {*} path
 */
export function downloadFile(params) {
  return axios.get('official/product/download', params);
}
/**
 * 获取产品信息
 * @param {*} id
 */
export function getProductInfo(params) {
  return axios.get('official/product/getProductInfoById', params);
}
/**
 * 获取教程产品列表
 */
export function getCourseProductList() {
  return axios.get('official/course/getCourseProductList');
}
/**
 * 获取教程难度列表
 */
export function getCourseDifficultList() {
  return axios.get('official/course/getCourseDifficultList');
}
/**
 * 获取教程专题列表
 */
export function getCourseProjectList() {
  return axios.get('official/course/getCourseProjectList');
}
/**
 * 获取路由列表
 */
export function getRouters() {
  return axios.get('official/view/getRouters');
}
/**
 * 课程分页
 *
 */
export function pageListCourse(params) {
  return axios.post('official/course/pageListCourse', params);
}
/**
 * 获取视频列表
 * @param {*} id
 */
export function getVideoList(params) {
  return axios.get('official/course/getVideoListById', params);
}
/**
 * 获取视频
 * @param {*} path
 */
export function getVideo(params) {
  return axios.get('official/course/getMP4FileBytesByRange', params);
}
/**
 * 获取搜索内容
 */
export function searchAll(params) {
  return axios.get('official/search/all', params);
}
/**
 * 分类搜索
 * @param {String} queryString 关键词
 * @param {String} pageIndex 分页
 * @param {String} pageSize 分页数
 * @param {String} resourceType 类型
 *
 */
export function search(params) {
  return axios.get('official/search/query', params);
}
/**
 * 数据埋点
 */
export function addEventMessage(params) {
  return axios.post('official/event/addEventMessage', params);
}
/**
 * 获取新闻动态列表详情
 */
export function getNews(id) {
  return axios.get('official/news/getNewsContent', id);
}

/**
 * 获取新闻动态列表(分页)
 */
export function getNewsList(params) {
  return axios.post('official/news/listPageNews', params);
}
export function getAuthentication(params) {
  return axios.get('official/platform/getAuthentication', params);
}
// export function oauth2Token(params) {
//   let host = 'http://172.16.1.146:8080';
//   let ret = '';
//   for (let it in params) {
//     ret += encodeURIComponent(it) + '=' + encodeURIComponent(params[it]) + '&'
//   }
//   let headers = {
//     // 测试
//     Authorization: 'Basic YTViNDljNWE4OWYzNDE3N2EyN2M6MDVmNWU2ZTI5N2JhNDc4ZGJhMGM='
//     // 本地
//     // Authorization: 'Basic OGFkYTM3YzAyNjFmNGNlOWJjYWI6MDBiYzcxM2MwZWYyNDIxNWIwZGQ='
//     // Authorization: authorization
//   }
//   return axios.post(`${host}/authentication/oauth2/token`, ret, headers);
// }

export function userinfo(params) {
  let host = process.env.VUE_APP_HOST;
  let headers = {
    Authorization: 'Bearer ' + getToken()
  };
  return axios.post(`${host}/authentication/userinfo`, params, headers);
}
export function refreshToken(params) {
  return axios.get('official/platform/refreshToken', params);
}
