import * as rpc from 'ty-vscode-ws-jsonrpc';
import ReconnectingWebSocket from 'reconnecting-websocket';
import i18n from '@/core/lang';
import store from '@/store';
var WEBSOCKET, CONNECTION, myQueue;
var errCodeList = [
  {
    code: 1001000,
    message: i18n.t('WebSocket.message')
  },
  {
    code: 1011001,
    message: i18n.t('WebSocket.message1')
  },
  {
    code: 1011002,
    message: i18n.t('WebSocket.message2')
  },
  {
    code: 1011003,
    message: i18n.t('WebSocket.message3')
  },
  {
    code: 1011004,
    message: i18n.t('WebSocket.message4')
  },
  {
    code: 1021001,
    message: i18n.t('WebSocket.message5')
  },
  {
    code: 1031001,
    message: i18n.t('WebSocket.message6')
  },
  {
    code: 1031002,
    message: i18n.t('WebSocket.message7')
  },
  {
    code: 1031003,
    message: i18n.t('WebSocket.message8')
  },
  {
    code: 1041001,
    message: i18n.t('WebSocket.message9')
  },
  {
    code: 1051001,
    message: i18n.t('WebSocket.message10')
  },
  {
    code: 1051002,
    message: i18n.t('WebSocket.message11')
  },
  {
    code: 1061001,
    message: i18n.t('WebSocket.message12')
  },
  {
    code: 1061002,
    message: i18n.t('WebSocket.message13')
  },
  {
    code: 1071001,
    message: i18n.t('WebSocket.message14')
  },
  {
    code: 1081001,
    message: i18n.t('WebSocket.message15')
  },
  {
    code: 1081002,
    message: i18n.t('WebSocket.message16')
  },
  {
    code: 1091001,
    message: i18n.t('WebSocket.message17')
  },
  {
    code: 1091001,
    message: i18n.t('WebSocket.message18')
  },
  {
    code: 1101001,
    message: i18n.t('WebSocket.message19')
  },
  {
    code: 1111001,
    message: i18n.t('WebSocket.message20')
  },
  {
    code: 1111002,
    message: i18n.t('WebSocket.message21')
  },
  {
    code: 1111003,
    message: i18n.t('WebSocket.message22')
  },
  {
    code: 1111004,
    message: i18n.t('WebSocket.message23')
  },
  {
    code: 1111005,
    message: i18n.t('WebSocket.message24')
  },
  {
    code: 1111006,
    message: i18n.t('WebSocket.message25')
  },
  {
    code: 1111007,
    message: i18n.t('WebSocket.message26')
  },
  {
    code: 1111008,
    message: i18n.t('WebSocket.message27')
  },
  {
    code: 1111009,
    message: i18n.t('WebSocket.message28')
  },
  {
    code: 1111010,
    message: i18n.t('WebSocket.message29')
  },
  {
    code: 1121001,
    message: i18n.t('WebSocket.message30')
  },
  {
    code: 1121002,
    message: i18n.t('WebSocket.message31')
  },
  {
    code: 1131001,
    message: i18n.t('WebSocket.message32')
  },
  {
    code: 1141001,
    message: i18n.t('WebSocket.message33')
  },
  {
    code: 1151001,
    message: i18n.t('WebSocket.message34')
  },
  {
    code: 1151002,
    message: i18n.t('WebSocket.message35')
  },
  {
    code: 1161001,
    message: i18n.t('WebSocket.message36')
  },
  {
    code: 1161002,
    message: i18n.t('WebSocket.message37')
  },
  {
    code: 1161003,
    message: i18n.t('WebSocket.message38')
  },
  {
    code: 1161004,
    message: i18n.t('WebSocket.message39')
  },
  {
    code: 1171001,
    message: i18n.t('WebSocket.message40')
  },
  {
    code: 1171002,
    message: i18n.t('WebSocket.message41')
  },
  {
    code: 1171003,
    message: i18n.t('WebSocket.message42')
  },
  {
    code: 1171004,
    message: i18n.t('WebSocket.message43')
  },
  {
    code: 1181001,
    message: i18n.t('WebSocket.message44')
  },
  {
    code: 1191001,
    message: i18n.t('WebSocket.message45')
  },
  {
    code: 1191002,
    message: i18n.t('WebSocket.message46')
  },
  {
    code: 1191003,
    message: i18n.t('WebSocket.message47')
  },
  {
    code: 1191004,
    message: i18n.t('WebSocket.message48')
  },
  {
    code: 1191005,
    message: i18n.t('WebSocket.message49')
  },
  {
    code: 1191006,
    message: i18n.t('WebSocket.message50')
  },
  {
    code: 1191007,
    message: i18n.t('WebSocket.message51')
  },
  {
    code: 1191008,
    message: i18n.t('WebSocket.message52')
  },
  {
    code: 1191009,
    message: i18n.t('WebSocket.message53')
  },
  {
    code: 1191010,
    message: i18n.t('WebSocket.message54')
  },
  {
    code: 1191011,
    message: i18n.t('WebSocket.message55')
  },
  {
    code: 1191012,
    message: i18n.t('WebSocket.message56')
  },
  {
    code: 1191013,
    message: i18n.t('WebSocket.message57')
  },
  {
    code: 1191014,
    message: i18n.t('WebSocket.message58')
  },
  {
    code: 1191015,
    message: i18n.t('WebSocket.message59')
  },
  {
    code: 1201001,
    message: i18n.t('WebSocket.message60')
  },
  {
    code: 1201002,
    message: i18n.t('WebSocket.message61')
  },
  {
    code: 1201003,
    message: i18n.t('WebSocket.message62')
  },
  {
    code: 1201004,
    message: i18n.t('WebSocket.message63')
  },
  {
    code: 1201005,
    message: i18n.t('WebSocket.message64')
  },
  {
    code: 1211001,
    message: i18n.t('WebSocket.message65')
  },
  {
    code: 1221001,
    message: i18n.t('WebSocket.message66')
  },
  {
    code: 1221002,
    message: i18n.t('WebSocket.message67')
  },
  {
    code: 1221003,
    message: i18n.t('WebSocket.message68')
  },
  {
    code: 1221004,
    message: i18n.t('WebSocket.message69')
  },
  {
    code: 1221005,
    message: i18n.t('WebSocket.message70')
  },
  {
    code: 1231001,
    message: i18n.t('WebSocket.message71')
  },
  {
    code: 1241001,
    message: i18n.t('WebSocket.message72')
  },
  {
    code: 1241002,
    message: i18n.t('WebSocket.message73')
  },
  {
    code: 1251001,
    message: i18n.t('WebSocket.message74')
  },
  {
    code: 1261001,
    message: i18n.t('WebSocket.message75')
  },
  {
    code: 1261002,
    message: i18n.t('WebSocket.message76')
  },
  {
    code: 1261003,
    message: i18n.t('WebSocket.message77')
  },
  {
    code: 1261004,
    message: i18n.t('WebSocket.message78')
  },
  {
    code: 1261005,
    message: i18n.t('WebSocket.message79')
  },
  {
    code: 1261006,
    message: i18n.t('WebSocket.message80')
  },
  {
    code: 1261007,
    message: i18n.t('WebSocket.message81')
  },
  {
    code: 1261008,
    message: i18n.t('WebSocket.message82')
  },
  {
    code: 1271001,
    message: i18n.t('WebSocket.message83')
  },
  {
    code: 1271002,
    message: i18n.t('WebSocket.message84')
  },
  {
    code: 1271003,
    message: i18n.t('WebSocket.message85')
  },
  {
    code: 1271004,
    message: i18n.t('WebSocket.message86')
  },
  {
    code: 1281001,
    message: i18n.t('WebSocket.message87')
  },
  {
    code: 1281002,
    message: i18n.t('WebSocket.message88')
  },
  {
    code: 1281003,
    message: i18n.t('WebSocket.message89')
  },
  {
    code: 1291001,
    message: i18n.t('WebSocket.message90')
  },
  {
    code: 1301001,
    message: i18n.t('WebSocket.message91')
  },
  {
    code: 1301002,
    message: i18n.t('WebSocket.message92')
  },
  {
    code: 1301003,
    message: i18n.t('WebSocket.message93')
  },
  {
    code: 1301004,
    message: i18n.t('WebSocket.message94')
  },
  {
    code: 1301005,
    message: i18n.t('WebSocket.message95')
  },
  {
    code: 1301006,
    message: i18n.t('WebSocket.message96')
  },
  {
    code: 1311001,
    message: i18n.t('WebSocket.message97')
  },
  {
    code: 1321001,
    message: i18n.t('WebSocket.message98')
  },
  {
    code: 1331001,
    message: i18n.t('WebSocket.message99')
  },
  {
    code: 1351001,
    message: i18n.t('WebSocket.message100')
  },
  {
    code: 1351002,
    message: i18n.t('WebSocket.message101')
  },
  {
    code: 1361001,
    message: i18n.t('WebSocket.message102')
  },
  {
    code: 1361002,
    message: i18n.t('WebSocket.message103')
  },
  {
    code: 1371001,
    message: i18n.t('WebSocket.message104')
  },
  {
    code: 1371002,
    message: i18n.t('WebSocket.message105')
  },
  {
    code: 1411001,
    message: i18n.t('WebSocket.message106')
  },
  {
    code: 1421001,
    message: i18n.t('WebSocket.message107')
  },
  {
    code: 1431001,
    message: i18n.t('WebSocket.message108')
  }
];

// 队列
function Queue(fn, delay) {
  this.tasks = [];
  this.status = 0;
  // 定时器查询任务状态
  this.timer = setInterval(() => {
    // console.log('--查询任务--', this.tasks)
    // 上个任务已结束
    if (!this.status && !this.isEmpty()) {
      let task = this.front();
      return fn(task);
    }
  }, delay);
  Queue.prototype.entry = function(task) {
    return this.tasks.push(task);
  };
  // 数据出队列
  Queue.prototype.out = function() {
    return this.tasks.shift();
  };
  // 查看队列的第一个数据信息
  Queue.prototype.front = function() {
    return this.tasks[0];
  };
  // 判断队列是否为空
  Queue.prototype.isEmpty = function() {
    return this.tasks.length == 0;
  };
  // 队列的长度
  Queue.prototype.size = function() {
    return this.tasks.length;
  };
  // 当前任务状态
  Queue.prototype.setStatus = function(value) {
    this.status = value;
  };
  // 队列数据转换
  Queue.prototype.toString = function() {
    let str = '';
    for (let i = 0; i < this.tasks.length; i++) {
      str += this.tasks[i] + ' ';
    }
    return str;
  };
  // 销毁
  Queue.prototype.destroy = function(value) {
    this.tasks = [];
    this.status = 0;
    window.clearInterval(this.timer);
  };
}

// 创建websocket
function createWebSocket(url) {
  const region = store.state.app.userInfo.region;
  if (region) {
    url = url.replace('mols', region + '/mols');
  }
  const socketOptions = {
    reconnectInterval: 1000,
    maxReconnectionDelay: 10000,
    minReconnectionDelay: 1000,
    reconnectionDelayGrowFactor: 1.3,
    connectionTimeout: 10000,
    maxRetries: Infinity,
    debug: false
  };
  return new ReconnectingWebSocket(url, undefined, socketOptions);
}

// 异常提示
function errorTip(res) {
  // 不需要错误提示的code集合
  const result = [1141001, 1301003, 1001000];
  if (res.message && !result.includes(res.errCode)) {
    let errObj = errCodeList.find(item => item.code == res.errCode);
    if (errObj) {
      notify(errObj.message, 'warning');
      return;
    }
    // notify(i18n.t('WebSocket.message109'), 'warning');
  }
}

// 请求任务
function request(method, params) {
  return new Promise((resolve, reject) => {
    const task = {
      id: Math.random() * 0x10 + new Date().getTime() + '',
      method,
      params,
      resolve,
      reject
    };
    if (myQueue) {
      myQueue.entry(task);
    }
  });
}

// 发送请求
function send(task) {
  myQueue.setStatus(1);
  const { method, params, resolve, reject } = task;
  if (CONNECTION) {
    CONNECTION.sendRequest(method, params)
      .then(res => {
        if (res.succeed) {
          resolve(res);
        } else {
          errorTip(res);
          reject(res);
        }
      })
      .finally(() => {
        myQueue.setStatus(0);
        myQueue.out();
      });
  }
}

export function getQueue() {
  return myQueue;
}

// rpc
export function initWebSocket(url) {
  return new Promise((resolve, reject) => {
    WEBSOCKET = createWebSocket(url);
    WEBSOCKET.addEventListener('close', evt => {
      CONNECTION = null;
      myQueue.destroy();
      console.log(evt, 'close');
    });
    rpc.listen({
      webSocket: WEBSOCKET,
      onConnection: connection => {
        CONNECTION = connection;
        connection.listen();
        // connection.onError(err => {
        //   console.log(err, 'Error');
        // });
        console.log(CONNECTION, 'mols服务开启');
        myQueue = new Queue(send, 200);
        resolve(WEBSOCKET);
      }
    });
  });
}

/**
 * 初始化服务接口
 * @param {*} ipAddress--服务端IP地址
 * @param {*} port--服务端端口号
 * @param {*} token--token
 * @param {*} username--用户名
 * @param {*} password--密码
 *
 */
export function initServer(params) {
  return request('init_server', params);
}

/**
 * 保存模型
 * @param {*} modelName--模型全名
 *
 */
export function saveModel(params) {
  return request('save_model', params);
}

/**
 *
 * 保存仓库
 * @param {*} projectId--项目id
 *
 */
export function saveRepo(params) {
  return request('push_model', params);
}

/**
 * 批量保存模型
 * @param {*} modelName--模型全名
 * @param {*} diagramGraphicsInfo--图形层图形
 * @param {*} iconGraphicsInfo--图标层图形
 * @param {*} components--组件
 * @param {*} connects--连线
 *
 */
export function saveBatch(params) {
  return request('save_batch', params);
}

/**
 * 加载模型库
 * @param {*} libraryName--模型库名称
 * @param {*} libraryVersion--模型库版本
 *
 */
export function loadLibrary(params) {
  return request('load_library', params);
}

/**
 * 克隆仓库
 * @param {*} projectId--仓库ID
 *
 */
export function initProject(params) {
  return request('init_project', params);
}

/**
 * 加载用户模型库
 * @param {*} projectId--仓库ID
 *
 */
export function loadModel(params) {
  return request('load_model', params);
}

/**
 * 获取模型下属列表
 * @param {*} modelName
 *
 */
export function getClasses(params) {
  return request('get_classes', params);
}

/**
 * 卸载模型(可替代删除接口)
 * @param {*} projectId--项目id
 * @param {*} projectType--项目类型
 * @param {*} topClassName--顶层模型名
 *
 */
export function unloadModel(params) {
  return request('unload_model', params);
}

/**
 * 删除模型
 * @param {*} modelName--模型全名
 *
 */
export function deleteModel(params) {
  return request('delete_model', params);
}

/**
 * 新建模型
 * @param {*} ident--简名
 * @param {*} type--类型
 * @param {*} description--描述
 * @param {*} bPartial--是否抽象类
 * @param {*} extends--继承类集合
 * @param {*} packageName--嵌入的父类
 * @param {*} saveType--存盘类型
 *
 */
export function createModel(params) {
  return request('new_nest_model', params);
}

/**
 * 获取图标
 * @param {*} modelName--模型全名
 *
 */
export function getIcon(params) {
  return request('get_icon', params);
}

/**
 * 获取图形
 * @param {*} modelName--模型全名
 *
 */
export function getDiagram(params) {
  return request('get_diagram', params);
}

/**
 * 获取组件图形
 * @param {*} modelName--模型全名
 * @param {*} compName--组件简名
 *
 */
export function getSubDiagram(params) {
  return request('get_sub_diagram', params);
}

/**
 * 获取模型下组件列表
 * @param {*} modelName--模型全名
 * @param {*} compName--组件简名
 *
 */
export function getComponentslist(params) {
  return request('get_components_list', params);
}

/**
 * 获取组件变量
 * @param {*} modelName--模型全名
 * @param {*} compName--组件简名
 *
 */
export function getComponentVariables(params) {
  return request('get_component_variables', params);
}

/**
 * 获取参数列表
 * @param {*} modelName--模型全名
 * @param {*} compName--组件简名
 *
 */
export function getComponentParams(params) {
  return request('get_component_params', params);
}

/**
 * 编辑组件参数
 * @param {*} modelName--模型全名
 * @param {*} compIdent--组件简名
 * @param {*} paramName--参数名
 * @param {*} paramValue--参数值
 *
 */
export function editComponentParams(params) {
  return request('edit_component_params', params);
}

/**
 * 编辑组件参数单位
 * @param {*} modelName--模型全名
 * @param {*} compIdent--组件简名
 * @param {*} paramName--参数名
 * @param {*} newParameterUnit--新参数单位
 *
 */
export function editComponentParamsUnit(params) {
  return request('edit_component_params_unit', params);
}

/**
 * 添加组件
 * @param {*} modelName--模型全名
 * @param {*} compType--组件类型名
 * @param {*} compIdent--组件简名
 * @param {*} subs--组件下标名
 * @param {*} comment--注释
 * @param {*} origin--原点坐标
 *
 */
export function addComponent(params) {
  return request('add_component', params);
}

/**
 * 删除组件
 * @param {*} modelName--模型全名
 * @param {*} compIdent--组件简名
 *
 */
export function deleteComponent(params) {
  return request('delete_component', params);
}

/**
 * 移动组件
 * @param {*} modelName--模型全名
 * @param {*} compIdent--组件简名
 * @param {*} pointEnd--终点坐标
 * @param {*} connectLines--组件相关连线
 *
 */
export function moveComponent(params) {
  return request('move_component', params);
}

/**
 * 调整组件大小
 * @param {*} modelName--模型全名
 * @param {*} compIdent--组件简名
 * @param {*} origin--终点坐标
 * @param {*} extent--组件大小
 * @param {*} connectLines--组件相关连线
 *
 */
export function sizeComponent(params) {
  return request('size_component', params);
}

/**
 * 创建连线
 * @param {*} modelName--模型全名
 * @param {*} portBegin--起点端口名
 * @param {*} portEnd--终点端口名
 * @param {*} points--连线点集
 *
 */
export function createConnection(params) {
  return request('create_connection', params);
}

/**
 * 编辑连线
 * @param {*} modelName--模型全名
 * @param {*} portBegin--起点端口名
 * @param {*} portEnd--终点端口名
 * @param {*} points--连线点集
 *
 */
export function editConnection(params) {
  return request('edit_connection', params);
}

/**
 * 删除连线
 * @param {*} modelName--模型全名
 * @param {*} portBegin--起点端口名
 * @param {*} portEnd--终点端口名
 *
 */
export function deleteConnection(params) {
  return request('delete_connection', params);
}

/**
 * 获取可扩展连接器的树
 * @param {*} modelName--模型全名
 * @param {*} portBegin--起点端口名
 * @param {*} portEnd--终点端口名
 */
export function getConnectorTree(params) {
  return request('get_connector_tree', params);
}

/**
 * 检查连接合法性
 * @param {*} modelName--模型全名
 * @param {*} portBegin--起始连接器名称
 * @param {*} portBeginFullName--包含子连接器的其实连接器全名
 * @param {*} portEnd--终止连接器名称
 * @param {*} portEndFullName--包含子连接器终止连接器全名
 */
export function checkConnection(params) {
  return request('check_connection', params);
}

/**
 * 添加图形
 * @param {*} modelName--模型全名
 * @param {*} annotLayer--添加到图层 icon/diagram
 * @param {*} graph
 *
 */
export function addGraph(params) {
  return request('add_graph', params);
}

/**
 * 更新图形
 * @param {*} modelName--模型全名
 * @param {*} annotLayer--添加到图层 icon/diagram
 * @param {*} graph
 *
 */
export function updateGraph(params) {
  return request('update_graph', params);
}

/**
 * 获取模型文本
 * @param {*} modelName--模型全名
 *
 */
export function getModelText(params) {
  return request('get_model_text', params);
}

/**
 * 获取模型文档
 * @param {*} modelName--模型全名
 * @param {*} compName--组件简名
 *
 */
export function getModelDocument(params) {
  return request('get_model_document', params);
}

/**
 * 获取属性
 * @param {*} modelName--模型全名
 * @param {*} compIdent--组件简名
 *
 */
export function getComponentAttribute(params) {
  return request('get_experiment_attribute', params);
}

/**
 * 旋转组件
 * @param {*} modelName--模型全名
 * @param {*} compIdent--组件简名
 * @param {*} rotate--旋转角度
 * @param {*} connectLines--组件相关连线
 *
 */
export function rotateComponent(params) {
  return request('rotate_component', params);
}

/**
 * 模型重命名
 * @param {*} projectId--项目id
 * @param {*} modelName--模型全名
 * @param {*} newModelIdent--新模型名
 *
 */
export function renameModel(params) {
  return request('rename_model', params);
}

/**
 * 组件重命名
 * @param {*} modelName--模型全名
 * @param {*} compIdent--组件简名
 * @param {*} newCompIdent--新组件简名
 *
 */
export function renameComponent(params) {
  return request('rename_component', params);
}

/**
 * 文本建模
 * @param {*} modelName--模型全名
 * @param {*} text--模型文本
 *
 */
export function setFullText(params) {
  return request('set_full_text', params);
}

/**
 *
 * 获取包结构
 * @param {*} projectId--项目id
 *
 */
export function getAllPackageName(params) {
  return request('get_all_package_name', params);
}

/**
 *
 * 获取依赖
 * @param {*} projectId--仓库名
 *
 */
export function readDependency(params) {
  return request('read_dependency', params);
}

/**
 *
 * 编辑依赖
 * @param {*} projectId--仓库名
 * @param {*} modelicaVersion--标准库版本
 * @param {*}  dependence--依赖集合 {libName,projectId,scope,version}
 *
 */
export function writeDependency(params) {
  return request('write_dependency', params);
}

/**
 *
 * 模型库列表
 * @param {*} projectId--仓库名
 *
 */
export function getLibrarysList(params) {
  return request('get_librarys_list', params);
}

/**
 *
 * 模型库搜索
 * @param {*} filterStr--关键字
 * @param {*} ExpandedModel--展开层级
 *
 */
export function filterModel(params) {
  return request('filter_model', params);
}

/**
 * deleteProject
 * @desc 删除仓库
 * @param {*} projectId--仓库名
 * @param {*} projectType--仓库类型
 *
 */
export function deleteProject(params) {
  return request('delete_project', params);
}

/**
 * lookupAttribute
 * @desc 检查组件属性
 * @param {*} modelTypeName--组件类型名
 *
 */
export function lookupAttribute(params) {
  return request('lookup_attribute', params);
}

/**
 * getModelVariable
 * @desc 获取模型变量
 * @param {*} modelName--模型名
 *
 */
export function getModelVariable(params) {
  return request('get_visible_variable', params);
}

/**
 * setModelVariable
 * @desc 编辑模型变量
 * @param {*} modelName--模型名
 * @param {*} visibleVec--监视变量数组
 * @param {*} ifSelectAll--是否全选
 *
 */
export function setModelVariable(params) {
  return request('set_visible_variable', params);
}

/**
 * getArrayDimension
 * @desc 获取组件维度
 * @param {*} modelName--模型名
 * @param {*} portLeft--左侧端口
 * @param {*} portRight--右侧端口
 * @param {*} portName--查询端口名
 *
 */
export function getArrayDimension(params) {
  return request('get_array_dimension', params);
}
