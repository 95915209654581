import Vue from "vue";
import { isLogin } from '@/core/utils';
export function hwAnalytics(event, msg) {
  if (system.env === "production") {
    if (isLogin()) {
      msg.name = system.userInfo.name;
    }
    Vue.prototype.$hiAnalytics.onEvent(event, msg);
  }
}
