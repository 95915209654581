import i18n from '../../core/lang';

const activity = {
  state: {
    activities: {
      REPO_CREATE: i18n.t('store.REPO_CREATE'),
      REPO_DELETE: i18n.t('store.REPO_DELETE'),
      REPO_CLEAR: i18n.t('store.REPO_CLEAR'),
      REPO_STAR: i18n.t('store.REPO_STAR'),
      REPO_COLLECTION: i18n.t('store.REPO_COLLECTION'),
      REPO_FORK: i18n.t('store.REPO_FORK'),
      REPO_PUSH: i18n.t('store.REPO_PUSH'),
      TAG_CREATE: i18n.t('store.TAG_CREATE'),
      TAG_DELETE: i18n.t('store.TAG_DELETE'),
      COMMENT_CREATE: i18n.t('store.COMMENT_CREATE'),
      COMMENT_DELETE: i18n.t('store.COMMENT_DELETE'),
      COMMENT_UPDATE: i18n.t('store.COMMENT_UPDATE'),
      GROUP_CREATE: i18n.t('store.GROUP_CREATE'),
      GROUP_DELETE: i18n.t('store.GROUP_DELETE'),
      GROUP_UPDATE: i18n.t('store.GROUP_UPDATE'),
      MEMBER_ADD: i18n.t('store.MEMBER_ADD'),
      MEMBER_BE_ADDED: i18n.t('store.MEMBER_BE_ADDED'),
      MEMBER_UPDATE: i18n.t('store.MEMBER_UPDATE'),
      MEMBER_BE_UPDATED: i18n.t('store.MEMBER_BE_UPDATED'),
      MEMBER_DELETE: i18n.t('store.MEMBER_DELETE'),
      MEMBER_BE_DELETED: i18n.t('store.MEMBER_BE_DELETED')
    }
  },
  getters: {
    getActivities(state) {
      return state.activities;
    }
  },
  mutations: {}
};

export default activity;
