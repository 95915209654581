<template>
  <div id="app">
    <header v-if="!hideMenu">
      <header-panel />
    </header>
    <div class="top"></div>
    <main class="main" id="main" :style="{ minHeight: mainHeight + 'px' }">
      <router-view :key="$route.fullPath" v-if="!loading" />
    </main>
    <footer v-if="!hideMenu">
      <footer-panel></footer-panel>
    </footer>
    <!-- 添加一个backtop -->
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import HeaderPanel from "@/components/header";
import FooterPanel from "@/components/footer/bottom.vue";
export default {
  data() {
    return {
      hideMenu: false,
      loading: true,
    };
  },
  components: {
    HeaderPanel,
    FooterPanel,
  },
  asyncComputed: {
    component: function () {
      return this.$utils.getComponent(this.$route.path);
    },
  },
  computed: {
    mainHeight() {
      return window.innerHeight - 220;
    },
    show() {
      const temp = ["/products", "/about"];
      return temp.includes(this.$route.path);
    },
  },
  watch: {
    "$route.path": {
      handler() {
        this.changeTitle(this.$route.path);
      },
      deep: true,
    },
  },
  async created() {
    this.loading = true;
    await this.$utils.refreshToken();
    this.loading = false;
    system.env = process.env.NODE_ENV;
  },
  mounted() {
    this.hideMenu = this.getQueryVariable("hideMenu");
    this.$bus.$on("langUpdate", () => {
      this.changeTitle(this.$route.path);
      this.update = false;
      this.$nextTick(() => {
        this.update = true;
      });
    });
  },
  methods: {
    changeTitle(path) {
      let title = "";
      switch (path) {
        case "/products":
          title = "产品中心";
          break;
        case "/about":
          title = "关于同元";
          break;
        case "/support":
          title = "技术支持";
          break;
        case "/cases":
          title = "经典案例";
          break;
        case "/solution":
          title = "解决方案";
          break;
        case "/search":
          title = "搜索";
          break;
        case "/news":
          title = "新闻动态";
          break;
        default:
          title = "";
      }
      document.title = title ? title + "-苏州同元软控" : "苏州同元软控";
    },
    getQueryVariable(variable) {
      const query = window.location.search.substring(1);
      const vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] === variable) {
          return pair[1];
        }
      }
      return false;
    },
    orientationControl() {
      const evt =
        "onorientationchange" in window ? "orientationchange" : "resize";

      window.addEventListener(evt, this.orientationchange, false);
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  position: relative;
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  // // overflow: hidden;
  // header {
  //   z-index: 100;
  //   position: sticky;
  //   top: -1px;
  //   // width: 100%;
  //   &.horizontal {
  //     position: relative;
  //   }
  // }
}

.main {
  background: white;
}

header {
  z-index: 1999;
  width: 100%;
  position: fixed;
}

.top {
  height: $--header-height;
}
@media screen and (max-width: 700px) {
  .main {
    min-height: 500px !important;
  }
}
</style>
