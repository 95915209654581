<template>
    <transition name="fade" mode="out-in">
        <div class="body" @mouseenter="enter" @mouseleave="leave" v-show="seen" name="body" v-if="data.submenus.length">
            <div class="sub">
                <!-- <el-row :gutter="20">
                    <el-col :span="8" v-for="(item, index) in data" :key="index"> -->
                    <div class="wrap" v-for="(item, index) in data.submenus" :key="index">
                        <div class="title">
                            <a @click="toUrl(item.router, item.point,item)">{{ item.name }}</a>
                        </div>
                        <div v-if="item.children.length">
                            <div v-for="(child, index) in item.children" :key="index" class="submenu">
                                <a @click="toUrl(child.router, child.point,child)">{{ child.name }}</a>
                            </div>
                        </div>
                    </div>
                    <!-- </el-col>
                </el-row> -->
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      seen: false
    };
  },
  created() {
    this.userInfo = this.$utils.getStorage('userInfo');
    console.log('userInfo11111', this.userInfo);
  },
  methods: {
    async toUrl(url, point, item) {
      const currentDate = new Date();
      const formattedTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
      for (let i = 0; i < this.data.submenus.length; i++) {
        if (this.data.submenus[i].name == item.name) {
          // console.log('二级标题');
          let obj = {};
          if (this.userInfo) {
            obj = {
              event: 'tag',
              eventTime: formattedTime,
              message: {
                tag: item.name,
                parent: this.data.parent.name
              },
              userName: this.userInfo.sub
            };
          } else {
            obj = {
              event: 'tag',
              eventTime: formattedTime,
              message: {
                tag: item.name,
                parent: this.data.parent.name
              }
            };
          }
          const res = await this.$api.addEventMessage(obj);
          if (res.code == 200) {
            console.log(res + '成功');
          }
        }
      }
      if (url) {
        if (url.includes('http')) {
          if (this.$utils.getToken() && url.includes('/applyLicense')) {
            let route = url + '?token=' + this.$utils.getToken();
            window.open(route);
          } else {
            window.open(url);
          }
        } else {
          if (point) {
            this.$router.push(url + point);
          } else {
            this.$router.push(url);
          }
        }
      } else {
        return;
      }
    },
    toTicket() {
      if (!this.$utils.isLogin()) {
        this.$utils.openLogin({ path: '/support/ticket' });
        return;
      } else {
        this.$router.push('/support/ticket');
      }
    },
    enter() {
      this.seen = true;
    },
    leave() {
      this.seen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
    position: absolute;
    left: 0;
    right: 0;
    background-color: #1d1d1d;
    padding: 2% 0 5% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .sub {
        display: flex;
        flex-wrap: wrap;
        max-width: 1200px;
        width: 100%;
        .wrap {
            width: 33%;
            padding-right: 2px;
            margin-top: 20px;
            // padding-right: 40px;
        }
    }

}

.sub {
    // width: 100vw;
    // margin-left: 15vw;
}

.submenu {
    margin: 3% 5%;
    font-size: 13px;
}

.submenu a {
    color: white;
    cursor: pointer;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;

    &:hover {
        color: #497dcc;
    }
}

.title {
    position: relative;
}

.title a {
    padding: 0 5%;
    font-size: 15px;
    color: white;
    cursor: pointer;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 1;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        color: #497dcc;
    }
}

.title:before {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    -ms-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    width: 6px;
    height: 6px;
    background-color: #497dcc;
}

::v-deep .el-col {
    margin: 15px 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
