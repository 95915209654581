// 新建模型tab
function createTab(data) {
  let tab = {
    label: data.ident, // 主模型简称
    value: data.fullName, // 主模型全称
    fromRepository: data.fromRepository, // 模型所属库
    projectId: data.projectId,
    modelView: data.modelView || 'diagram', // 模型视图-图标、图形、文本
    routes: [createRoute(data)], // 模型打开路由
    compName: '', // 当前选中组件
    active: true, // 选项卡激活状态
    currentChange: null,
    editable: data.editable,
    isPackage: data.specialization == 'package', // 当前模型是否为包结构
    modelText: '',
    encryptLevel: data.encryptLevel // 加密等级
  };
  // 加密模型不允许编辑
  if (data.encryptLevel) {
    tab.editable = false;
  }
  return tab;
}

// 新建模型路由
function createRoute(data) {
  let route = {
    ident: data.ident,
    fullName: data.fullName,
    children: [],
    isLeaf: data.isLeaf,
    compName: '' // 当前选中组件
  };
  return route;
}

export default {
  namespaced: true,
  state: {
    editor: null, // 编辑器实例
    tabs: [] // 标签列表-用于多模型视图
  },
  mutations: {
    // 编辑器
    EDITOR(state, value) {
      state.editor = value;
    },
    // 标签页
    TAB(state, value) {
      state.tabs = value;
    },
    // 清空
    CLEAR(state, value) {
      state.views = [];
      state.tabs = [];
      state.editor = null;
    }
  },
  getters: {
    editor: state => state.editor,
    tabs: state => state.tabs,
    activeTab: state => state.tabs.find(item => item.active),
    currentRoute: state => {
      const activeTab = state.tabs.find(item => item.active);
      if (activeTab) {
        return activeTab.routes[activeTab.routes.length - 1];
      }
      return false;
    }
  },
  actions: {
    update({ commit, dispatch, state }, value) {
      return new Promise(resolve => {
        let { fullName, action } = value;
        // 区分新开还是替换当前tab
        if (!action) {
          action = state.tabs.find(item => item.active) ? 'replace' : 'add';
        }
        if (action == 'add' || action == 'replace') {
          // 如果存在，则不继续执行
          const currentTab = state.tabs.find(item => item.value == fullName);
          if (currentTab) {
            return;
          }
        }
        if (action == 'add') {
          dispatch('add', value);
        } else if (action == 'delete') {
          dispatch('delete', value);
        } else if (action == 'replace') {
          dispatch('replace', value);
        } else if (action == 'active') {
          dispatch('active', value);
        }
        resolve();
      });
    },
    add({ commit, dispatch, state }, value) {
      let tabs = JSON.parse(JSON.stringify(state.tabs));
      const newTab = createTab(value);
      tabs.forEach(tab => {
        tab.active = false;
      });
      tabs.push(newTab);
      commit('TAB', tabs);
    },
    delete({ commit, dispatch, state }, value) {
      let tabs = JSON.parse(JSON.stringify(state.tabs));
      let { fullName, node } = value;
      let deleTabs = [];
      if (node) {
        deleTabs = tabs.filter(
          item => item.value == fullName || item.value.includes(fullName + '.')
        );
        tabs = tabs.filter(
          item => item.value != fullName && !item.value.includes(fullName + '.')
        );
      } else {
        tabs = tabs.filter(item => item.value != fullName);
      }
      // 删除的模型没有打开，则不继续执行
      if (!deleTabs.length) {
        return;
      }

      // 如果删除后,没有激活的tab,则激活最后一个tab
      const activeTab = tabs.find(item => item.active);
      if (!activeTab && tabs.length) {
        dispatch('active', tabs[tabs.length - 1]);
      }
      commit('TAB', tabs);
    },
    active({ commit, dispatch, state }, value) {
      let tabs = JSON.parse(JSON.stringify(state.tabs));
      let { fullName } = value;
      tabs.forEach(item => {
        item.currentChange = null;
        item.active = item.value == fullName;
      });
      commit('TAB', tabs);
    },
    replace({ commit, dispatch, state }, value) {
      let tabs = JSON.parse(JSON.stringify(state.tabs));
      const newTab = createTab(value);
      const index = tabs.findIndex(item => item.active);
      if (index == -1) {
        return;
      }
      // 继承模型视图状态
      newTab.modelView = tabs[index].modelView;
      tabs[index] = newTab;
      commit('TAB', tabs);
    },
    // 模型视图
    changeModelView({ commit, dispatch, state }, value) {
      let tabs = JSON.parse(JSON.stringify(state.tabs));
      const i = tabs.findIndex(item => item.active);
      if (i == -1) {
        return;
      }
      tabs[i].currentChange = 'modelView';
      tabs[i].modelView = value || 'diagram';
      commit('TAB', tabs);
    },
    // 切换模型中组件选中状态
    changeModelSelection({ commit, getters, dispatch, state }, value) {
      if (!getters.currentRoute) {
        return;
      }
      getters.currentRoute.compName = value || '';
    },
    // 更新模型路由状态
    updateRoute({ commit, dispatch, state }, value) {
      const { action, data, index } = value;
      const activeTab = state.tabs.find(item => item.active);
      let routes = JSON.parse(JSON.stringify(activeTab.routes));
      if (action == 'push') {
        routes.push(createRoute(data));
      } else if (action == 'back') {
        const i = routes.findIndex(route => route.fullName == data.fullName);
        routes.splice(i + 1);
      } else if (action == 'replace') {
        routes.splice(index, 100, createRoute(data));
      }
      let tabs = JSON.parse(JSON.stringify(state.tabs));
      const i = tabs.findIndex(item => item.active);
      if (i == -1) {
        return;
      }
      tabs[i].currentChange = 'route';
      tabs[i].routes = routes;
      commit('TAB', tabs);
    },
    // 清空
    clear({ commit, dispatch, state }, value) {
      commit('CLEAR');
    }
  }
};
