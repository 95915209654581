<template>
  <div class="item">
    <el-skeleton-item variant="image" style="width: 100%; height: 220px; margin-bottom: 10px;" />
    <el-skeleton-item variant="h3" style="width: 50%;" />
    <el-skeleton-item variant="text" style="margin-right: 16px;" />
    <el-skeleton-item variant="text" style="width: 30%;" />
  </div>
</template>

<script>
export default {
  name: 'SkeletonCard',
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
.item {
  display: inline-block;
  width: calc((100% - 40px) / 3);
  margin-bottom: 20px;

  &:not(:nth-child(3n)) {
    margin-right: 20px;
  }
}

@media screen and (max-width: 700px) {
  .item {
    width: 100%;
  }
}
</style>
