import store from '@/store';
import Vue from '@/main.js';
import { getQueryString } from '@/core/utils';
/**
 * 创建窗口实例id
 * @param data
 * @returns {*}
 */
function createId(data) {
  let id;
  let ids = []; // 当前id集合
  data.forEach(item => {
    ids.push(item.id);
  });
  for (let i = 1; i < 10000000; i++) {
    if (!ids.includes(i)) {
      id = i;
      break;
    }
  }
  return id;
}

function getTabs() {
  return store.getters['tabs/tabs'];
}

/**
 * 计算tab的位置信息
 * @param {*} state
 */
function getTabPosition() {
  const tabsLayout = store.getters['tabs/layout'];
  const x = tabsLayout.y + 1;
  const y = tabsLayout.x + 1;
  let tabs = store.getters['tabs/tabs'];
  let layout = {
    x: 0,
    y: 0
  };
  for (let j = 1; j <= y; j++) {
    for (let i = 1; i <= x; i++) {
      let index = tabs.findIndex(item => item.x === i && item.y === j);
      if (index === -1) {
        layout.x = i;
        layout.y = j;
        return layout;
      }
    }
  }
}

export default {
  namespaced: true,
  state: {
    selectTaskId: null,
    speed: 1
  },
  mutations: {
    SELECT_TASK(state, data) {
      state.selectTaskId = data;
    },
    ADD_ANIMATIONS(state, data) {
      const tabs = getTabs();
      const tabsLayout = store.getters['tabs/layout'];
      if (tabs.length >= (tabsLayout.x + 1) * (tabsLayout.y + 1)) {
        return;
      }
      tabs.forEach(item => {
        item.isActive = false;
      });
      const animation = {
        id: createId(tabs),
        taskId: data.taskId,
        type: 'animate',
        isActive: true,
        host: data.host,
        speed: state.speed
      };
      if (data.position === undefined) {
        let layout = getTabPosition();
        animation.x = layout.x;
        animation.y = layout.y;
      } else {
        animation.x = data.position.x;
        animation.y = data.position.y;
      }
      tabs.push(animation);
    },
    DELETE: (state, data) => {
      const tabs = getTabs();
      const i = tabs.findIndex(item => item.id === data.id);
      tabs.splice(i, 1);
      if (data.isActive) {
        let len = tabs.length;
        if (len != 0) {
          tabs[len - 1].isActive = true;
          // 如果是动画
          if (tabs[len - 1].taskId) {
            state.selectTaskId = {
              taskId: tabs[len - 1].taskId,
              host: tabs[len - 1].host
            };
          } else {
            // state.selectTaskId = null;
          }
        }
      }
    },
    CLEAR: (state, data) => {
      // state.animations = [];
      // state.animations.push(data);
    },
    ACTIVE: (state, data) => {
      // 场景
      if (!getQueryString('scene')) {
        const tabs = getTabs();
        tabs.forEach(item => {
          item.isActive = false;
          if (item.id == data.id) {
            item.isActive = true;
          }
          if (item.taskId !== data.taskId) {
            // 重置其他动画
            Vue.$root.$emit(`reset${item.id}`, item);
          }
        });
        state.selectTaskId = {
          taskId: data.taskId,
          host: data.host
        };
      }
    },
    UPDATE_SPEED(state, data) {
      const tabs = getTabs();
      state.speed = data;
      tabs.forEach(item => {
        if (item.type === 'animate') {
          item.speed = data;
        }
      });
    },
    ADD_SCENE(state, data) {
      const tabs = getTabs();
      const tabsLayout = store.getters['tabs/layout'];
      if (tabs.length >= (tabsLayout.x + 1) * (tabsLayout.y + 1)) {
        return;
      }
      tabs.forEach(item => {
        item.isActive = false;
      });
      const animation = {
        id: createId(tabs),
        taskId: data.taskId,
        type: 'scene',
        isActive: true,
        host: data.host,
        speed: state.speed
      };
      if (data.position === undefined) {
        let layout = getTabPosition();
        animation.x = layout.x;
        animation.y = layout.y;
      } else {
        animation.x = data.position.x;
        animation.y = data.position.y;
      }
      tabs.push(animation);
    },
    ADD_MODEL(state, data) {
      const tabs = getTabs();
      const tabsLayout = store.getters['tabs/layout'];
      if (tabs.length >= (tabsLayout.x + 1) * (tabsLayout.y + 1)) {
        return;
      }
      tabs.forEach(item => {
        item.isActive = false;
      });
      const animation = {
        id: createId(tabs),
        taskId: data.taskId,
        type: 'model',
        isActive: true,
        host: data.host,
        speed: state.speed
      };
      if (data.position === undefined) {
        let layout = getTabPosition();
        animation.x = layout.x;
        animation.y = layout.y;
      } else {
        animation.x = data.position.x;
        animation.y = data.position.y;
      }
      tabs.push(animation);
    }
  },
  getters: {
    selectTaskId: state => state.selectTaskId
  },
  actions: {
    deleteAnimation({ commit }, data) {
      return new Promise(resolve => {
        commit('DELETE', data);
        resolve();
      });
    },
    clearAnimation({ commit }, data) {
      return new Promise(resolve => {
        commit('CLEAR', data);
        resolve();
      });
    },
    changeActive({ commit }, data) {
      return new Promise(resolve => {
        commit('ACTIVE', data);
        resolve();
      });
    },
    clear({ commit }, data) {
      return new Promise(resolve => {
        commit('CLEAR');
        resolve();
      });
    }
  }
};
