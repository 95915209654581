export default {
  captcha: {
    title: 'Please complete the following verification:',
    successText: 'Verification is successful!',
    failText: 'Verification failed, please try again',
    sliderText: 'Drag the slider to complete the top puzzle',
    refresh: 'Refresh',
    send: 'The verification code has been sent, please note to check'
  }
};
