<template>
  <keep-alive>
    <component :is="component"></component>
  </keep-alive>
</template>

<script>
export default {
  data() {
    return {};
  },
  asyncComputed: {
    component: function() {
      return this.$utils.getComponent(this.$route.path);
    }
  }
};
</script>

<style scoped lang="scss">
.ivu-exception-content {
  width: 300px;
  margin: 0 auto;
  margin-top: 20px;
}
</style>
