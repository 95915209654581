import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import activity from './activity/index';
import attribute from './attribute/index';
import settings from './modules/settings'
Vue.use(Vuex);
// 批量导入modules中所有文件
const modulesFiles = require.context('./modules', true, /\.js$/);
console.log(modulesFiles)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
modules.activity = activity;
modules.attribute = attribute;
const routers = {
  namespaced: true,
  state: () => ({
      routers: "",
  }),
  mutations: {
      routers(state, newsdata) {
          state.routers = newsdata
      },

  },
  actions: {
      routers(context) {
          context.commit('routers')
      },
  },
  getters: {
      routers(state) {
          console.log("getters", state)
          return state.routers
      },

  }
}
const store = new Vuex.Store({
  modules: {
      ...modules,
      routers: routers,
      settings
  },
  plugins: [createPersistedState({
      key: 'routersData',
      paths: ["routers.routers"]
  })]
})

export default store
