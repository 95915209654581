import agconnect from '@agconnect/api';
import '@agconnect/instance';
import '@hmscore/analytics-web';
import { isLogin } from '@/core/utils';
import store from '@/store';
var agConnectConfig = {
  agcgw: {
    backurl: 'connect-drcn.hispace.hicloud.com',
    url: 'connect-drcn.dbankcloud.cn',
    websocketbackurl: 'connect-ws-drcn.hispace.dbankcloud.com',
    websocketurl: 'connect-ws-drcn.hispace.dbankcloud.cn'
  },
  agcgw_all: {
    CN: 'connect-drcn.dbankcloud.cn',
    CN_back: 'connect-drcn.hispace.hicloud.com',
    DE: 'connect-dre.dbankcloud.cn',
    DE_back: 'connect-dre.hispace.hicloud.com',
    RU: 'connect-drru.hispace.dbankcloud.ru',
    RU_back: 'connect-drru.hispace.dbankcloud.cn',
    SG: 'connect-dra.dbankcloud.cn',
    SG_back: 'connect-dra.hispace.hicloud.com'
  },
  websocketgw_all: {
    CN: 'connect-ws-drcn.hispace.dbankcloud.cn',
    CN_back: 'connect-ws-drcn.hispace.dbankcloud.com',
    DE: 'connect-ws-dre.hispace.dbankcloud.cn',
    DE_back: 'connect-ws-dre.hispace.dbankcloud.com',
    RU: 'connect-ws-drru.hispace.dbankcloud.ru',
    RU_back: 'connect-ws-drru.hispace.dbankcloud.cn',
    SG: 'connect-ws-dra.hispace.dbankcloud.cn',
    SG_back: 'connect-ws-dra.hispace.dbankcloud.com'
  },
  client: {
    cp_id: '2850086000513273437',
    product_id: '99536292102562679',
    client_id: '964444833336807232',
    client_secret:
      '19551F926349FC7BF6D21B91EA8A8668D8387852E2782506F164A75CC5725117',
    project_id: '99536292102562679',
    app_id: '243650030996134023',
    api_key:
      'DAEDAFVaKW/l8bXBFzazY17OOzbwh++N5a5GwatXfo48YAxF9HDgUw/Xb0TNtPZXgiCFkK0is9foy7D4dPv65+3cd8FNRgd0Rd4Fug=='
  },
  oauth_client: {
    client_id: '106932801',
    client_type: 7
  },
  app_info: {
    app_id: '243650030996134023'
  },
  service: {
    analytics: {
      collector_url:
        'datacollector-drcn.dt.hicloud.com,datacollector-drcn.dt.dbankcloud.cn',
      collector_url_ru:
        'datacollector-drru.dt.dbankcloud.ru,datacollector-drru.dt.hicloud.com',
      collector_url_sg:
        'datacollector-dra.dt.hicloud.com,datacollector-dra.dt.dbankcloud.cn',
      collector_url_de:
        'datacollector-dre.dt.hicloud.com,datacollector-dre.dt.dbankcloud.cn',
      collector_url_cn:
        'datacollector-drcn.dt.hicloud.com,datacollector-drcn.dt.dbankcloud.cn',
      resource_id: 'p1',
      channel_id: ''
    },
    search: {
      url: 'https://search-drcn.cloud.huawei.com'
    },
    cloudstorage: {
      storage_url_sg_back: 'https://agc-storage-dra.cloud.huawei.asia',
      storage_url_ru_back: 'https://agc-storage-drru.cloud.huawei.ru',
      storage_url_ru: 'https://agc-storage-drru.cloud.huawei.ru',
      storage_url_de_back: 'https://agc-storage-dre.cloud.huawei.eu',
      storage_url_de: 'https://ops-dre.agcstorage.link',
      storage_url: 'https://agc-storage-drcn.platform.dbankcloud.cn',
      storage_url_sg: 'https://ops-dra.agcstorage.link',
      storage_url_cn_back: 'https://agc-storage-drcn.cloud.huawei.com.cn',
      storage_url_cn: 'https://agc-storage-drcn.platform.dbankcloud.cn'
    },
    ml: {
      mlservice_url:
        'ml-api-drcn.ai.dbankcloud.com,ml-api-drcn.ai.dbankcloud.cn'
    }
  },
  region: 'CN',
  configuration_version: '3.0'
};
if (process.env.NODE_ENV !== 'development') {
  // agconnect.instance().configInstance(agConnectConfig);
}
export function hwAnalytics(event, msg) {
  if (process.env.NODE_ENV === 'production') {
    if (isLogin()) {
      msg.name = store.state.app.userInfo.name;
    }
    agconnect.analytics().onEvent(event, msg);
  }
}
