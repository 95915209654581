import axios from 'axios';
import { getToken, loginOut, openLogin } from '@/core/utils';
import router from '../router/index';
// http请求拦截器
axios.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers.common.token = token;
    }
    let lang = localStorage.getItem('lang');
    if (lang && lang == 'en') {
      lang = 'en_US';
    } else {
      lang = '';
    }
    if (lang) {
      config.url += '?' + 'lang=' + lang;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// http响应拦截器
axios.interceptors.response.use(
  response => {
    console.log(response, 'response3333');
    // 无权限访问
    if (response.data && response.data.code == 404) {
      window.location.href = '/505';
      return null;
    }
    if (response.data && (response.data.code == 403 || response.data.code == 400)) {
      // loginOut();
      console.log('response.data.code == 403 || response.data.code == 400');
      openLogin({ path: router.router.history.current.fullPath });
      return null;
    }
    // ip拦截
    if (response.data && response.data.code == 501) {
      let lang = localStorage.getItem('lang');
      if (lang == 'en') {
        notify(
          'The request is too frequent. The ip address has been blocked. Please try again in a few minutes',
          'warning'
        );
      } else {
        notify(response.data.message, 'warning');
      }
    }
    return response;
  },
  error => {
    console.log(error, 'error');
    console.log('json', JSON.stringify(error));
    console.log('error.code', error.code);
    console.log('error.code', error.message);
    console.log('error.code', error.message.includes('timeout'));
    if (error.message === 'Network Error') {
      console.log('NETwwork', { path: router.router.history.current.fullPath });
      loginOut();
      if (window.location.pathname.includes('/support/ticket')) {
        console.log('工单页面跳转登录');
        openLogin({ path: router.router.history.current.fullPath });
      }
      return null;
    }
    // if (
    //   error.code === 'ECONNABORTED' ||
    //   error.message === 'Network Error' ||
    //   error.message.includes('timeout')
    // ) {
    //   notify('请求超时，请稍后重试', 'error');
    // }
    if (error.response) {
      const { data, status, statusText } = error.response;
      // token失效
      if (status == 401 || status == 403) {
        loginOut();
        return null;
      }
      // 错误信息打印出来
      if (process.env.NODE_ENV != 'production') {
        // notify(
        //   `<p>${status}</p><p>${statusText}1111</p><p style="word-break:break-all">${JSON.stringify(
        //     data
        //   )}</p>`,
        //   'error',
        //   10000
        // );
      }
    }
    return Promise.reject(error);
  }
);
export default axios;
