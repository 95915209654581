module.exports = {
    tyApplicationInfo: {
        host: 'http://172.16.1.146:8080',
        client_id: '8ada37c0261f4ce9bcab',
        redirect_uri: 'http://172.16.1.146:9400/tyLogin',
        // state: 'http://127.0.0.1:9999',
        grant_type: 'authorization_code',
    }
    // 测试环境
    // tyApplicationInfo: {
    //     host: 'http://172.16.1.146:8080',
    //     client_id: 'a5b49c5a89f34177a27c',
    //     redirect_uri: 'http://172.16.1.146:9400/callback',
    //     grant_type: 'authorization_code',
    // }
}
