export default {
  captcha: {
    title: '请完成下列验证后继续：',
    successText: '验证成功！',
    failText: '验证失败，请重试',
    sliderText: '拖动滑块完成上方拼图',
    refresh: '刷新',
    send: '验证码已发送，请注意查收'
  }
};
