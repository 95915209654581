<template>
  <div class="item">
    <div class="item-object">
      <el-skeleton-item variant="image" style="width: 100%;height:100%" />
    </div>
    <div class="item-bd">
      <el-skeleton-item variant="text" style="width:30%;margin-bottom: 10px;" />
      <el-skeleton-item variant="text" style="margin-bottom: 30px;" />
      <el-skeleton-item variant="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonCase',
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
.item {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 40px;
  margin-bottom: 40px;
  &-object {
    width: 360px;
    height: 220px;
    margin-right: 40px;
  }
  &-bd {
    flex: 1;
  }
}
</style>
