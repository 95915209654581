export default {
  common: {
    serarch: 'Search {name}',
    manage: '{name} manage',
    name: '{name} name',
    repo: 'Repository | repository',
    select: 'Please select',
    system: 'System',
    profile: 'Profile',
    member: 'Member',
    logout: 'Logout',
    submit: 'Submit',
    determine: 'Determine',
    cancel: 'Cancel',
    save: 'Save',
    warning: 'Warning',
    seeMore: 'See more',
    noData: 'No data',
    loadMore: 'Load more',
    haveNoMore: 'Have no more',
    content: 'Content',
    operation: 'Operation',
    confirm: 'Confirm',
    password: 'Password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    updateSuccess: 'Update success',
    updateFailed: 'Update failed',
    username: 'Username',
    realname: 'Real name',
    mine: 'Mine ',
    more: 'More',
    also: 'Also',
    commit: 'Commits',
    push: 'Pushed the code to',
    deleteRepoTip: 'The repository has been deleted and cannot be accessed',
    systemError: 'System error',
    classification: 'Classification',
    description: 'Description',
    time: 'Time',
    type: 'Type',
    deleteRepo: 'Delete repository',
    publishRepo: 'Publish repository',
    applicant: 'Applicant',
    modelName: 'Repository name',
    creator: 'Creator',
    updateTime: 'Update time',
    status: 'Status',
    recent: 'Just now',
    seconds: 'seconds',
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    months: 'months',
    ago: ' {name} ago',
    noDescription: 'Have no description',
    done: 'Done',
    from: 'From',
    createdTime: 'Created time',
    applyStatus: 'Apply status',
    input: 'Please enter the content',
    tip: 'Tips',
    model: 'Model',
    success: 'Success',
    fail: 'Fail',
    delete: 'Delete',
    info: 'Infomation',
    cancelDelete: 'Deletion cancelled',
    deletionSucceeded: 'Deletion succeeded',
    deletionFailed: 'Deletion failed',
    noAnimation: 'have no animation',
    edit: 'Edit',
    add: 'Add',
    isDelete: 'Confirm deletion',
    yes: 'Yes',
    no: 'No',
    addSuccess: 'Successfully added',
    editSuccess: 'Modification succeeded',
    saveSuccess: 'Save succeeded',
    saving: 'Saving...',
    saveFailed: 'Save failed',
    editFail: 'Modification failed',
    notDelete: 'No permission to delete',
    noPermission: 'Account has no permission',
    loading: 'loading...',
    keyword: 'Please enter keywords',
    search: 'Search',
    reset: 'Reset',
    read: 'Readable',
    manager: 'Manager',
    write: 'Writable',
    owner: 'Owner',
    important: 'Important',
    filter: 'Enter keywords for filtering',
    WeChat: 'WeChat',
    all: 'All',
    noIntroduction: 'No Introduction',
    introduction: 'Introduction',
    public: 'Public',
    private: 'Private',
    operationSucceeded: 'Operation Succeeded',
    noResult: 'No search results',
    you: 'you',
    download: 'Download',
    update: 'Update',
    index: 'Index',
    detail: 'Detail',
    simulate: 'Simulate',
    plot: 'Plot',
    course: 'Tutorial',
    consultation: 'Consultation',
    question: 'Feedback'
  },
  login: {
    message1: 'Bind an existing account',
    message2: 'Sign up a new account and bind it',
    noAccount: 'No account?',
    registerNow: 'Sign up now',
    haveAccount: 'Already have an account?',
    clickBind: 'Click Bind'
  },
  sign: {
    login: 'Sign in',
    register: 'Sign up',
    registerAccount: 'Sign up MoHub account',
    resetPassword: 'Reset Password',
    noAccount: 'No account?',
    registerNow: 'Sign up now',
    haveAccount: 'Already have an account?',
    goToLogin: 'Go to Login',
    otherLoginMethods: 'Or sign in with',
    message1: 'Industrial knowledge model interconnection platform',
    message2: 'Online modeling at all times',
    message3: 'Online modeling at all places',
    message4: 'Neat and complete post-processing',
    message5: 'Efficient interactive learning platform',
    title: 'Sign in|Sign up',
    namePlaceholder: 'user name/mobile/email',
    password: 'Password',
    newPassword: 'New Password',
    forgotPassword: 'forget password?',
    passwordFreeLogin: 'mobile/email login',
    loginAndBind: 'Login and bind',
    registerAndBind: 'Sign up and bind',
    passwordFreeLoginPlaceholder: 'mobile/email',
    verificationCode: 'Verification code',
    getVerificationCode: 'Get verification code',
    getVerificationCodeMessage: 'Resend after {count}s',
    accountPasswordLogin: 'Account Password Login',
    validate1: 'Please enter user name/mobile/email',
    validate2: 'The account does not exist',
    validate3: 'Please enter your mobile/email',
    validate4: 'Please enter the correct mobile/email',
    validate5: 'The account already exists',
    validate6: 'Please enter the password',
    validate7:
      'The password must contain numbers and must contain letters or other symbols (! @_#$%^&*()-+=,.?)',
    validate8: 'The password must contain 6 to 32 characters',
    validate9: 'Please enter the verification code',
    validate10: 'Please enter the username',
    validate11:
      'The user name only contains numbers, English and underscores, and does not start with a number',
    validate12: 'The user name already exists',
    username: 'username',
    registerSuccess:
      'Successfully registered, and will log in automatically...',
    passwordSetSuccess: 'Password set successfully',
    validate13:
      'Please read and agree to the Terms of Service and Privacy Terms.',
    message6: 'Agree and comply with the',
    message7: 'Terms of Service',
    message8: 'and',
    message9: 'Privacy Terms'
  },
  app: {
    message404: 'Sorry, the page you visited does not exist.',
    message505: `Sorry, you don't have permission to access this page.`,
    goBack: 'Return to home',
    beta: 'Beta',
    notice: '(Note: Data loss risk may exist during open beta)',
    tip: 'Tip'
  },
  community: {
    title1: 'Intelligence sharing platform in the digital age',
    title2: 'Modeling and simulation has never been easier',
    searchPlaceholder:
      'A large number of high-quality models for you to choose from',
    dialogTitle: 'You have received a repository invitation',
    dialogMessage: 'Invite you to join the repository as',
    tab1: 'Recommendation',
    tab2: 'Latest',
    repeatedApplicationMessage:
      'Under review, please do not repeat the application',
    applicationSuccess: 'Sent the request Successfully ',
    involved: 'participants'
  },
  course: {
    title: 'Tutorial Center',
    title1: 'Get started',
    message1: 'Six core functions enable you to get started in three minutes',
    title2: 'User Manual',
    message2: 'Explain all functions in an all-round way without dead angle',
    title3: 'Multi person collaboration',
    message3: 'An expert guide carefully prepared for you',
    title4: 'Cloud in One',
    message4: 'MoHub online version interacts with Sysplorer desktop version',
    title5: 'Common Problems',
    message5: 'High frequency problems and hidden tips are here',
    title6: 'Example Tutorial',
    message6: 'Common interaction effects',
    title7: 'Video Tutorial',
    message7: 'Quickly master MoHub'
  },
  doc: {
    serviceAgreement: 'Service Protocol',
    privacyPolicy: 'Privacy Policy'
  },
  notice: {
    messageReminder: 'Message Notices',
    invitationMessage: 'Invitation Message',
    readAll: 'Read All',
    mention: 'Mention to me'
  },
  personal: {
    personalHomepage: 'Personal Homepage',
    changeTheAvatar: 'Change The Avatar',
    accountSettings: 'Account Settings',
    uploadAvatar: 'Upload Avatar',
    selectPicture: 'Select Picture',
    pleaseSelectPicture: 'Please select picture',
    onlyOne: 'Only one file can be uploaded',
    validate1: 'Uploaded pictures can only be in jpg/jpeg/png',
    validate2: 'The size of the uploaded image cannot exceed 200KB',
    activity: 'Activity',
    repository: 'Repository',
    setting: {
      accountSettings: 'Account Settings',
      generalInformation: 'General Information',
      generalSettings: 'General Settings',
      accountInformation: 'Account Information',
      bindingInformation: 'Binding Information',
      securitySetting: 'Security Setting',
      loginHistory: 'Login History',
      bindAccountInformation: 'Bind account information',
      bindingTime: 'Binding time',
      unbind: 'Unbind',
      noBindingInformation: 'No binding information',
      thirdPartyAccountBinding: 'Third party account binding',
      unbindMessage:
        'This operation will unbind the WeChat account. Do you want to continue?',
      phone: 'Phone',
      changePhone: 'Change phone number',
      noPhone: 'No phone number',
      bindPhone: 'Bind phone number',
      email: 'Email',
      changeEmail: 'Change email',
      noEmail: 'No email',
      bindEmail: 'Bind email',
      changePassword: 'Change password',
      logOff: 'Log Off',
      logOffPromptMessage:
        'After you cancel your account, you will no longer be able to log in to MoHub with this account. All the content, information, data and records under the relevant products and services of MoHub will be deleted, and this operation is irreversible. Do you want to continue? If confirmed, please enter',
      pleaseEnter: 'Please enter ',
      logOffSucceeded: 'Log off succeeded'
    }
  },
  project: {
    message1: 'This is a private Repository',
    message2: 'This is a public Repository',
    file: 'File',
    relies: 'Dependencies',
    version: 'Version',
    manage: 'Manage',
    onlineModeling: 'Online Modeling',
    openSyslabOnline: 'Open Syslab Online',
    openInClient: 'Open in Client',
    downloadRepository: 'Download Repository',
    exitRepository: 'Exit repository',
    noAuthority:
      'You have no permission to view this repository, or the repository has been deleted',
    noAuthorityToDownload: 'No authority to download',
    exitRepositoryMessage:
      'This operation will exit the repository, do you want to continue?',
    clientVersion: 'Support MWORKS.Sysplorer 5.1.1.5727 and above',
    exetip:
      'You have not installed the {name} client software. Do you want to download it now?',
    checkHelp: 'Check the document',
    startDownload: 'Please wait for the download to start soon...',
    download: 'Downloading...',
    copy: {
      source: 'Copied from',
      empty: 'You do not have any copies of this repository',
      title: 'Your existing copies'
    }
  },
  workbench: {
    viewSimulationResult: 'View Simulation Results',
    pleaseSelectSimulationResult: 'Please select simulation result',
    nav: {
      recent: 'Recent',
      create: 'Create',
      star: 'Star',
      collect: 'Collect',
      copy: 'Copy',
      simulate: 'Simulation'
    },
    simulate: {
      modelName: 'Model Name',
      repoName: 'Repository Name',
      startTime: 'Start Time',
      allTime: 'Total time',
      status: 'Status',
      action: 'Action',
      check: 'Check',
      delete: 'Delete',
      note: 'Note',
      empty: 'No description',
      state: {
        submitted: 'The simulation request is submitted to the background',
        ready_container: 'Prepare for creating the simulation instance',
        submitted_container:
          'The simulation instance creation request has been initiated',
        created_container: 'The simulation instance is running correctly',
        loading_file: 'loading file',
        checking: 'checking',
        check_succeed: 'check succeeded',
        translating: 'translating',
        translate_succeed: 'translate succeeded',
        simulating: 'simulating',
        succeed: 'succeed',
        failed: 'failed',
        var_num_exceed: 'The number of variables exceeds the limit',
        pause: 'pause',
        continue: 'continue',
        stop: 'stop'
      },
      deleteInfo:
        'The simulation result cannot be retrieved after deletion. Are you sure to delete it?',
      stop: 'Stop',
      all: 'All',
      success: 'Success',
      failed: 'Failed',
      simulating: 'Simulating',
      placeholder: 'Please enter a model name'
    },
    import: {
      default: 'Import the repository',
      title: 'Import from GitHub/GitLab',
      currentPage: 'Import all repositories on the current page',
      tab: {
        url: 'From the URL to import',
        github: 'Import the GitHub repository',
        gitlab: 'Import into GitLab warehouse'
      },
      url: {
        gitUrl: 'URL of the git repository',
        repoName: 'Name of the repository',
        repoUrl: 'Path to the repository',
        repoDescription: 'Description of the repository',
        repoType: 'Type of repository',
        placeholder: 'Please enter',
        placeholderDes: 'Describe it in short words',
        public: 'Open (visible to all)',
        private: 'Private (visible only to warehouse members)',
        submit: 'Import',
        validateUrl: 'The Git repository URL is invalid'
      },
      github: {
        repo: 'GitHub Repo',
        target: 'Import To',
        size: 'Size',
        status: 'Status',
        action: 'Action',
        newRepoName: 'New Repo Name',
        errTip:
          'The user name only contains numbers, English and underscores, and does not start with a number',
        tip: 'An empty repository does not support import',
        check: 'Check',
        import: 'Import',
        create: 'Create',
        processing: 'Processing',
        fail: 'Fail',
        success: 'Success'
      }
    }
  },
  workspace: {
    title: 'Workspace',
    simulateTitle: 'Simulation Result',
    loading: 'Loading the model, please be patient...',
    empty: 'No Data',
    modelCreatedSuccessfully: 'Model created successfully',
    deleteModelConfirm:
      'The deleted model cannot be restored. Are you sure to delete it?',
    plotAttribute: 'Plot Attribute',
    selectColor: 'Select Color',
    tip: {
      title: 'tips',
      message:
        'Only one workspace can be opened for the same user.One of the possiable reason for this may be that a workspace is currently open, or it was closed abnormally last time.',
      confirmText: 'Close others',
      cancelText: 'Close current',
      textViewError:
        'There is a syntax error in the content of the text view. If you switch to another view, the modified data in the text view will be lost. Do you want to continue?',
      textViewError1:
        'There is a syntax error in the content of the text view. Relevant operations cannot be performed at this time!',
      textViewError2: 'Error in text view',
      cannotPackage: 'Cannot insert package directory',
      insertTypeMessage:
        'Cannot insert a component of type {type}. The inserted component type must be class, model, connector, block or record',
      insertTypeMessage1: 'Cannot insert a component of type parent model',
      insertTypeMessage2:
        'Drag a component of an abstract class {fullName}, and you must redeclare it to change its type before using it. Note that redeclaration is only applicable to replaceable components, so you should ensure that components are replaceable.',
      createReuseClass: 'Create reuse class',
      insertTypeMessage3:
        'Cannot insert a component of type {type}，The component type inserted into the icon view must be a connector.'
    },
    loginTip: {
      title: 'tips',
      message: 'Are you sure to sign out?'
    },
    plot: {
      addt: 'Add y(t)',
      addx: 'Add y(x)',
      window: 'Plot Window',
      animate: 'Add Animate',
      turnOnAreaZoom: 'Turn on area zoom',
      turnOffAreaZoom: 'Turn off area zoom',
      undoZoom: 'Undo zoom',
      zoomToBest: 'Zoom to best',
      zoomXAxisToBest: 'Zoom xAxis to best',
      zoomYAxisToBest: 'Zoom yAxis to best',
      setAxisRange: 'Set Axis range',
      clearCurrentChildPlot: 'Clear current child plot',
      removeCurrentRow: 'Remove current row',
      removeCurrentColumn: 'Remove current column',
      xAxisUnit: 'xAxis unit',
      xAxisRange: 'xAxis range',
      min: 'Min',
      max: 'Max',
      leftYAxisRange: 'Left yAxis range',
      rightYAxisRange: 'Right yAxis range',
      cannotBeEmpty: 'Cannot be empty',
      notLessThan: 'Not less than ',
      cannotBeGreaterThan: 'Cannot be greater than ',
      minNotGreaterMax: 'The min value cannot be greater than the max value',
      AxisRange: 'Axis range',
      copySuccess: 'Copy success',
      cannotCopy: 'Cannot copy',
      cannotCopyMessage:
        'It only supports copying pictures under the security domain',
      chartOperationMessage: 'Please select at least two curves',
      chartOperationMessage1: 'Please select only one curve',
      programException: 'Program exception, please refresh the page',
      plotAttribute: {
        curve: 'Curve',
        title: 'Title',
        titlePlaceholder: 'Please enter title',
        legend: 'Legend',
        legendLayout: 'Legend layout',
        hide: 'Hide',
        implant: 'Implant',
        float: 'Float',
        floatPosition: 'Float position',
        topLeft: 'Top Left',
        top: 'Top',
        topRight: 'Top Right',
        left: 'Left',
        right: 'Right',
        bottomLeft: 'Bottom Left',
        bottom: 'Bottom',
        bottomRight: 'Bottom Right',
        defaultLayout: 'Default layout',
        xAxisTitle: 'xAxis title',
        null: 'Null',
        default: 'Default',
        custom: 'Custom',
        leftYAxisTitle: 'Left yAxis title',
        rightYAxisTitle: 'Right yAxis title',
        name: 'Name',
        unit: 'Unit',
        description: 'Description',
        reset: 'Reset',
        appearance: 'Appearance',
        Axis: 'Axis',
        yAxis: 'yAxis',
        leftYAxis: 'Left yAxis',
        rightYAxis: 'Right yAxis',
        data: 'data',
        point: 'point'
      },
      scene: 'Add scene',
      model: 'Add model'
    },
    panel: {
      modelBrowser: 'Model Browser',
      modelParameters: 'Model Parameters',
      modelComponent: 'Model Component',
      modelDocument: 'Model Document',
      modelAttribute: 'Model Attribute',
      simulationBrowser: 'Simulation Browser',
      outputView: 'Output Log',
      toolbar: 'Change toolbar',
      sidebar: 'Change sidebar',
      panelbar: 'Change panelbar',
      auxiliarybar: 'Change auxiliarybar',
      zen: 'Change zen',
      general: 'General',
      parameters: 'Parameters'
    },
    code: {
      100: 'Services preparation',
      101: 'Service preparation failed, Please refresh and try again',
      102: 'Service preparation successfully, connecting in progress...',
      103: 'Service connection failed. Please refresh and retry or check the network status',
      104: 'Service connection successful, loading model...',
      105: 'Model load failed. Please refresh and try again',
      106: 'The model has loaded successfully and is about to enter the modeling space...'
    },
    modelBrowser: {
      modelLibrary: 'Model Library',
      modelUser: 'User Model',
      searchPlaceholder: 'Search Model',
      loaded: 'loaded',
      whetherChange: ', Whether to change to ',
      changeing: 'Switching model library, please wait patiently...'
    },
    simulationBrowser: {
      searchPlaceholder: 'Search Variable',
      name: 'Name',
      value: 'Value',
      unit: 'Unit',
      description: 'Description',
      menus: {
        expandTwo: 'Expand Two',
        fold: 'Fold',
        remove: 'Remove',
        resimulate: 'Resimulate',
        copyVariableName: 'Copy Variable Name'
      }
    },
    titleBar: {
      home: 'Home',
      edit: 'Edit',
      simulate: 'Simulate',
      plot: 'Plot',
      view: {
        title: 'Window',
        toolbar: 'Show toolbar',
        panelbar: 'Show panelbar',
        statusbar: 'Show statusbar',
        sidebar: 'Show sidebar',
        auxiliarybar: 'Show auxiliarybar',
        zen: 'Easy mode',
        quit: 'Quit easy mode'
      }
    },
    toolBar: {
      file: 'File',
      library: 'Library',
      model: 'Model',
      simulate: 'Simulate',
      plot: 'Plot',
      layout: 'Layout',
      view: 'View',
      clipboard: 'Clipboard',
      animate: 'Animate',
      plotTool: 'Plot Tool',
      plotOperation: 'Plot Operation',
      timePlaceholder: 'Please enter the time',
      selectPlaceholder: 'Please select',
      draw: 'Draw',
      action: {
        new: 'New',
        save: 'Save',
        library: 'Library',
        dependency: 'Dependency',
        graph: 'Graph',
        plot: 'Plot',
        split: 'Split',
        icon: 'Icon',
        diagram: 'Diagram',
        code: 'Text',
        document: 'Info',
        attribute: 'Attribute',
        grid: 'Grid',
        fitWindow: 'Fit Window',
        fitCanvas: 'Fit Canvas',
        painter: 'Painter',
        line: 'Line',
        rectangle: 'Rectangle',
        ellipse: 'Ellipse',
        polygon: 'Polygon',
        text: 'Text',
        picture: 'Picture',
        star: 'Star',
        outline: 'Outline',
        fill: 'Fill',
        arrange: 'Arrange',
        align: 'Align',
        forward: 'Forward',
        backward: 'Backward',
        cut: 'Cut',
        copy: 'Copy',
        paste: 'Paste',
        delete: 'Delete',
        selectAll: 'Select All',
        undo: 'Undo',
        redo: 'Redo',
        check: 'Check',
        translate: 'Translate',
        simulate: 'Simulate',
        pause: 'Pause',
        stop: 'Stop',
        setting: 'Setting',
        stopTime: 'StopTime',
        algorithm: 'Algorithm',
        plot_t: 'Add y(t) Plot',
        plot_x: 'Add y(x) Plot',
        add_child_plot: 'Add Child Plot',
        clear_child_plot: 'Clear Child Plot',
        remove_child_plot: 'Remove Child Plot',
        plot_zoom: 'Plot Zoom',
        cursor: 'Cursor',
        plot_grid: 'Plot Grid',
        export_image: 'Export Image',
        copy_image: 'Copy Image',
        sum: 'Sum',
        subtract: 'Subtract',
        multiply: 'Multiply',
        integration: 'Integration',
        differential: 'Differential',
        animate: 'Animate',
        animate_play: 'Play',
        animate_pause: 'Pause',
        animate_reset: 'Reset',
        animate_speed: 'Speed',
        animate_time: 'Time',
        copyFullName: 'Copy Full Name',
        open: 'Open',
        rename: 'Rename',
        create: 'Create Model From {label}',
        deleteSelectedVariable: 'Delete selected variable',
        displayUnits: 'Display units',
        yAxis: 'yAxis',
        color: 'Color',
        otherColors: 'Other colors',
        lineType: 'Linetype',
        symbol: 'Symbol',
        lineWidth: 'Line width'
      }
    },
    statusBar: {
      ready: 'Ready',
      simulate: 'Translation model...',
      check: 'Check model...',
      translate: 'Translation model...',
      pause: 'Pause'
    },
    popmenu: {
      cut: 'Cut',
      copy: 'Copy',
      paste: 'Paste',
      delete: 'Delete',
      selectAll: 'Select All',
      undo: 'Undo',
      redo: 'Redo',
      forward_top: 'On the top floor',
      forward_one: 'Move up a layer of',
      backward_bottom: 'At the bottom',
      backward_one: 'Down a layer of',
      rotate: 'Rotate 90 degrees',
      rotate_reverse: 'Rotate - 90 degrees',
      flip_h: 'Flip horizontal',
      flip_v: 'Flip vertical',
      rename: 'Rename'
    },
    simulate: {
      simulate: 'Start simulate',
      check: 'Start check',
      translate: 'Start translate',
      info: 'User {control} {action} the task',
      limit: 'The number of simulation steps exceeded the maximum limit',
      wait: 'Starting the simulation service',
      loading:
        'The simulation service started successfully and the model is being loaded',
      stop: 'The user terminated the task',
      find: 'The task could not be found'
    },
    modelAttribute: {
      save: 'Attribute modified successfully'
    }
  },
  homepage: {
    message1: 'The Interconnected Platform for Industrial Knowledge Modeling',
    message2:
      'Support the modeling, software and platformization of industrial knowledge, experience and data; realize the creation, sharing and collaboration of knowledge; promote the interconnection of knowledge models',
    message3: 'Modeling and Simulation Cloud Platform',
    message4: 'Featured Models',
    message5: 'Knowledge interconnection, starting from here',
    message6: 'Knowledge sharing and interconnection',
    message7: 'Modeling and simulation using third-party libraries',
    message8: 'Support multi-person collaborative modeling',
    message9: 'Online experience of Tongyuan built-in model library',
    message10: 'Support for encrypted model library',
    message11: 'A lightweight Modelica WebIDE',
    message12: 'Support drag-and-drop modeling, text modeling',
    message13:
      'Rapidly conduct system modeling and simulation , verify ideas at any time',
    message14: 'Support for distributed simulation',
    message15: 'Support for elastic calculation',
    message16: 'A world-class solution kernel built over more than 20 years',
    message17: 'Online Post-processing',
    message18: 'Simple and fast post-processing',
    message19: 'Convenient curve viewing and curve calculation functions',
    message20:
      'Support for 3D animation, including stl, dxf, obj and other formats',
    message21: 'Cloud in One',
    message22:
      'Breaking the boundaries between cloud platform and client software',
    message23:
      'Support for cloud and client model library information data synchronization',
    message24: 'Modeling and knowledge sharing for multiple environments',
    message25: 'Download Sysplorer Client',
    message26: 'Join MoHub',
    message27: 'Growing with thousands of engineers',
    message28: 'Quick Registration',
    goToWorkbench: 'Go to Workbench',
    moreModel: 'More Models',
    modelingAndSimulation: 'Modeling and simulation',
    textModeling: 'Text Modeling',
    cloudSimulation: 'Cloud Simulation',
    curveOperation: 'Curve operation',
    curveView: 'Curve view',
    threeDimensionalAnimation: '3D animation'
  },
  components: {
    password: {
      phone: 'mobile/email',
      verification: 'Verification code',
      getVerification: 'Get Verification Code',
      resend: 'Resend after {time}s',
      newPassword: 'New password',
      validate1: 'Please enter your mobile/email address',
      validate2: 'Please enter the correct mobile/email',
      validate3: 'This account does not exist',
      validate4: 'Please input password',
      validate5: 'Password does not support Chinese',
      validate6: 'Password length is less than six digits',
      validate7: 'Please enter the verification code',
      validate8: 'Password set successfully',
      validate9: 'The new password cannot be the same as the old password'
    },
    login: {
      name: 'User name/mobile/email',
      forget: 'Forgot your password?',
      login: 'Password free login',
      login1: 'Login',
      bind: 'Login and bind',
      account: 'Account login',
      validate: 'Please enter user name/mobile/email',
      validate1: 'Please enter user name',
      username: 'Username',
      register: 'Sign up',
      register1: 'Sign up and bind'
    },
    comments: {
      expand: 'Expand hidden comments',
      reply: 'Reply',
      edit: 'Edit',
      delete: 'Delete',
      report: 'Report',
      modify: 'Modify',
      notNull: 'Comment content cannot be empty',
      count: 'Repository Comments',
      noMore: 'No more comments',
      noContent: 'No content temporarily',
      pleaseLogin: 'Want to post or view comments?',
      login: 'Login immediately',
      comment: 'Comment',
      deleteComment: 'Are you sure to delete this comment?'
    },
    dependencyTree: {
      input: 'Please enter the model name',
      quick: 'Quick',
      fold: 'Fold',
      unfold: 'Unfold',
      delete: 'The repository has been deleted',
      noAuth: 'Permission: No permission to view',
      name: 'Name',
      edition: 'Edition',
      creator: 'Creator',
      deleted: 'Deleted',
      checkDetail: 'Check detail',
      changeVersion: 'Change version',
      remove: 'Delete dependency',
      empty: 'No version data is available in this warehouse',
      title: 'Change dependent version',
      select: 'Please select version',
      version: 'Version'
    },
    image: {
      select: 'Upload',
      reselect: 'Reupload',
      confirm: 'Confirm upload',
      noImage: 'No recommended model pictures',
      please: 'Please select a file to upload',
      only: 'Only one file can be uploaded',
      format:
        'The uploaded image type can only be one of jpg/jpeg/gif/png format',
      size: 'The size of the uploaded image cannot exceed 150kb'
    },
    table: {
      more: 'More...',
      row: 'Row',
      col: 'Column',
      curve: 'Curve layout'
    },
    chart: {
      topLeft: 'Top left',
      top: 'Top',
      topRight: 'Top right',
      left: 'Left',
      right: 'Right',
      bottomLeft: 'Bottom left',
      bottom: 'Bottom',
      bottomRight: 'Bottom right',
      hide: 'Hide'
    },
    video: {
      complete: 'Complete Modelica modeling and simulation function',
      support:
        'It supports online declarative modeling and text modeling, and can quickly conduct system modeling and simulation.',
      single: 'Fully independent industrial software kernel',
      worth:
        'Tongyuan soft control has been polished for more than 20 years and has experienced numerous major engineering tests. The happiness of independence is worth having.',
      third: 'Support third-party libraries',
      protect:
        'The third-party library can be used online for modeling and simulation; Support encryption model library, we are very careful to protect your intellectual property.'
    },
    report: {
      reason: 'Reasons',
      please: 'Please specify the reason for reporting',
      validate: '1 to 100 characters in length',
      success: 'Reported successfully!'
    },
    modal: {
      rename: 'Component rename',
      input: 'Please enter a name',
      validate:
        'The name must start with a letter or underscore, and can only contain letters, numbers, and underscores',
      modelName: 'Model name',
      component: 'Component Name',
      new: 'New model',
      type: 'Model category',
      select: 'Please select a model category',
      description: 'Description',
      insert: 'Insert into',
      parent: 'Please select a parent model',
      save: 'Save type',
      selectType: 'Please select a save type',
      class: 'Abstract class or not',
      validate1: 'Please enter the model name',
      validate2:
        'The model name must start with a letter or underscore, and can only contain letters, numbers, and underscores',
      validate3: 'Model name already exists',
      validate4: 'The model name cannot be the same as the Modelica keyword',
      validate5: 'Model name cannot be the same as Modelica built-in function',
      validate6: 'Model name cannot be the same as Modelica built-in type',
      validate7: 'The renamed name has not changed',
      modelType: 'Please select a model type',
      single: 'Save as a single file',
      directory: 'Save as directory structure',
      parentFile: 'Save to the file of the parent model',
      library: 'Model library',
      search: 'Search model library',
      pleaseSelect: 'Please select',
      version: 'Version',
      my: 'My repository',
      loading: 'Loading...',
      uninstall: 'Uninstall',
      install: 'Install',
      reload: 'Repository {name} has been loaded. Do you want to reload it?',
      noContent: 'The repository has no content temporarily',
      modelRename: 'Model rename',
      email: 'Email',
      inputEmail: 'Please enter your email address',
      verification: 'Verification',
      correct: 'Please enter the correct email address',
      exists: 'The mailbox already exists',
      bind: 'Bind mailbox',
      replace: 'Replace mailbox',
      updatePassword: 'Change password',
      oldPassword: 'Old password',
      inputOld: 'Please enter the old password',
      newPassword: 'New password',
      inputNew: 'Please enter the new password',
      validate8: 'Password shall not be less than 6 digits',
      validate9: 'The new password cannot be the same as the old password',
      tip:
        'The password has been modified successfully. You are about to sign out and log in again',
      phone: 'Phone',
      inputPhone: 'Please enter your mobile number',
      validate10: 'Please enter the correct mobile number',
      validate11: 'The phone number already exists',
      bindPhone: 'Bind mobile number',
      change: 'Change phone number',
      tip1: 'Phone binding succeeded',
      connect: 'Connect',
      message1:
        'Select the sub components of the external connector from the component list to connect.',
      message2: 'Connect to the appropriate connector',
      message3: 'The types of connectors do not match.',
      message4: 'Invalid subscript index specified',
      createConnection: 'Create connection'
    },
    message: {
      info: '{name} @ you in {project}',
      time: '{date} in repository {project}',
      mark: 'Mark as read',
      invite:
        ' join the personal repository {project} at the invitation of administrator {name}',
      reject:
        " refuse administrator {name}'s invitation to join personal repository {project}",
      join: 'Confirm joining',
      refuse: 'Refuse',
      pass:
        ' apply to join the personal repository {project} through the invitation link of {name}',
      remove: ' removed from repository {project} by {name}',
      apply:
        'Apply to join the personal repository through the invitation link of {name}',
      manage:
        'Invite to join the personal warehouse through administrator {name}',
      agree: 'Agree'
    },
    simulate: {
      setting: 'Simulation Settings',
      modelName: 'Model name',
      startTime: 'Start time',
      input: 'Please enter the start time',
      endTime: 'End time',
      inputEnd: 'Please enter the end time',
      output: 'Output range',
      step: 'Step',
      inputStep: 'Please enter the step size',
      steps: 'Step number',
      inputSteps: 'Please enter the number of steps',
      stepRange: 'Step range',
      algorithm: 'Algorithm',
      precision: 'Precision',
      inputPrecision: 'Please enter the precision',
      initial: 'Integration step',
      inputInitial: 'Please input the initial integration step',
      integration: 'Integration steps',
      result: 'Result storage',
      store: 'Store the variable value at the time of the event',
      explain: 'Explain',
      inputExplain: 'Please enter the description',
      invalid: 'Invalid start time setting',
      less: 'The start time must be less than the end time',
      invalid1: 'Invalid end time setting',
      more: 'The end time must be greater than the start time',
      invalid2: 'Invalid interval length setting',
      invalid3: 'Invalid interval step number setting',
      invalid4: 'Invalid precision setting',
      initial1: 'Initial integration step',
      integration1: 'Integration steps',
      invalid5: 'Invalid setting',
      inputRange: 'Please enter the output range',
      inputAlgorithm: 'Please enter the algorithm',
      simulateRange: 'The simulation interval',
      integralAlgorithm: 'Integral algorithm',
      fixedIntegral: 'Segment fixed integral step',
      tip: 'Tip',
      save: 'Save',
      test: 'Test',
      successTip: 'Simulate successful',
      goResult: 'Do you want to check the simulation results immediately?'
    },
    page: {
      home: 'Home Page',
      model: 'Model Community',
      tutorial: 'Tutorial',
      repo: 'Repository detail',
      workbench: 'My workbench',
      homepage: 'Personal Homepage',
      setting: 'Account Settings',
      message: 'Message reminder'
    },
    diff: {
      my: 'Mine',
      merge: 'Merge results',
      cloud: 'Remote',
      submit: 'Submit information',
      input: 'Please enter the submission information',
      left: 'Select left',
      right: 'Select right',
      giveUp: 'Give up'
    },
    detail: {
      recent: 'Recent Activities',
      noDynamic: 'No activities',
      intro: 'Introduction',
      noIntro: 'No introduction',
      license: 'License',
      contributor: 'Contributors',
      noCon: 'No contributors',
      input: 'Please enter the tag name',
      tip: 'This operation will delete this tag. Do you want to continue?'
    },
    modelica: {
      newest: 'Latested',
      input: 'Please enter model name',
      model: 'Updating the model view',
      noView: 'Model view unavailable',
      simulating: 'Simulating',
      simulation: 'Simulate',
      log: 'Log',
      text: 'Text',
      icon: 'Icon',
      component: 'Component',
      explain: 'Explain',
      var: 'Variable',
      output: 'Output',
      close: 'Close',
      setting: 'Simulation Settings',
      tip: 'There is a simulation in progress, please wait',
      noSelect: 'No model selected',
      validate: 'The current component is the smallest viewable unit!',
      name: 'Name',
      default: 'Default',
      unit: 'Unit',
      description: 'Description',
      tip1: 'The current version model view is being generated, please wait...',
      tip2: 'Failed to generate the current version model view.'
    },
    role: {
      participant: 'Participant',
      observer: 'Observer',
      developer: 'Developer',
      controller: 'Manager',
      owner: 'Owner'
    },
    setting: {
      link: 'Invitation link',
      share: 'Invite members by sharing the link address',
      permission: 'Permission:',
      url: 'Link address:',
      reset: 'Reset link',
      copy: 'Copy link',
      add: 'Add directly',
      addUser: 'Add user directly',
      user: 'User:',
      input: 'Please enter the user name/mobile phone number/email address',
      add1: 'Add',
      select: 'Please select a user',
      success: 'Copied',
      send: 'Request sent successfully',
      owner: 'Owner',
      me: 'Me',
      create: 'Created at {time}',
      invite: 'Invited at {time}',
      exit: 'Exit project',
      remove: 'Move out of repository',
      sure: 'This operation will remove {name} off, Do you want to continue?',
      member: 'Add as Member',
      noMember:
        '「There is no {label} permission member in the current repository',
      invited: 'Invite new members',
      comment: 'Comments',
      allow: 'Comments allowed',
      copy1: 'Copy',
      allow1: 'Copy allowed',
      allow2: 'Download allowed',
      base: 'Essential Information',
      cover: 'Cover',
      change: 'Replace cover',
      repoName: 'Name',
      repoType: 'Type',
      open: 'Visibility level',
      private: 'Private',
      public: 'Public',
      tag: 'Label',
      selectTag: 'Please select the repository label',
      setCover: 'Set cover',
      validate: 'Please enter the repository name',
      validate1:
        'Repository name only contains numbers, English and underscore, and does not start with a number',
      validate2: 'Please select repository type',
      validate3: 'Please select the repository visibility level',
      sure1: 'This operation will delete this label. Do you want to continue?',
      unpass: 'Verification failed',
      tip: 'This operation cannot be resumed! Please be careful!',
      tip1:
        'Clear the repository. This operation will clear the repository code and close the pages service. It will not clear the issues, wikis and other settings of the repository.',
      clear: 'Clear the repository',
      sureClear:
        'This operation will clear the repository. Do you want to continue?',
      cleared: 'The repository was cleared',
      tip2:
        'Deleting a repository will remove all data associated with it (including versions, version views, tags, etc.) and unlink the repository to its collaborators. Deleted repositories will no longer be displayed in the repository list.',
      delete: 'Delete the repository',
      tip3:
        'This operation will permanently delete the repository, do you want to continue? If confirmed, please enter ',
      pleaseInput: 'Please input',
      deleted: 'Repository has been deleted'
    },
    upload: {
      directory: 'File directory',
      submit: 'Submit information',
      select: 'Select file',
      click: 'Click to upload',
      validate: 'Upload file size limit 50MB in total',
      validate1: 'Only one file can be uploaded once',
      validate2: 'Please select a file',
      success: 'File upload succeeded',
      validate3: 'File already existed',
      name: 'File name',
      validate4: 'Single file limits 10MB!',
      validate5: 'Please select at least one file',
      validate6: 'Folder upload succeeded',
      validate7: 'File or fold already existed',
      validate8: 'Please upload at least one folder',
      validate9: 'Cannot upload more than 10 files once',
      path: 'File path',
      input: 'Please enter file name',
      back: 'Return to the previous level',
      file: 'File',
      new: 'New file',
      upload: 'Upload file',
      newFolder: 'New folder',
      uploadFolder: 'Upload folder',
      submitTime: 'Submitted at',
      check: 'Cannot view online, please check after {download}',
      download: 'Download repository',
      inputSubmit: 'Please enter the submission message',
      folder: 'Folder name',
      validate10: 'Please enter a folder name',
      validate11: 'Folder name limited 32 characters',
      validate12: 'All you inputted are blankspaces',
      validate13: 'Only alphabets, numbers and underscores are allowed',
      validate14: 'Please enter the label name',
      validate15: 'File Deletion',
      validate16: 'Please enter the saving information',
      copySuccess: 'Copied successfully',
      tip: 'The label will be deleted. Do you want to continue?',
      createSuccess: 'Folder created successfully!',
      inputName: 'Please enter the file name at first',
      deleteTip: 'Tip: After the file is deleted, it cannot be restored.'
    },
    manage: {
      base: 'Basic Settings',
      repo: 'Repository Settings',
      info: 'Essential Information',
      permission: 'Permission Settings',
      delete: 'Delete Repository',
      member: 'Member',
      all: 'All',
      manager: 'Manager',
      apply: 'Application List',
      contributors: 'All contributors',
      create: 'Create Version',
      noVersion: 'No version description information',
      created: 'Created at ',
      download: 'Download',
      view: 'Click to regenerate the model view',
      version: 'Version',
      recommend: 'Recommended format: V1.0.0',
      description: 'Version Description',
      build: 'Build Version View',
      input: 'Please enter the version number',
      sure: 'Are you sure to regenerate the version view?',
      building: 'Generating version view',
      continue:
        'This operation will delete this version. Do you want to continue?',
      version1: 'Version'
    },
    widget: {
      favorite: 'Favorite',
      collect: 'Collect',
      copied: 'Copied',
      copy: 'Copy',
      repo: 'Copy repository',
      repoName: 'Repository name',
      input: 'Please enter the repository name',
      exist: 'The repository name already exists',
      Liked: 'Starred',
      Like: 'Star'
    }
  },
  header: {
    message: 'My messages',
    workbench: 'Workbench',
    profile: 'Personal Homepage',
    setting: 'Account Settings',
    quick: 'Get started',
    help: 'Help Documentation',
    signOut: 'Sign out',
    login: 'Login',
    register: 'Sign up',
    homepage: 'Home Page',
    model: 'Model Community',
    course: 'Tutorial',
    sure: 'Are you sure you want to sign out?'
  },
  footer: {
    service: 'Service Agreement',
    privacy: 'Privacy Policy',
    contact: 'Contact Us',
    client: 'Client Downloads',
    right: 'all rights reserved',
    message:
      'You can call 0512-62720716 (switchboard)/18115503283 (market) (9:00-17:00 on working days) or leave your contact information, our staff will contact you within three working days.',
    name: 'Name',
    corporate: 'Corporate name',
    mobile: 'Mobile phone',
    email: 'Email',
    consultation: 'Consultation content',
    validate: 'Please enter the correct mobile number',
    validate1: 'Please enter your name',
    validate2: 'Please enter your mobile number',
    validate3: 'Please enter the email address',
    validate4: 'Please enter the correct email address',
    send: 'Sending succeeded',
    company: 'Suzhou Tongyuan Software&Control Technology Co., Ltd'
  },
  repository: {
    public: 'Public',
    private: 'Private',
    star: 'Star',
    collect: 'Collect',
    copy: 'Copy',
    createNewRepo: 'Create New Repository',
    personal: 'Personal',
    mine: 'Mine',
    involved: 'Involved',
    name: 'Name',
    modelTypes: 'Model types',
    openTypes: 'Open types',
    tags: 'Tags',
    pleaseSelectTags: 'Please Select Repository Tags',
    initModel: 'Init model',
    validate1: 'Please enter repository name',
    validate2: 'The length of repository name cannot exceed 50',
    validate3: 'Repository name already exists',
    validate4:
      'Repository name only contains numbers, English and underscore, and does not start with a number',
    validate5: 'Please select the Repository opening type',
    validate6: 'Please select the Repository Model type',
    validate7: 'Please enter a short description'
  },
  requestMessage: {
    operationSucceeded: 'Operation Succeeded',
    uploadSucceeded: 'Upload Succeeded',
    userLoggedOff: 'The user is logged off',
    createSucceeded: 'Create Succeeded',
    updateSucceeded: 'Update Succeeded'
  },
  activity: {
    createdMessage: 'cerated repository',
    deletedMessage: 'deleted repository',
    clearedMessage: 'cleared repository',
    startedMessage: 'started repository',
    cancelStartedMessage: 'cancel started repository',
    collectedMessage: 'collected repository',
    cancelCollectedMessage: 'cancel collected Message',
    copiedMessage: 'copied message',
    pushToMessage: 'push to repository',
    tagCreatedMessage: '{name} added a new version in the repository {repo}',
    deleteTagMessage: '{name} deleted a version in the repository {repo}',
    commentedMessage: '{name} commented in the repository {repo}',
    deleteCommentMessage: '{name} deleted a comment in the repository {repo}',
    updateCommentMessage: '{name} update a comment in the repository {repo}',
    addUserToRepo: '{inviter} invites {invitee} to join the repository {repo}',
    updateUserAuthority: "Apdated {name}'s authority in the repository {repo}",
    updatedAuthority: '{name} updated your authority in the repository {repo}',
    deleteMember: '{deleter} removed {deleteBy} from the repository {repo}'
  },
  utils: {
    year: 'years ago',
    months: 'months ago',
    day: 'days ago',
    hour: 'hours ago',
    minutes: 'minutes ago',
    seconds: 'seconds ago',
    day1: ' days',
    hour1: ' hours',
    minutes1: ' minutes',
    seconds1: ' seconds',
    tip: 'Please select the simulation results that support animation',
    tip1: 'Please select the animation window',
    translate: 'Package cannot be translated'
  },
  store: {
    var: 'The variable already exists and cannot be added repeatedly',
    diff:
      'The comparison of different simulation results is not supported temporarily',
    REPO_CREATE: 'Create repository',
    REPO_DELETE: 'Delete repository',
    REPO_CLEAR: 'Clear the repository',
    REPO_STAR: 'Like repository',
    REPO_COLLECTION: 'Collection repository',
    REPO_FORK: 'Fork repository',
    REPO_PUSH: 'Submit content',
    TAG_CREATE: 'Add tag',
    TAG_DELETE: 'Delete tag',
    COMMENT_CREATE: 'Publish comments',
    COMMENT_DELETE: 'Delete comments',
    COMMENT_UPDATE: 'Modify comments',
    GROUP_CREATE: 'Create a group',
    GROUP_DELETE: 'Delete the group',
    GROUP_UPDATE: 'Modify group information',
    MEMBER_ADD: 'Add member',
    MEMBER_BE_ADDED: 'Member added',
    MEMBER_UPDATE: 'Edit member permissions',
    MEMBER_BE_UPDATED: 'Member permission is edited',
    MEMBER_DELETE: 'Delete member',
    MEMBER_BE_DELETED: 'The member is deleted',
    solid: 'Solid line',
    dashed: 'Dashed line',
    dotted: 'Dotted line',
    none: 'None',
    circle: 'Circle',
    rect: 'Rect',
    triangle: 'Triangle',
    diamond: 'Diamond',
    single: 'Single line width',
    double: 'Double line width',
    quadruple: 'Quadruple line width',
    unfill: 'No padding',
    solid1: 'Solid',
    level: 'Level',
    degree45: '-45 degree diagonal',
    degree_45: '45 degree diagonal',
    line: '45 degree diagonal crossing line',
    horizontal: 'Horizontal cylindrical surface',
    vertical: 'Vertical cylindrical surface',
    ball: 'Spherical',
    dashDotL: 'Dot dash line',
    dashDotDotLine: 'Double dot dash line',
    fangsong: 'Simsun',
    song: 'Songtypeface',
    black: 'Boldface',
    regular: 'Regular script',
    pass: 'Application passed',
    applying: 'Applying',
    unpass: 'Application failed',
    invited: 'Invitation succeeded',
    inviting: 'Inviting',
    refuse: 'Invitation declined'
  },
  WebSocket: {
    message: 'Missing or incorrect mandatory parameters',
    message1: 'Failed to create block',
    message2: 'Illegal subscript',
    message3: 'Failed to save the component to the bottom',
    message4: 'Failed to get component variables',
    message5: 'Connection creation failed',
    message6: 'Invalid model name',
    message7: 'Invalid component name',
    message8: 'Failed to delete component',
    message9: 'Failed to delete the connection',
    message10: 'Unit conversion failed',
    message11: 'Failed to set the unit',
    message12: 'Failed to modify the parameter value',
    message13: 'Failed to set parameters',
    message14: 'Failed to set the connection to the bottom layer',
    message15:
      'Unable to convert, the new type is not class, model, block or record',
    message16: 'Failed to modify the component type',
    message17:
      'Unable to create block, please check whether the model name is correct',
    message18: 'Failed to move components',
    message19: 'Component renaming failed',
    message20: 'A type with the same name exists, model renaming failed',
    message21: 'Parent model does not exist, model renaming failed',
    message22: 'The storage path does not exist, model renaming failed',
    message23:
      'The storage path has no permission to write files, and model renaming failed',
    message24:
      'A file with the same name already exists in the storage path, model renaming failed',
    message25: 'Rename failed',
    message26: 'The new model name is illegal',
    message27: 'The Modelica keyword cannot be used as the model name',
    message28: 'Cannot use Modelica built-in type as model name',
    message29: 'Cannot use the Modelica built-in function as the model name',
    message30:
      'Unable to create block, please check whether the model name is correct',
    message31: 'Failed to rotate components',
    message32: 'Failed to save model simulation settings',
    message33: 'Error in text syntax, failed to set model text',
    message34:
      'Unable to create block, please check whether the model name is correct',
    message35: 'Failed to edit component size',
    message36: 'The model name is illegal',
    message37: 'Failed to get package name',
    message38: 'The model cannot be created',
    message39: 'Failed to save the model to the file',
    message40: 'The model library does not exist',
    message41: 'The model library of the current version has been loaded',
    message42: 'Failed to load the model library, please try again',
    message43: 'Model library path not found',
    message44:
      'Failed to load the model, please check whether a model with the same name exists',
    message45: 'The model name is illegal',
    message46: 'The model extension is illegal',
    message47: 'Failed to create a new model',
    message48: 'A model with the same name exists, but the new model failed',
    message49:
      'A component with the same name exists, but failed to create a new model',
    message50:
      'A file with the same name and different case has been loaded, loading the model failed',
    message51: 'The parent model does not exist, loading the model failed',
    message52: 'The storage path does not exist, creating the model failed',
    message53:
      'The storage path is in the structured model, and the new model failed',
    message54:
      'The storage path does not have write permission. Failed to create a new model',
    message55:
      'A file with the same name already exists in the storage path. Failed to create a new model',
    message56: 'The new model name is illegal',
    message57: 'The Modelica keyword cannot be used as the model name',
    message58: 'Cannot use Modelica built-in type as model name',
    message59: 'Cannot use the Modelica built-in function as the model name',
    message60: 'Failed to save the model',
    message61: 'Failed to copy intermediate file',
    message62: 'The current model is not a package type',
    message63: 'Failed to call the packaging tool',
    message64: 'Model publishing failed',
    message65: 'Failed to read dependency',
    message66: 'Failed to save the model',
    message67: 'Graph redrawing failed',
    message68: 'Failed to save the icon',
    message69: 'Failed to save components',
    message70: 'Connection creation failed',
    message71: 'Unable to save the model library',
    message72: 'Failed to save the model',
    message73: 'Failed to unload the model',
    message74: 'Write dependency failed',
    message75: 'Failed to get the line',
    message76: 'Failed to get polygon',
    message77: 'Failed to get rectangle',
    message78: 'Failed to get the ellipse shape',
    message79: 'Failed to get the text',
    message80: 'Failed to get the resource file',
    message81: 'Failed to save the graph to the graph layer',
    message82: 'Failed to save the graph to the icon layer',
    message83: 'Failed to send MoHub server request',
    message84: 'The user is not recognized by the MoHub server',
    message85: 'The MoHub server cannot be found',
    message86: 'Repository user name and password resolution failed',
    message87: 'Warehouse ID resolution failed',
    message88: 'Failed to send MoHub server request',
    message89: 'The Git warehouse cloning failed',
    message90: 'Failed to push the model',
    message91: 'Failed to check the initialization of the connection tool',
    message92: 'The connection already exists',
    message93: 'Extensible connections are not supported temporarily',
    message94: 'Connection signal conflict',
    message95: 'Array type component connection is not supported temporarily',
    message96: 'Connector type does not match',
    message97: 'No matching model found',
    message98: 'Failed to get the top-level model',
    message99: 'There is no sub model for this model',
    message100: 'Failed to obtain component information',
    message101: 'Component variable acquisition failed',
    message102: 'Failed to obtain component information',
    message103: 'Failed to obtain model information',
    message104: 'Failed to check the initialization of the connection tool',
    message105: 'Failed to save the model',
    message106: 'Failed to get the model document',
    message107: 'Failed to get model text',
    message108: 'Failed to get the component type',
    message109: 'Service exception'
  },
  svg: {
    text: 'No component view is available. Click to view it',
    text1: 'No icon information, click to view',
    text2: 'Or',
    text3: 'Text',
    text4: 'Icon',
    text5: 'Diagram'
  }
};
