<template>
  <el-skeleton :loading="loading" :count="count" :rows="rows" animated>
    <template slot="template" v-if="type">
      <component :is="activeComponent"></component>
    </template>
    <template>
      <template v-if="data.length">
        <slot></slot>
      </template>
      <template v-else>
        <el-empty description="暂无数据" />
      </template>
    </template>
  </el-skeleton>
</template>

<script>
import SkeletonCard from './card.vue';
import SkeletonList from './list.vue';
import SkeletonImage from './image.vue';
import SkeletonCase from './case.vue';
export default {
  name: 'Skeleton',
  components: {
    SkeletonCard,
    SkeletonList,
    SkeletonImage,
    SkeletonCase
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    // 如果template，该字段生效
    count: {
      type: Number,
      default: 1
    },
    // 如果没有template，该字段生效
    rows: {
      type: Number,
      default: 4
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    activeComponent() {
      if (this.type) {
        // 首字母大写
        const type = this.type.slice(0, 1).toUpperCase() + this.type.slice(1);
        return 'Skeleton' + type;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
