<template>
  <div class="item">
    <div class="item-object">
      <el-skeleton-item variant="image" style="width: 100%;height:100%" />
    </div>
    <div class="item-bd">
      <el-skeleton-item variant="text" style="width:30%;margin-bottom: 10px;" />
      <el-skeleton-item variant="text" style="margin-bottom: 30px;" />
      <el-skeleton-item variant="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonList',
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
.item {
  display: flex;
  padding: 30px 0;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
  transition: all 0.2s;

  &:first-child {
    padding-top: 0;
  }

  &-object {
    width: 240px;
    height: 120px;
  }

  &-bd {
    flex: 1;
    padding-left: 30px;
  }
}

@media screen and (max-width: 700px) {
  .item {
    display: block;

    &-object {
      width: 100%;
    }

    &-bd {
      padding-top: 20px;
      padding-left: 0;
    }
  }
}</style>
