export default {
  common: {
    serarch: '搜索{name}',
    manage: '{name}管理',
    name: '{name}名称',
    repo: '仓库',
    select: '请选择',
    system: '系统',
    profile: '个人信息',
    member: '团队',
    logout: '退出',
    submit: '提交',
    determine: '确定',
    cancel: '取消',
    save: '保存',
    warning: '警告',
    seeMore: '查看更多',
    noData: '暂无数据',
    loadMore: '加载更多',
    haveNoMore: '没有更多了',
    content: '内容',
    operation: '操作',
    confirm: '确认',
    password: '密码',
    oldPassword: '原始密码',
    newPassword: '新密码',
    confirmPassword: '确认密码',
    updateSuccess: '编辑成功',
    updateFailed: '更新失败',
    username: '用户名',
    realname: '真实姓名',
    mine: '我的',
    more: '更多',
    also: '还有',
    commit: '次提交',
    push: '推送了代码到',
    deleteRepoTip: '仓库已删除，无法访问',
    systemError: '系统错误',
    classification: '密级',
    description: '描述',
    time: '时间',
    type: '类型',
    deleteRepo: '删除仓库',
    publishRepo: '发布仓库',
    applicant: '申请人',
    modelName: '仓库名称',
    creator: '创建人',
    updateTime: '更新时间',
    status: '状态',
    recent: '刚刚',
    seconds: '秒',
    minutes: '分钟',
    hours: '小时',
    days: '天',
    months: '月',
    ago: '{name}前',
    noDescription: '暂无描述',
    done: '已处理',
    from: '来自',
    createdTime: '创建时间',
    applyStatus: '审批状态',
    input: '请输入内容',
    tip: '提示',
    model: '模型',
    success: '成功',
    fail: '失败',
    delete: '删除',
    info: '消息',
    cancelDelete: '已取消删除',
    deletionSucceeded: '删除成功!',
    deletionFailed: '删除失败!',
    noAnimation: '暂无动画',
    edit: '编辑',
    add: '新增',
    isDelete: '确认删除',
    yes: '是',
    no: '否',
    addSuccess: '添加成功',
    editSuccess: '修改成功',
    saving: '正在保存...',
    saveSuccess: '保存成功',
    saveFailed: '保存失败',
    editFail: '修改失败',
    notDelete: '无删除权限',
    noPermission: '账户无权限',
    loading: '拼命加载中...',
    keyword: '请输入关键词',
    search: '查询',
    reset: '重置',
    read: '可读',
    manager: '管理',
    write: '可写',
    owner: '拥有者',
    important: '重要',
    filter: '输入关键字进行过滤',
    WeChat: '微信',
    all: '全部',
    noIntroduction: '暂无简介',
    introduction: '个人简介',
    public: '公开',
    private: '私有',
    operationSucceeded: '操作成功',
    noResult: '无搜索结果',
    you: '你',
    download: '下载',
    update: '更新',
    index: '序号',
    detail: '详情',
    simulate: '仿真',
    plot: '图表',
    course: '教程中心',
    consultation: '售前咨询',
    question: '反馈问题'
  },
  login: {
    message1: '绑定已有账号',
    message2: '注册新账号并绑定',
    noAccount: '没有账号?',
    registerNow: '立即注册',
    haveAccount: '已有账号?',
    clickBind: '点击绑定'
  },
  sign: {
    login: '登录',
    register: '注册',
    registerAccount: '注册MoHub账号',
    resetPassword: '重置密码',
    noAccount: '没有账号?',
    registerNow: '立即注册',
    haveAccount: '已有账号?',
    goToLogin: '前往登录',
    otherLoginMethods: '其他登录方式',
    message1: '工业知识模型互联平台',
    message2: '随时随地在线建模',
    message3: '随时随地在线仿真',
    message4: '简洁完整的后处理功能',
    message5: '高效的交互式学习平台',
    title: '登录 / 注册',
    namePlaceholder: '用户名/手机号/邮箱',
    password: '密码',
    newPassword: '新密码',
    forgotPassword: '忘记密码？',
    passwordFreeLogin: '手机号/邮箱登录',
    loginAndBind: '登录并绑定',
    registerAndBind: '注册并绑定',
    passwordFreeLoginPlaceholder: '手机号/邮箱',
    verificationCode: '验证码',
    getVerificationCode: '获取验证码',
    getVerificationCodeMessage: '{count}s后重新发送',
    accountPasswordLogin: '账号密码登录',
    validate1: '请输入用户名/手机号/邮箱',
    validate2: '该账号不存在',
    validate3: '请输入手机号/邮箱',
    validate4: '请输入正确的手机号/邮箱',
    validate5: '该账号已存在',
    validate6: '请输入密码',
    validate7:
      '密码必须包含数字，且必须包含字母或其它符号（!@_#$%^&*()-+=,.?）',
    validate8: '密码的长度必须是 6-32 位',
    validate9: '请输入验证码',
    validate10: '请输入用户名',
    validate11: '用户名仅含数字、英文和下划线',
    validate12: '该用户名已存在',
    username: '用户名',
    registerSuccess: '注册成功，即将自动登录...',
    passwordSetSuccess: '密码设置成功',
    validate13: '请阅读并同意《服务条款》和《隐私条款》',
    message6: '同意并遵守',
    message7: '《服务协议》',
    message8: '和',
    message9: '《隐私政策》'
  },
  app: {
    message404: '抱歉，您访问的页面不存在。',
    message505: '抱歉，您无权访问此页面。',
    goBack: '返回首页',
    beta: '公测版',
    notice: '（备注：公测期间可能存在数据丢失风险）',
    tip: '提示'
  },
  community: {
    title1: '数字时代的众智共享平台',
    title2: '建模仿真，从未如此简便',
    searchPlaceholder: '大量优质模型供您选择',
    dialogTitle: '您收到一个仓库邀请',
    dialogMessage: '邀请您加入仓库成为',
    tab1: '首页推荐',
    tab2: '最新模型',
    repeatedApplicationMessage: '审核中，请勿重复申请',
    applicationSuccess: '成功发送请求',
    involved: '参与者'
  },
  course: {
    title: '教程中心',
    title1: '快速上手',
    message1: '6个核心功能让你3分钟快速上手',
    title2: '使用手册',
    message2: '全方位无死角讲解所有功能',
    title3: '多人协作',
    message3: '精心为你准备的高手指南',
    title4: '端云一体',
    message4: 'MoHub在线版与Sysplorer桌面版交互',
    title5: '常见问题',
    message5: '高频问题和隐藏小技巧都在这里',
    title6: '实例教程',
    message6: '常见交互效果一网打尽',
    title7: '视频教程',
    message7: '快速掌握MoHub'
  },
  doc: {
    serviceAgreement: '服务协议',
    privacyPolicy: '隐私政策'
  },
  notice: {
    messageReminder: '消息提醒',
    invitationMessage: '邀请消息',
    readAll: '全部标记为已读',
    mention: '提到我的'
  },
  personal: {
    personalHomepage: '个人主页',
    changeTheAvatar: '更换头像',
    accountSettings: '账号设置',
    uploadAvatar: '上传头像',
    selectPicture: '选择图片',
    pleaseSelectPicture: '请选择上传图片',
    onlyOne: '只能上传一个文件',
    validate1: '上传图片只能是 jpg/jpeg/png格式',
    validate2: '上传图片大小不能超过 200KB',
    activity: '动态',
    repository: '仓库',
    setting: {
      accountSettings: '账号设置',
      generalInformation: '基本信息',
      generalSettings: '基本设置',
      accountInformation: '账号信息',
      bindingInformation: '绑定信息',
      securitySetting: '安全设置',
      loginHistory: '登录历史',
      bindAccountInformation: '绑定账号信息',
      bindingTime: '绑定时间',
      unbind: '解除绑定',
      noBindingInformation: '暂无绑定信息',
      thirdPartyAccountBinding: '第三方账号绑定',
      unbindMessage: '此操作将解除微信账号绑定, 是否继续?',
      phone: '手机号',
      changePhone: '更换手机号',
      noPhone: '暂无手机号',
      bindPhone: '绑定手机号',
      email: '邮箱',
      changeEmail: '更换邮箱',
      noEmail: '暂无邮箱',
      bindEmail: '绑定邮箱',
      changePassword: '修改密码',
      logOff: '注销',
      logOffPromptMessage:
        '注销账号后您将无法再使用该账号登录MoHub，使用的MoHub相关产品与服务下的所有内容、信息、数据、记录将会被全部删除，且此操作不可逆, 是否继续？如果确认，请输入',
      pleaseEnter: '请输入 ',
      logOffSucceeded: '注销成功'
    }
  },
  project: {
    message1: '这是一个私有的仓库',
    message2: '这是一个公开的仓库',
    file: '文件',
    relies: '依赖',
    version: '版本',
    manage: '管理',
    onlineModeling: '在线建模',
    openSyslabOnline: '打开Syslab Online',
    openInClient: '客户端打开',
    downloadRepository: '下载',
    exitRepository: '退出仓库',
    noAuthority: '您没有权限查看此仓库，或仓库已经删除',
    noAuthorityToDownload: '暂无下载权限',
    exitRepositoryMessage: '此操作将退出该仓库, 是否继续?',
    clientVersion: '支持MWORKS.Sysplorer 5.1.1.5727及以上版本',
    exetip: '您还未安装{name}客户端软件，是否立即前往下载？',
    checkHelp: '查看文档',
    startDownload: '即将开始下载，请耐心等待...',
    download: '正在下载...',
    copy: {
      source: '来源于',
      empty: '你还没有复制过该仓库',
      title: '你复制的'
    }
  },
  workbench: {
    viewSimulationResult: '查看仿真结果',
    pleaseSelectSimulationResult: '请选择仿真结果',
    nav: {
      recent: '最近查看',
      create: '我的仓库',
      star: '我的点赞',
      collect: '我的收藏',
      copy: '我的复制',
      simulate: '我的仿真'
    },
    simulate: {
      modelName: '模型名称',
      repoName: '仓库名称',
      startTime: '开始时间',
      allTime: '总耗时',
      status: '状态',
      action: '操作',
      check: '查看',
      delete: '删除',
      note: '描述',
      empty: '暂无描述',
      state: {
        submitted: '仿真请求已提交给后台',
        ready_container: '仿真实例创建前准备',
        submitted_container: '仿真实例创建请求已发起',
        created_container: '仿真实例已正确运行',
        loading_file: '加载模型中',
        checking: '检查模型中',
        check_succeed: '检查成功',
        translating: '翻译模型中',
        translate_succeed: '翻译成功',
        simulating: '正在仿真',
        succeed: '仿真成功',
        failed: '仿真失败',
        var_num_exceed: '变量数超出限制',
        pause: '暂停仿真',
        continue: '继续仿真',
        stop: '仿真结束'
      },
      deleteInfo: '该仿真结果删除后将无法找回，确定删除吗?',
      stop: '结束',
      all: '全部',
      success: '成功',
      failed: '失败',
      simulating: '仿真中',
      placeholder: '请输入模型名称'
    },
    import: {
      default: '导入仓库',
      title: '从GitHub/GitLab导入仓库',
      currentPage: '导入当前页所有仓库',
      tab: {
        url: '从 URL 导入',
        github: '导入 GitHub 仓库',
        gitlab: '导入 GitLab 仓库'
      },
      url: {
        gitUrl: 'Git 仓库 URL',
        repoName: '仓库名称',
        repoUrl: '仓库路径',
        repoDescription: '仓库描述',
        repoType: '仓库类型',
        placeholder: '请输入',
        placeholderDes: '用简短的语言描述一下吧',
        public: '公开（所有人可见）',
        private: '私有（仅仓库成员可见）',
        submit: '导入',
        validateUrl: 'Git 仓库 URL 不合法'
      },
      github: {
        repo: 'GitHub仓库',
        target: '导入至',
        size: '仓库容量',
        status: '状态',
        action: '操作',
        newRepoName: '新仓库名称',
        errTip: '仓库名仅含数字、英文和下划线，且不以数字开头',
        tip: '空仓库不支持导入',
        check: '查看仓库',
        import: '导入',
        create: '未开始',
        processing: '正在导入...',
        fail: '导入失败',
        success: '导入成功'
      }
    }
  },
  workspace: {
    title: '工作空间',
    simulateTitle: '仿真结果',
    loading: '正在加载模型，请耐心等待...',
    empty: '暂无数据',
    modelCreatedSuccessfully: '模型创建成功',
    deleteModelConfirm: '删除模型后不可恢复，确认删除吗？',
    plotAttribute: '曲线窗口属性',
    selectColor: '选择颜色',
    tip: {
      title: '提示',
      message:
        '同一用户只能开启一个工作空间，出现该提示可能的原因：<br> 1、当前已经开启一个工作空间 ;<br> 2、上次异常关闭工作空间 ;',
      confirmText: '关闭其他',
      cancelText: '关闭当前',
      textViewError:
        '文本视图的内容存在语法错误。如果切换到其他视图，文本视图中修改的数据会丢失，是否继续？',
      textViewError1: '文本视图的内容存在语法错误。此时无法进行相关的操作！',
      textViewError2: '文本视图存在错误',
      cannotPackage: '不能插入package目录',
      insertTypeMessage:
        '不能插入{type}类型的组件,插入的组件类型必须是class,model,connector,block或record',
      insertTypeMessage1: '不能插入类型为父模型的组件',
      insertTypeMessage2:
        '拖入了一个抽象类{fullName}的组件，在使用之前必须通过重声明(redeclare)来改变组件的类型。注意重声明只适用于可替换的(replaceable)组件，因此应确保组件是可替换的。',
      createReuseClass: '创建重用类',
      insertTypeMessage3:
        '不能插入{type}类型的组件，插入到图标视图中的组件类型必须是连接器'
    },
    loginTip: {
      title: '提示',
      message: '确认退出登录吗？'
    },
    plot: {
      addt: '新建y(t)',
      addx: '新建y(x)',
      window: '曲线窗口',
      animate: '添加动画',
      turnOnAreaZoom: '开启区域缩放',
      turnOffAreaZoom: '关闭区域缩放',
      undoZoom: '撤销缩放',
      zoomToBest: '缩放至最佳',
      zoomXAxisToBest: '横轴缩放至最佳',
      zoomYAxisToBest: '纵轴缩放至最佳',
      setAxisRange: '设置坐标轴范围',
      clearCurrentChildPlot: '清空当前子窗口',
      removeCurrentRow: '移除当前行',
      removeCurrentColumn: '移除当前列',
      xAxisUnit: 'X轴单位',
      xAxisRange: '横坐标轴范围',
      min: '最小',
      max: '最大',
      leftYAxisRange: '左纵坐标轴范围',
      rightYAxisRange: '左纵坐标轴范围',
      cannotBeEmpty: '不能为空',
      notLessThan: '不能小于',
      cannotBeGreaterThan: '不能大于',
      minNotGreaterMax: '最小值不能大于最大值',
      AxisRange: '坐标轴范围',
      copySuccess: '复制成功',
      cannotCopy: '无法复制',
      cannotCopyMessage: '仅支持安全域下复制图片',
      chartOperationMessage: '请至少选中两条曲线',
      chartOperationMessage1: '请只选中一条曲线',
      programException: '程序异常，请刷新页面',
      plotAttribute: {
        curve: '曲线',
        title: '标题',
        titlePlaceholder: '请输入标题',
        legend: '图例',
        legendLayout: '图例布局',
        hide: '隐藏',
        implant: '嵌入',
        float: '浮动',
        floatPosition: '浮动位置',
        topLeft: '左上',
        top: '正上',
        topRight: '右上',
        left: '左边',
        right: '右边',
        bottomLeft: '左下',
        bottom: '正下',
        bottomRight: '右下',
        defaultLayout: '设为默认布局',
        xAxisTitle: '横坐标轴标题',
        null: '无',
        default: '默认',
        custom: '自定义',
        leftYAxisTitle: '左纵坐标轴标题',
        rightYAxisTitle: '右纵坐标轴标题',
        name: '名字',
        unit: '单位',
        description: '描述',
        reset: '重置',
        appearance: '外观',
        Axis: '坐标轴',
        yAxis: '纵坐标轴',
        leftYAxis: '左纵坐标轴',
        rightYAxis: '右纵坐标轴',
        data: '数据',
        point: '点数'
      },
      scene: '添加场景',
      model: '添加模型'
    },
    panel: {
      modelBrowser: '模型浏览器',
      modelParameters: '组件参数',
      modelComponent: '组件浏览器',
      modelDocument: '文档浏览器',
      modelAttribute: '模型属性',
      simulationBrowser: '仿真浏览器',
      outputView: '输出',
      toolbar: '切换工具栏',
      sidebar: '切换主侧栏',
      panelbar: '切换面板',
      auxiliarybar: '切换辅助侧栏',
      zen: '切换禅模式',
      general: '常规',
      parameters: '参数'
    },
    code: {
      100: '正在启动服务...',
      101: '服务启动失败，请刷新重试',
      102: '服务启动成功，正在进行连接...',
      103: '服务连接失败，请刷新重试或检查网络状态',
      104: '服务连接成功，正在加载模型...',
      105: '模型加载失败，请刷新重试',
      106: '模型加载成功，即将进入建模空间...'
    },
    modelBrowser: {
      modelLibrary: '模型库',
      modelUser: '用户模型',
      searchPlaceholder: '搜索模型',
      loaded: '已经加载',
      whetherChange: '，是否切换到',
      changeing: '正在切换模型库，请耐心等待...'
    },
    simulationBrowser: {
      searchPlaceholder: '搜索结果变量',
      name: '名称',
      value: '值',
      unit: '单位',
      description: '描述',
      menus: {
        expandTwo: '展开两级',
        fold: '全部折叠',
        remove: '删除结果',
        resimulate: '重新仿真',
        copyVariableName: '复制变量全名'
      }
    },
    titleBar: {
      home: '主页',
      edit: '编辑',
      simulate: '仿真',
      plot: '图表',
      view: {
        title: '窗口',
        toolbar: '显示工具栏',
        panelbar: '显示输出栏',
        statusbar: '显示状态栏',
        sidebar: '显示左侧栏',
        auxiliarybar: '显示右侧栏',
        zen: '极简模式',
        quit: '退出极简模式'
      }
    },
    toolBar: {
      file: '文件',
      library: '模型库',
      model: '建模',
      simulate: '仿真',
      plot: '曲线',
      layout: '视图布局',
      view: '视图',
      clipboard: '剪贴板',
      animate: '动画控制',
      plotTool: '曲线工具',
      plotOperation: '曲线运算',
      timePlaceholder: '请输入时间',
      selectPlaceholder: '请选择',
      draw: '绘图',
      action: {
        new: '快速新建',
        save: '保存',
        library: '模型库',
        dependency: '依赖管理',
        graph: '模型',
        plot: '曲线',
        split: '拆分',
        icon: '图标',
        diagram: '图形',
        code: '文本',
        document: '文档',
        attribute: '属性',
        grid: '网格',
        fitWindow: '适应窗口',
        fitCanvas: '适应画布',
        painter: '格式刷',
        line: '线条',
        rectangle: '矩形',
        ellipse: '椭圆',
        polygon: '多边形',
        text: '文字',
        picture: '图片',
        star: '五角星',
        outline: '轮廓',
        fill: '填充',
        arrange: '排列',
        align: '对齐',
        forward: '上移',
        backward: '下移',
        cut: '剪切',
        copy: '复制',
        paste: '粘贴',
        delete: '删除',
        selectAll: '全选',
        undo: '撤销',
        redo: '重做',
        check: '检查',
        translate: '翻译',
        simulate: '仿真',
        pause: '暂停',
        stop: '停止',
        setting: '仿真设置',
        stopTime: '终止时间',
        algorithm: '积分算法',
        plot_t: '新建y(t)曲线',
        plot_x: '新建y(x)曲线',
        add_child_plot: '添加子窗口',
        clear_child_plot: '清空子窗口',
        remove_child_plot: '移除子窗口',
        plot_zoom: '缩放至最佳',
        cursor: '游标',
        plot_grid: '显示网格',
        export_image: '导出图片',
        copy_image: '复制为图片',
        sum: '相加',
        subtract: '相减',
        multiply: '相乘',
        integration: '积分',
        differential: '微分',
        animate: '新建动画',
        animate_play: '播放',
        animate_pause: '暂停',
        animate_reset: '重置',
        animate_speed: '速度',
        animate_time: '时间',
        copyFullName: '复制全名',
        open: '打开',
        rename: '重命名',
        create: '在{label}中新建模型',
        deleteSelectedVariable: '删除所选变量',
        displayUnits: '显示单位',
        yAxis: '右纵坐标轴',
        color: '颜色',
        otherColors: '其他颜色',
        lineType: '线型',
        symbol: '数据点',
        lineWidth: '线宽'
      }
    },
    statusBar: {
      ready: '就绪',
      simulate: '翻译模型...',
      check: '检查模型...',
      translate: '翻译模型...',
      pause: '暂停'
    },
    popmenu: {
      cut: '剪切',
      copy: '复制',
      paste: '粘贴',
      delete: '删除',
      selectAll: '全选',
      undo: '撤销',
      redo: '重做',
      forward_top: '置于顶层',
      forward_one: '上移一层',
      backward_bottom: '置于底层',
      backward_one: '下移一层',
      rotate: '旋转90度',
      rotate_reverse: '旋转-90度',
      flip_h: '水平翻转',
      flip_v: '垂直翻转',
      rename: '重命名'
    },
    simulate: {
      simulate: '开始仿真',
      check: '开始检查',
      translate: '开始翻译',
      info: '用户{control}{action}任务',
      limit: '仿真步数超出最大限制',
      wait: '正在启动仿真服务',
      loading: '仿真服务启动成功，正在加载模型',
      stop: '用户终止了任务',
      find: '找不到该任务'
    },
    modelAttribute: {
      save: '属性修改成功'
    }
  },
  homepage: {
    message1: '工业知识模型互联平台',
    message2:
      '支持工业知识、经验、数据的模型化、软件化、平台化；实现知识的众创、分享与协作；推动知识模型的互联互通',
    message3: '建模仿真云平台',
    message4: '精选模型',
    message5: '知识互联，从这里开始',
    message6: '知识共享与互联',
    message7: '使用第三方库进行建模仿真',
    message8: '支持多人协同建模',
    message9: '在线体验同元内置模型库',
    message10: '支持加密模型库',
    message11: '轻量Modelica WebIDE',
    message12: '支持拖拽式建模、文本建模',
    message13: '快速进行系统建模和仿真，随时验证想法',
    message14: '支持分布式仿真',
    message15: '支持弹性计算',
    message16: '历经20余年沉淀的世界级求解内核',
    message17: '在线后处理',
    message18: '简洁快速的后处理',
    message19: '便捷的曲线查看和曲线运算功能',
    message20: '支持三维动画，包括stl、dxf、obj等格式',
    message21: '端云一体',
    message22: '打破云端平台与客户端软件的界限',
    message23: '支持云端与客户端模型库信息数据同步',
    message24: '多环境的建模和知识共享',
    message25: '下载Sysplorer桌面版',
    message26: '加入 MoHub',
    message27: '与上万个工程师一起成长',
    message28: '快速注册',
    goToWorkbench: '前往工作台',
    moreModel: '更多模型',
    modelingAndSimulation: '建模仿真',
    textModeling: '文本建模',
    cloudSimulation: '云端仿真',
    curveOperation: '曲线运算',
    curveView: '曲线运算',
    threeDimensionalAnimation: '三维动画'
  },
  components: {
    password: {
      phone: '手机号/邮箱',
      verification: '验证码',
      getVerification: '获取验证码',
      resend: '{time}s后重新发送',
      newPassword: '新密码',
      validate1: '请输入手机号/邮箱',
      validate2: '请输入正确的手机号/邮箱',
      validate3: '该账号不存在',
      validate4: '请输入密码',
      validate5: '密码不支持中文',
      validate6: '密码长度少于六位数',
      validate7: '请输入验证码',
      validate8: '密码设置成功',
      validate9: '新密码不可与旧密码相同'
    },
    login: {
      name: '用户名/手机号/邮箱',
      forget: '忘记密码？',
      login: '免密登录',
      login1: '登录',
      bind: '登录并绑定',
      account: '账号密码登录',
      validate: '请输入用户名/手机号/邮箱',
      validate1: '请输入用户名',
      username: '用户名',
      register: '注册',
      register1: '注册并绑定'
    },
    comments: {
      expand: '展开隐藏的评论',
      reply: '回复',
      edit: '编辑',
      delete: '删除',
      report: '举报',
      modify: '修改',
      notNull: '评论内容不能为空',
      count: '仓库评论',
      noMore: '无更多评论',
      noContent: '暂无内容',
      pleaseLogin: '想要发表或查看评论？',
      login: '立即登录',
      comment: '评论',
      deleteComment: '确定删除这条评论吗？'
    },
    dependencyTree: {
      input: '请输入模型名称',
      quick: '快速',
      fold: '折叠',
      unfold: '展开',
      delete: '该仓库已删除',
      noAuth: '权限：暂无查看权限',
      name: '名称',
      edition: '版本',
      creator: '创建者',
      deleted: '已删除',
      checkDetail: '查看详情',
      changeVersion: '切换版本',
      remove: '删除依赖',
      empty: '该仓库暂无版本数据',
      title: '切换依赖版本',
      select: '请选择版本',
      version: '版本'
    },
    image: {
      select: '上传图片',
      reselect: '重新上传',
      confirm: '确定',
      noImage: '暂无推荐模型图片',
      please: '请选择上传文件',
      only: '只能上传一个文件',
      format: '上传图片只能是 jpg/jpeg/gif/png格式',
      size: '上传图片大小不能超过 150KB'
    },
    table: {
      more: '更多...',
      row: '行',
      col: '列',
      curve: '曲线布局'
    },
    chart: {
      topLeft: '置于左上',
      top: '置于正上',
      topRight: '置于右上',
      left: '置于左边',
      right: '置于右边',
      bottomLeft: '置于左下',
      bottom: '置于正下',
      bottomRight: '置于右下',
      hide: '隐藏'
    },
    video: {
      complete: '完备的Modelica建模仿真功能',
      support: '支持在线陈述式建模、文本建模，可以快速进行系统建模和仿真。',
      single: '完全独立自主的工业软件内核',
      worth:
        '同元软控20余年精心打磨，历经无数重大工程考验的世界级编译求解内核。独立自主的快乐，你值得拥有。',
      third: '支持第三方库',
      protect:
        '可以在线使用第三方库进行建模仿真；支持加密模型库，我们很认真的保护您的知识产权。'
    },
    report: {
      reason: '举报原因',
      please: '请详细说明举报原因',
      validate: '长度在 1 到 100 个字符',
      success: '举报成功!'
    },
    modal: {
      rename: '组件重命名',
      input: '请输入名称',
      validate: '名称必须以字母或下划线开头,并且只能包含字母、数字和下划线',
      modelName: '模型名称',
      component: '组件名称',
      new: '新建模型',
      type: '模型类别',
      select: '请选择模型类别',
      description: '描述',
      insert: '插入到',
      parent: '请选择父模型',
      save: '保存类型',
      selectType: '请选择保存类型',
      class: '是否抽象类',
      validate1: '请输入模型名称',
      validate2: '模型名必须以字母或下划线开头,并且只能包含字母、数字和下划线',
      validate3: '模型名称已存在',
      validate4: '模型名称不能与Modelica关键字重名',
      validate5: '模型名称不能与Modelica内置函数重名',
      validate6: '模型名称不能与Modelica内置类型重名',
      validate7: '重命名的名字没有改变',
      modelType: '请选择模型类型',
      single: '保存为单个文件',
      directory: '保存为目录结构',
      parentFile: '保存到父模型所在文件',
      library: '模型库',
      search: '搜索模型库',
      pleaseSelect: '请选择',
      version: '版本号',
      my: '我的仓库',
      loading: '正在加载...',
      uninstall: '卸载',
      install: '安装',
      reload: '仓库{name}已经加载, 是否重新加载?',
      noContent: '该仓库暂无内容',
      modelRename: '模型重命名',
      email: '邮箱',
      inputEmail: '请输入邮箱',
      verification: '验证码',
      correct: '请输入正确的邮箱',
      exists: '该邮箱已存在',
      bind: '绑定邮箱',
      replace: '更换邮箱',
      updatePassword: '修改密码',
      oldPassword: '旧密码',
      inputOld: '请输入旧密码',
      newPassword: '新密码',
      inputNew: '请输入新密码',
      validate8: '密码不少于6位',
      validate9: '新密码不可与旧密码相同',
      tip: '密码修改成功,即将退出重新登陆',
      phone: '手机号',
      inputPhone: '请输入手机号',
      validate10: '请输入正确的手机号',
      validate11: '该手机号已存在',
      bindPhone: '绑定手机号',
      change: '更换手机号',
      tip1: '手机绑定成功',
      connect: '连接',
      message1: '从组件列表中选择外部连接器的子组件进行连接。',
      message2: '连接到合适的连接器',
      message3: '连接器的类型不匹配。',
      message4: '指定的下标索引无效',
      createConnection: '创建连接'
    },
    message: {
      info: '{name} 在 {project} @了你',
      time: '{date} 于 仓库 {project}',
      mark: '标记为已读',
      invite: '通过管理员{name}的邀请加入个人仓库{project}',
      reject: '拒绝管理员{name}的邀请加入个人仓库{project}',
      join: '确认加入',
      refuse: '拒绝',
      pass: '通过{name}的邀请链接，申请加入个人仓库{project}',
      remove: '被{name}从仓库{project}移除',
      apply: '通过{name}的邀请链接，申请加入个人仓库',
      manage: '通过管理员{name}邀请加入个人仓库',
      agree: '同意'
    },
    simulate: {
      setting: '仿真设置',
      modelName: '模型名称',
      startTime: '开始时间',
      input: '请输入开始时间',
      endTime: '终止时间',
      inputEnd: '请输入终止时间',
      output: '输出区间',
      step: '步长',
      inputStep: '请输入步长',
      steps: '步数',
      inputSteps: '请输入步数',
      stepRange: '步数范围',
      algorithm: '算法',
      precision: '精度',
      inputPrecision: '请输入精度',
      initial: '初始积分步长',
      inputInitial: '请输入初始积分步长',
      integration: '积分步数',
      result: '结果存储',
      store: '存储事件时刻的变量值',
      explain: '说明',
      inputExplain: '请输入说明内容',
      invalid: '开始时间设置无效',
      less: '开始时间必须小于终止时间',
      invalid1: '终止时间设置无效',
      more: '终止时间必须大于开始时间',
      invalid2: '区间长度设置无效',
      invalid3: '区间步数设置无效',
      invalid4: '精度设置无效',
      initial1: '初始积分步长',
      integration1: '积分步数',
      invalid5: '设置无效',
      inputRange: '请输入输出区间',
      inputAlgorithm: '请输入算法',
      simulateRange: '仿真区间',
      integralAlgorithm: '积分算法',
      fixedIntegral: '分段固定积分步长',
      tip: '提示',
      save: '保存',
      test: '试验',
      successTip: '仿真成功',
      goResult: '是否立即查看仿真结果？'
    },
    page: {
      home: '首页',
      model: '应用市场',
      tutorial: '教程',
      repo: '仓库详情',
      workbench: '我的工作台',
      homepage: '我的主页',
      setting: '账号设置',
      message: '消息提醒'
    },
    diff: {
      my: '我的',
      merge: '合并结果',
      cloud: '云端',
      submit: '提交信息',
      input: '请输入提交信息',
      left: '选择左边',
      right: '选择右边',
      giveUp: '放弃'
    },
    detail: {
      recent: '近期动态',
      noDynamic: '暂无动态',
      intro: '简介',
      noIntro: '暂无简介',
      license: '查看license',
      contributor: '贡献者',
      noCon: '暂无贡献者',
      input: '请输入标签名称',
      tip: '此操作将删除该标签, 是否继续?'
    },
    modelica: {
      newest: '最新',
      input: '请输入模型名称',
      model: '模型视图更新中',
      noView: '暂无模型视图',
      simulating: '正在仿真中',
      simulation: '仿真',
      log: '仿真日志',
      text: '文本',
      icon: '图标',
      component: '组件',
      explain: '说明',
      var: '变量',
      output: '输出',
      close: '关闭',
      setting: '仿真设置',
      tip: '有正在进行的仿真，请稍后',
      noSelect: '没有选中模型',
      validate: '当前组件不能继续查看！',
      name: '名称',
      default: '默认值',
      unit: '单位',
      description: '描述',
      tip1: '当前版本模型视图正在生成中，请耐心等待...',
      tip2: '当前版本模型视图生成失败'
    },
    role: {
      participant: '参与者',
      observer: '观察者',
      developer: '开发者',
      controller: '管理者',
      owner: '拥有者'
    },
    setting: {
      link: '链接邀请',
      share: '通过分享链接地址邀请成员',
      permission: '应用权限：',
      url: '链接地址：',
      reset: '重置链接',
      copy: '复制链接',
      add: '直接添加',
      addUser: '直接添加用户',
      user: '用户：',
      input: '请输入用户名/手机号/邮箱',
      add1: '添加',
      select: '请选择用户',
      success: '当前应用邀请链接复制成功',
      send: '已将请求发送给用户！',
      owner: '应用拥有者',
      me: '我自己',
      create: '于{time}创建',
      invite: '于{time}被邀请',
      exit: '退出应用',
      remove: '移出应用',
      sure: '此操作将移除应用成员{name}，是否继续?',
      member: '添加应用成员',
      noMember: '「当前应用暂无{label}权限成员」',
      invited: '邀请新成员',
      comment: '应用评论',
      allow: '允许用户对应用进行评论',
      copy1: '复制',
      allow1: '允许应用被复制',
      allow2: '允许用户下载应用内容',
      base: '基本信息',
      cover: '应用封面',
      change: '更换封面',
      repoName: '应用名称',
      repoType: '应用类型',
      open: '开放类型',
      private: '私有的',
      public: '公开的',
      tag: '应用标签',
      selectTag: '请选择应用标签',
      setCover: '设置封面图',
      validate: '请输入应用名称',
      validate1: '应用名称仅含数字、英文和下划线，且不以数字开头',
      validate2: '请选择应用类型',
      validate3: '请选择应用开放类型',
      sure1: '此操作将删除该标签, 是否继续?',
      unpass: '校验未通过',
      tip: '此操作无法恢复！请谨慎操作！',
      tip1:
        '清空应用，此操作会清空应用代码以及关闭pages服务，不会清空应用的issues，Wiki及其他设置。',
      clear: '清空应用',
      sureClear: '此操作将清空该应用, 是否继续?',
      cleared: '应用已经清空',
      tip2:
        '删除应用将会连同其相关的所有数据（包括版本，版本视图、标签等在内）一起删除，同时取消应用的协作者关联。应用列表中将不再显示已删除的应用。',
      delete: '删除应用',
      tip3: '此操作将永久删除该应用, 是否继续？如果确认，请输入 ',
      pleaseInput: '请输入',
      deleted: '应用已经删除'
    },
    upload: {
      directory: '文件目录',
      submit: '提交信息',
      select: '选择文件',
      click: '点击上传',
      validate: '上传文件大小不能超过 50MB!',
      validate1: '只能上传一个文件',
      validate2: '请选择一个文件',
      success: '上传文件成功',
      validate3: '仓库中存在同名文件',
      name: '文件名',
      validate4: '单个文件大小不能超过 10MB!',
      validate5: '请至少选择一个文件',
      validate6: '上传文件夹成功',
      validate7: '仓库中存在同名文件或文件夹',
      validate8: '请至少上传一个文件夹',
      validate9: '只能上传10个文件',
      path: '文件路径',
      input: '请输入文件名',
      back: '返回上一级',
      file: '文件',
      new: '新建文件',
      upload: '上传文件',
      newFolder: '新建文件夹',
      uploadFolder: '上传文件夹',
      submitTime: '提交时间',
      check: '非纯文本文件，请{download}后查看',
      download: '下载',
      inputSubmit: '请输入提交信息',
      folder: '文件夹名称',
      validate10: '请输入文件夹名称',
      validate11: '文件夹名称不得超过32个字符',
      validate12: '不能全为空格',
      validate13: '只允许输入英文、数字和下划线',
      validate14: '请输入标签名称',
      validate15: '文件删除',
      validate16: '请输入保存信息',
      copySuccess: '当前仓库地址复制成功',
      tip: '此操作将删除该标签, 是否继续?',
      createSuccess: '创建文件夹成功!',
      inputName: '请先输入文件名',
      deleteTip: '提示：文件删除后，将无法恢复'
    },
    manage: {
      base: '基本设置',
      repo: '应用设置',
      info: '基本信息',
      permission: '权限设置',
      delete: '删除应用',
      member: '应用成员管理',
      all: '所有',
      manager: '管理者',
      apply: '申请列表',
      contributors: '所有贡献者',
      create: '创建版本',
      noVersion: '暂无版本描述信息',
      created: '创建于',
      download: '下载',
      view: '点击重新生成模型视图',
      version: '版本号',
      recommend: '推荐格式：1.0.0',
      description: '版本描述',
      build: '生成版本视图',
      input: '请输入版本号',
      sure: '确定重新生成该版本视图?',
      building: '正在生成版本视图',
      continue: '此操作将删除该版本, 是否继续?',
      version1: '版本'
    },
    widget: {
      favorite: '已收藏',
      collect: '收藏',
      copied: '已复制',
      copy: '复制',
      repo: '复制仓库',
      repoName: '仓库名',
      input: '请输入仓库名',
      exist: '该仓库名已存在',
      Liked: '已点赞',
      Like: '点赞'
    }
  },
  header: {
    message: '我的消息',
    workbench: '工作台',
    profile: '个人主页',
    setting: '账号设置',
    quick: '快速上手',
    help: '帮助文档',
    signOut: '退出',
    login: '登录',
    register: '注册',
    homepage: '首页',
    model: '应用市场',
    course: '教程',
    sure: '确认退出登录吗？'
  },
  footer: {
    service: '服务协议',
    privacy: '隐私政策',
    contact: '联系我们',
    client: '客户端下载',
    right: '保留所有权利',
    message:
      '您可以致电0512-62720716（总机）/18115503283（市场）（工作日9:00-17:00）或留下您的联系方式，我们的工作人员将于三个工作日内联系您。',
    name: '姓名',
    corporate: '公司名称',
    mobile: '手机',
    email: '邮箱',
    consultation: '咨询内容',
    validate: '请输入正确的手机号',
    validate1: '请输入姓名',
    validate2: '请输入手机号',
    validate3: '请输入邮箱地址',
    validate4: '请输入正确的邮箱地址',
    send: '发送成功',
    company: '苏州同元软控信息技术有限公司'
  },

  repository: {
    public: '公开',
    private: '私有',
    star: '点赞',
    collect: '收藏',
    copy: '复制',
    createNewRepo: '新建仓库',
    personal: '个人的',
    mine: '我创建的',
    involved: '我参与的',
    name: '仓库名称',
    modelTypes: '仓库类型',
    openTypes: '开放类型',
    tags: '仓库标签',
    pleaseSelectTags: '请选择仓库标签',
    initModel: '初始化模型',
    validate1: '请输入仓库名称',
    validate2: '仓库名称长度不能超过50',
    validate3: '仓库名称已存在',
    validate4: '仓库名称仅含数字、英文和下划线，且不以数字开头',
    validate5: '请选择仓库开放类型',
    validate6: '请选择仓库类型',
    validate7: '请输入一段简短的描述吧'
  },
  requestMessage: {
    operationSucceeded: '操作成功',
    uploadSucceeded: '上传成功',
    userLoggedOff: '该用户已注销',
    createSucceeded: '创建成功',
    updateSucceeded: '更新成功'
  },
  activity: {
    createdMessage: '创建了仓库',
    deletedMessage: '删除了仓库',
    clearedMessage: '清空了仓库',
    startedMessage: '点赞了仓库',
    cancelStartedMessage: '取消点赞仓库',
    collectedMessage: '收藏了仓库',
    cancelCollectedMessage: '取消收藏仓库',
    copiedMessage: '复制了仓库',
    pushToMessage: '推送到了仓库',
    tagCreatedMessage: '{name}在仓库{repo}新增了一个版本',
    deleteTagMessage: '{name}在仓库{repo}删除了一个版本',
    commentedMessage: '{name}在仓库{repo}发表了评论',
    deleteCommentMessage: '{name}在仓库{repo}删除了评论',
    updateCommentMessage: '{name}在仓库{repo}修改了评论',
    addUserToRepo: '{inviter}邀请{invitee}到仓库{repo}',
    updateUserAuthority: '编辑了{name}在仓库{repo}的权限',
    updatedAuthority: '{name}编辑了你在仓库{repo}的权限',
    deleteMember: '{deleter}将{deleteBy}移出了仓库'
  },
  utils: {
    year: '年前',
    months: '月前',
    day: '天前',
    hour: '小时前',
    minutes: '分钟前',
    seconds: '秒前',
    day1: ' 天',
    hour1: ' 小时',
    minutes1: ' 分',
    seconds1: ' 秒',
    tip: '请选择支持动画的仿真结果',
    tip1: '请选择动画窗口',
    translate: 'package不能翻译'
  },
  store: {
    var: '该变量已存在，不可重复添加',
    diff: '暂不支持不同仿真结果的对比',
    REPO_CREATE: '创建仓库',
    REPO_DELETE: '删除仓库',
    REPO_CLEAR: '清空仓库',
    REPO_STAR: '点赞仓库',
    REPO_COLLECTION: '收藏仓库',
    REPO_FORK: 'Fork仓库',
    REPO_PUSH: '提交内容',
    TAG_CREATE: '增加tag',
    TAG_DELETE: '删除tag',
    COMMENT_CREATE: '发表评论',
    COMMENT_DELETE: '删除评论',
    COMMENT_UPDATE: '修改评论',
    GROUP_CREATE: '创建群组',
    GROUP_DELETE: '删除群组',
    GROUP_UPDATE: '修改群组信息',
    MEMBER_ADD: '添加成员',
    MEMBER_BE_ADDED: '成员被添加',
    MEMBER_UPDATE: '编辑成员权限',
    MEMBER_BE_UPDATED: '成员权限被编辑',
    MEMBER_DELETE: '删除成员',
    MEMBER_BE_DELETED: '成员被删除',
    solid: '实线',
    dashed: '虚线',
    dotted: '点线',
    none: '无',
    circle: '圆形',
    rect: '正方形',
    triangle: '三角形',
    diamond: '菱形',
    single: '单倍线宽',
    double: '双倍线宽',
    quadruple: '四倍线宽',
    unfill: '无填充',
    solid1: '实心',
    level: '水平线',
    degree45: '-45度斜线',
    degree_45: '45度斜线',
    line: '45度斜交叉线',
    horizontal: '水平圆柱面',
    vertical: '垂直圆柱面',
    ball: '球形',
    dashDotL: '点划线',
    dashDotDotLine: '双点划线',
    fangsong: '仿宋',
    song: '宋体',
    black: '黑体',
    regular: '楷体',
    pass: '申请通过',
    applying: '申请中',
    unpass: '申请不通过',
    invited: '邀请成功',
    inviting: '邀请中',
    refuse: '邀请被拒绝'
  },
  WebSocket: {
    message: '缺少必须参数或必须参数错误',
    message1: '创建block失败',
    message2: '非法下标',
    message3: '组件保存到底层失败',
    message4: '获取组件变量失败',
    message5: '创建连接失败',
    message6: '无效的模型名',
    message7: '无效的组件名',
    message8: '删除组件失败',
    message9: '删除连接失败',
    message10: '单位转换失败',
    message11: '设置单位失败',
    message12: '修改参数值失败',
    message13: '设置参数失败',
    message14: '设置连线到底层失败',
    message15: '无法转换， 新类型不是class、model、block、record类型',
    message16: '修改组件类型失败',
    message17: '无法创建block，请检查模型名是否正确',
    message18: '移动组件失败',
    message19: '组件重命名失败',
    message20: '存在同名类型，模型重命名失败',
    message21: '父模型不存在，模型重命名失败',
    message22: '储存路径不存在，模型重命名失败',
    message23: '储存路径没有写文件权限，模型重命名失败',
    message24: '储存路径已经存在了同名文件，模型重命名失败',
    message25: '重命名失败',
    message26: '新模型名不合法',
    message27: '不能用Modelica关键字作为模型名',
    message28: '不能用Modelica内置类型作为模型名',
    message29: '不能用Modelica内置函数作为模型名',
    message30: '无法创建block，请检查模型名是否正确',
    message31: '旋转组件失败',
    message32: '保存模型仿真设置失败',
    message33: '文本语法存在错误，设置模型文本失败',
    message34: '无法创建block，请检查模型名是否正确',
    message35: '编辑组件大小失败',
    message36: '模型名不合法',
    message37: '获取package名失败',
    message38: '模型不允许被创建',
    message39: '保存模型到文件失败',
    message40: '模型库不存在',
    message41: '当前版本的模型库已经被加载',
    message42: '加载模型库失败，请重试',
    message43: '模型库路径没找到',
    message44: '加载失败，请检查是否存在同名模型',
    message45: '模型名不合法',
    message46: '模型扩展名不合法',
    message47: '新建模型失败',
    message48: '存在同名模型，新建模型失败',
    message49: '存在同名组件，新建模型失败',
    message50: '已经加载了大小写不一样的同名文件，加载模型失败',
    message51: '父模型不存在，加载模型失败',
    message52: '存储路径不存在，新建模型失败',
    message53: '存储路径是在结构化模型中，新建模型失败',
    message54: '存储路径没有写权限，新建模型失败',
    message55: '存储路径已经存在了同名文件，新建模型失败',
    message56: '新模型名不合法',
    message57: '不能用Modelica关键字作为模型名',
    message58: '不能用Modelica内置类型作为模型名',
    message59: '不能用Modelica内置函数作为模型名',
    message60: '保存模型失败',
    message61: '拷贝中间文件失败',
    message62: '当前模型非包类型',
    message63: '调用打包工具失败',
    message64: '模型发布失败',
    message65: '读依赖失败',
    message66: '保存模型失败',
    message67: '图形重绘失败',
    message68: '保存图标失败',
    message69: '保存组件失败',
    message70: '创建连接失败',
    message71: '无法保存模型库',
    message72: '保存模型失败',
    message73: '卸载模型失败',
    message74: '写依赖失败',
    message75: '获取线失败',
    message76: '获取多边形失败',
    message77: '获取矩形失败',
    message78: '获取椭圆形状失败',
    message79: '获取文本失败',
    message80: '获取资源文件失败',
    message81: '将图形保存到图形层失败',
    message82: '将图形保存到图标层失败',
    message83: '发送MoHub服务器请求失败',
    message84: 'MoHub服务器未识别该用户',
    message85: '找不到MoHub服务器',
    message86: '存储库用户名和密码解析失败',
    message87: '仓库ID解析失败',
    message88: '发送MoHub服务器请求失败',
    message89: 'Git仓库克隆失败',
    message90: '推送模型失败',
    message91: '检查连接工具初始化失败',
    message92: '连接已经存在',
    message93: '暂时不支持可扩展连接',
    message94: '连接信号冲突',
    message95: '暂时不支持数组类型组件连接',
    message96: '连接器类型不匹配',
    message97: '未找到匹配模型',
    message98: '获取顶层模型失败',
    message99: '该模型不存在子模型',
    message100: '组件信息获取失败',
    message101: '组件变量获取失败',
    message102: '组件信息获取失败',
    message103: '模型信息获取失败',
    message104: '检查连接工具初始化失败',
    message105: '保存模型失败',
    message106: '获取模型文档失败',
    message107: '获取模型文本失败',
    message108: '获取组件类型失败',
    message109: '服务异常'
  },
  svg: {
    text: '暂无组件视图, 点击查看',
    text1: '暂无图标信息, 点击查看',
    text2: '或',
    text3: '文本',
    text4: '图标',
    text5: '组件'
  }
};
