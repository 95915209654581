import cookie from 'vue-cookies';
import * as utils from './utils';
window.system = {};
system = {
  /**
   * 当前路由
   */
  routerBase: process.env.BASE_URL,
  /**
   * 路由模式 'hash' | 'history' | 'abstract'
   */
  routerMode: 'history',
  /**
   * 首页的默认地址
   */
  index: '/homePage',
  /**
   * 登录path
   */
  loginUrl: 'sign',
  /**
   * 首页是否需要登录
   */
  mainIsLogin: true,
  /**
   * 数据令牌
   */
  token: null,
  /**
   * 可用来分割用户的登录信息
   */
  cookiePrefix: 'mohub',
  /**
   * 视图目录
   */
  pageView: 'views',
  /**
   * 当前应用的基本配置信息
   */
  app: {},
  /**
   * 当前应用菜单信息
   */
  menus: null,
  /**
   * 当前应用的已授权的菜单url
   */
  menuUrls: [],
  /**
   * 当前应用缓存页面的名称
   */
  cacheNames: [],
  /**
   * 系统环境
   */
  env: null,
  /**
   * 定时器
   */
  timers: [],
  /**
   * 仓库详情是否需要登录
   */
  detailIsLogin: false
};
let tokenCookieKey = 'token';

function getTokenKey() {
  let key = tokenCookieKey;
  if (system.cookiePrefix) {
    key = key + '_' + system.cookiePrefix;
  }
  return key;
}

function getToken() {
  let key = getTokenKey();
  return cookie.get(key);
}
setLocalStorageProperty(tokenCookieKey);
setLocalStorageProperty('userInfo');

/**
 * 设置本地storage需要缓存的数据key
 */
export function setLocalStorageProperty(key) {
  Object.defineProperty(system, key, {
    get: function () {
      if (key == tokenCookieKey) {
        let temp = getToken();
        return temp;
      } else {
        return JSON.parse(localStorage.getItem(key));
      }
    },
    set: function (data) {
      if (data == null) {
        if (key == tokenCookieKey) {
          cookie.remove(getTokenKey(), null);
        } else {
          localStorage.removeItem(key);
        }
      } else {
        if (key == tokenCookieKey) {
          cookie.set(getTokenKey(), data, '7d', null);
        } else {
          return localStorage.setItem(key, JSON.stringify(data));
        }
      }
    }
  });
}
