// 1、引入插件
import Vue from 'vue';
import VueI18n from 'vue-i18n';
// 引入elementUI的语言表
import elZh from 'element-ui/lib/locale/lang/zh-CN';
import elEn from 'element-ui/lib/locale/lang/en';
// 引入导航的语言包
import comZh from './zh';
import comEn from './en';

// 引入验证码的语言包
import captchaZh from '@/components/captcha/lang/zh';
import captchaEn from '@/components/captcha/lang/en';
// 注册
Vue.use(VueI18n);
// 2、定义中英文语言对照表
const messages = {
  zh: {
    ...elZh,
    ...comZh,
    ...captchaZh
  },
  en: {
    ...elEn,
    ...comEn,
    ...captchaEn
  }
};

// 3、实例化插件
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh', // 当前的语言
  messages: messages
});

// 4、导出
export default i18n;
