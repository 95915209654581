import Cookies from 'js-cookie';

export default {
  state: {
    modelId: '',
    treeModelId: '',
    isDirectory: false,
    modelDirFolded: Cookies.get('modelDirFolded') ? !!+Cookies.get('modelDirFolded') : false,
    componentDirFolded: Cookies.get('componentDirFolded') ? !!+Cookies.get('componentDirFolded') : false,
    detailAsideFolded: Cookies.get('detailAsideFolded') ? !!+Cookies.get('detailAsideFolded') : false
  },
  mutations: {
    SendTreeModelId(state, treeModelIdData) {
      state.treeModelId = treeModelIdData;
    },
    IsDirectory(state, isDirectory) {
      state.isDirectory = isDirectory;
    },
    SendModelId(state, mdoelIdData) {
      state.modelId = mdoelIdData;
    },
    CHANGE_MODEL_DIR_STATUS(state) {
      state.modelDirFolded = !state.modelDirFolded;
      if (state.modelDirFolded) {
        Cookies.set('modelDirFolded', 1);
      } else {
        Cookies.set('modelDirFolded', 0);
      }
    },
    CHANGE_COMPONENT_DIR_STATUS(state) {
      state.componentDirFolded = !state.componentDirFolded;
      if (state.componentDirFolded) {
        Cookies.set('componentDirFolded', 1);
      } else {
        Cookies.set('componentDirFolded', 0);
      }
    },
    CHANGE_DETAIL_ASIDE_STATUS(state) {
      state.detailAsideFolded = !state.detailAsideFolded;
      if (state.detailAsideFolded) {
        Cookies.set('detailAsideFolded', 1);
      } else {
        Cookies.set('detailAsideFolded', 0);
      }
    }
  },
  getters: {
    treeModelId: state => state.treeModelId,
    isDirectory: state => state.isDirectory,
    modelId: state => state.modelId,
    modelDirFolded: state => state.modelDirFolded,
    componentDirFolded: state => state.componentDirFolded,
    detailAsideFolded: state => state.detailAsideFolded
  },
  actions: {
    sendTreeModelId({ commit }, treeModelIdData) {
      commit('SendTreeModelId', treeModelIdData);
    },
    isDirectory({ commit }, isDirectory) {
      commit('IsDirectory', isDirectory);
    },
    sendModelId({ commit }, mdoelIdData) {
      commit('SendModelId', mdoelIdData);
    },
    changeModelDirStatus({ commit }) {
      commit('CHANGE_MODEL_DIR_STATUS');
    },
    changeComponentDirStatus({ commit }) {
      commit('CHANGE_COMPONENT_DIR_STATUS');
    },
    changeDetailAsideStatus({ commit }) {
      commit('CHANGE_DETAIL_ASIDE_STATUS');
    }
  }
};
