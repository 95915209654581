<template>
    <div v-if="!this.$route.path.includes('html')">
        <div class="bottom">
            <div class="tac"><img src="../../assets/images/official/MWORKS.png"></div>
            <h3>科学计算与系统建模仿真平台</h3>
            <div class="button"><a href="/download">前往下载</a></div>
            <div class="footer">
                <div class="footer_logo"><img src="../../assets/images/official/tongyuannewlogo.png"></div>
                <div class="content">
                    <div class="title">
                        <div v-for="(item, index) in menus" :key="index">
                            <a :href="item.router">{{ item.name }}</a><span>|</span>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div>
                        <div class="info">
                            <div class="left">
                                <div class="body">
                                    <div class="image"><img src="../../assets/images/official/phone.png">
                                    </div>
                                    <div class="text">联系方式<br>0512-62720716(总机) /18115503283（市场）</div>
                                    <div class="clear"></div>
                                </div>
                                <div class="body">
                                    <div class="image"><img src="../../assets/images/official/fax.png">
                                    </div>
                                    <div class="text">传真<br>0512-62720717</div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                            <div class="right">
                                <div class="body">
                                    <div class="image"><img src="../../assets/images/official/email.png">
                                    </div>
                                    <div class="text">邮箱<br>support@tongyuan.cc</div>
                                    <div class="clear"></div>
                                </div>
                                <div class="body">
                                    <div class="image"><img src="../../assets/images/official/address.png">
                                    </div>
                                    <div class="text">地址<br>苏州工业园区若水路388号纳米大学科技园E栋3、17、18层</div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                            <div class="code" style="margin-left: 80px;"><img src="../../assets/images/official/tongyuancode.jpg"></div>
                            <div class="clear"></div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="sign">Suzhou Tongyuan Software&amp;Control Technology Co., Ltd.
                        <div class="sign-button" style="display: flex;align-items: center;">
                            <img src="../../assets/images/official/beian.png" style="margin-right: 3px;height: 18px;">
                            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32059002005558" rel="noreferrer"
                                target="_blank">苏公网安备32059002005558</a>
                        </div>
                        <div class="sign-button" style="margin-left: 15px;">
                            <!-- <a href="http://www.tongyuan.cc/admin/">管理登陆</a> &nbsp;&nbsp;&nbsp;&nbsp; -->
                            <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备08107463号-2</a>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
                <div style="display:none">-</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: null,
      menus: []
    };
  },
  created() {
    this.userInfo = system.userInfo;
    this.getFooter();
  },
  methods: {
    async getFooter() {
      const res = await this.$api.getHeader();
      if (res.code == 200) {
        this.menus = res.data;
      }
    },
    toUrl(url) {
      this.$router.push(url);
    }
  }
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}

.bottom {
    padding-top: 100px;
    width: 100%;
    background: $--background-color-banner;
}

.tac {
    text-align: center;
    height: 140px;
}

.tac img {
    height: 100%;
}

.bottom h3 {
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    margin-top: 34px;
    color: #FFFFFF;
}

.button a {
    display: block;
    width: 200px;
    height: 60px;
    border-radius: 30px;
    background: #467ed1;
    color: #FFFFFF;
    text-align: center;
    line-height: 60px;
    font-size: 24px;
    margin: 0 auto;
    margin-top: 60px;
}

.footer {
    border-top: 1px solid #9a9a9a;
    max-width: 1350px;
    margin: 0 auto;
    margin-top: 60px;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 54px;
}

.footer_logo img {
    float: left;
    width: 140px;
}

.content {
    float: right;
    width: 76%;

    .body {
        margin-bottom: 20px;
    }

    .title {
        line-height: 38px;
    }

    .title a {
        display: block;
        float: left;
        color: #FFFFFF;
        font-size: 16px;
    }

    .title span {
        display: block;
        float: left;
        color: #FFFFFF;
        font-size: 18px;
        margin: 0 30px;
    }
}

.info {
    display: flex;
    font-size: 16px;
    line-height: 36px;
    color: #f7f8f8;
    margin-top: 20px;

    .left {
        width: 50%;
        margin-right: 70px;
        margin-bottom: 20px;
    }

    .right {
        width: 50%;
        float: right;
    }

    .image {
        width: 20px;
        margin-right: 32px;
        float: left;
    }

    .image img {
        width: 100%;
        margin-top: 6px;
    }

    .text {
        font-size: 14px;
        line-height: 18px;
    }
}

.code {
    float: right;
    margin-top: 20px;
}

.code img {
    width: 94px;
}

.sign {
    color: white;
    font-size: 14px;
    margin-top: 70px;
    padding-bottom: 20px;
    display: flex;

    &-button {
        margin-left: 50px;

        a {
            color: white;
        }
    }
}

@media screen and (max-width: 700px) {
    .bottom {
        padding-top: 50px;
    }

    .title {
        display: none;
    }

    .footer_logo {
        width: 30%;
        margin: 0 auto;
    }

    .footer_logo img,
    .content {
        width: 100%;
        float: none;
    }

    .tac {
        height: 90px;
    }

    .bottom h3 {
        font-size: 20px;
        margin-top: 20px;
    }

    .button a {
        width: 160px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        margin: 0 auto;
        margin-top: 20px;
    }

    .image {
        height: 60px;
    }

    .info {
        display: block;

        .left,
        .right {
            float: none;
            width: 100%;
        }

        .text {
            font-size: 18px;
            line-height: 20px;
        }
    }

    .sign {
        text-align: center;
        font-size: 14px;
        margin-top: 30px;
        display: block;

        &-button {
            margin-left: 0;

            a {
                text-align: center;
            }
        }
    }

    .code {
        display: none;
    }
}
</style>
