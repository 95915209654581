import * as api from '@/service/api';
import store from '@/store';
import i18n from '../../core/lang';

function getNumExp(num, exp = 0) {
  if (num < Math.pow(10, -(exp + 2))) {
    exp += 3;
    exp = getNumExp(num, exp);
  }
  return exp;
}
/**
 * 创建chart实例
 *
 * @param data --曲线图表数据
 * @returns {*}
 */
function createChart(data, taskId) {
  const chart = {
    id: getUuid(),
    view: data.view, // 曲线图表存放位置
    xRange: [null, null], // x坐标轴范围（y轴是针对子窗口的）
    data: [
      {
        id: getUuid(),
        x: 0,
        y: 0,
        isActive: true,
        loading: false,
        yRange: [null, null], // 子窗口y坐标轴范围
        data: {
          columns: [], // 维度和指标的集合 默认第一个值为维度，剩余的值为指标
          rows: [], // 数据集合
          varDatas: []
        }
      }
    ],
    taskId // 限制一个曲线只能存放一个任务的变量
  };
  return chart;
}

function createChartChild(x, y) {
  const chart = {
    id: getUuid(),
    x: x,
    y: y,
    isActive: false,
    loading: false,
    yRange: [null, null], // 子窗口y坐标轴范围
    data: {
      columns: [], // 维度和指标的集合 默认第一个值为维度，剩余的值为指标
      rows: [], // 数据集合
      varDatas: []
    }
  };
  return chart;
}

/**
 * @param {*} charts
 */
function getActiveChart(charts, type) {
  const activeTab = store.getters['tabs/activeTab'];
  return charts.find(item => item.view.id === activeTab.id);
}

/**
 * 根据chart（父窗口）获取当前选中的子窗口
 * @param {*} chart
 */
function getActiveChildForChart(chart) {
  // return chart.data.find(item => item.isActive === true);
  return chart;
}

/**
 * 加载坐标变量,范围要维护
 * @param chart
 * @param data--变量数据
 * @returns {*}
 */
async function loadVariable(chart, data) {
  // 首先找到当前窗口下选中的chart
  let activeChild = getActiveChildForChart(chart);
  // 曲线类型-区分y(t)和y(x)
  const type = chart.view.type;
  const { operator, taskId, path, unit, seriesNames } = data;
  const x = type == 'time' ? type + '(s)' : path;
  // y(t)
  if (!activeChild.data.columns.length) {
    activeChild.data.columns.push(x);
  }
  let request, y;
  if (!operator) {
    // 非曲线运算
    y = {
      taskId,
      path,
      unit
    };
    request = {
      operator: '',
      simulationTaskId: taskId,
      variableName: path,
      factors: []
    };
  } else {
    // 曲线运算
    y = {
      path: []
    };
    request = {
      operator,
      simulationTaskId: '',
      variableName: '',
      factors: []
    };
    // 积分 微分
    if (seriesNames.length == 1) {
      const name = seriesNames[0];
      // 查找当前变量对应的算法
      const temp = activeChild.data.varDatas.find(item => {
        return item.path == name;
      });
      y.path.push(name);
      if (temp.taskId) {
        request.simulationTaskId = temp.taskId;
        request.variableName = temp.path;
      }
      if (temp.factors) {
        request.factors.push(temp.factors);
      }
    }
    if (seriesNames.length > 1) {
      seriesNames.forEach((name, index) => {
        // 查找当前变量对应的算法
        const temp = activeChild.data.varDatas.find(item => {
          return item.path == name;
        });
        y.path.push(name);
        if (temp.factors) {
          request.factors.push(temp.factors);
        } else {
          request.factors.push({
            operator: '',
            simulationTaskId: temp.taskId,
            variableName: temp.path
          });
        }
      });
    }
    y.factors = request;
    if (operator == 'SUM') {
      y.path = '(' + y.path.join('+') + ')';
    } else if (operator == 'SUBTRACT') {
      y.path = '(' + y.path.join('-') + ')';
    } else if (operator == 'MULTIPLY') {
      y.path = '(' + y.path.join('*') + ')';
    } else if (operator == 'INTEGRAL') {
      y.path = 'Int' + '<' + y.path.join('') + '>';
    } else {
      y.path = 'Diff' + '<' + y.path.join('') + '>';
    }
    data.operatorPath = y.path;
    data.path = y.path;
    data.factors = y.factors;
  }
  const _path = taskId ? path : y.path;
  const columns = activeChild.data.columns;
  if (columns[0] != _path && columns.includes(_path)) {
    notify(i18n.t('store.var'), 'warning');
    return;
  }
  activeChild.data.columns.push(_path);
  if (!activeChild.loading) activeChild.loading = true;
  activeChild.data.varDatas.push(data);
  // 获取hostlocation
  const activeChart = getActiveChart(store.state.simulate.charts);
  const res = await api.querySimulationTask({
    simulationTaskId: taskId || activeChart.taskId
  });
  if (res.code != 0) {
    return;
  }
  const url =
    res.data.hostLocation +
    `/alchemy/simulationResult/queryCurveOperationResult?userName=${system.userInfo.name}`;
  api.post(url, request).then(res => {
    if (res.code === 0) {
      const values = res.data.values;
      // 这里区分y(t)与y(x)
      let xValues = res.data.time;
      if (activeChild.xValues) {
        xValues = activeChild.xValues;
      } else {
        if (type !== 'time') {
          xValues = res.data.values;
        }
        activeChild.xValues = xValues;
      }
      // 计算x轴范围
      chart.xRange[0] = xValues[0];
      chart.xRange[1] = xValues[xValues.length - 1];

      let serieData = [];
      let min = null;
      let max = null;
      // 计算y轴单位
      let exp = 0;
      let maxValue = null;
      let tempValues = values
        .map(item => Math.abs(item))
        .filter(item => item !== 0);
      maxValue = Math.max.apply(Math, tempValues);
      // values.forEach(item => {
      //   if (item !== 0) {
      //     let currentExp = getNumExp(Math.abs(item));
      //     if (currentExp > 3 && currentExp > exp) {
      //       exp = currentExp;
      //     }
      //   }
      // });
      if (maxValue !== 0) {
        let maxExp = getNumExp(Math.abs(maxValue));
        if (maxExp >= 3 && maxExp > exp) {
          exp = maxExp;
        }
      }
      // activeChild.yUnit = exp;
      // if (exp >= 3) {
      //   values.forEach((item, index) => {
      //     if (item !== 0) {
      //       values[index] = item * Math.pow(10, exp);
      //     }
      //   });
      // }
      xValues.forEach((num, index) => {
        // 更新x轴范围
        if (chart.xRange[0] > num) {
          chart.xRange[0] = num;
        }
        if (chart.xRange[1] < num) {
          chart.xRange[1] = num;
        }
        const tempTime = Number(Number.parseFloat(num).toFixed(5));
        const tempValue = values[index];
        // const tempValue = Number(Number.parseFloat(values[index]).toFixed(3));
        serieData.push([tempTime, tempValue]);
        if (min === null || values[index] < min) {
          min = values[index];
        }
        if (max === null || values[index] > max) {
          max = values[index];
        }
      });
      activeChild.data.rows.push({
        name: _path,
        data: serieData
      });
      // 如果曲线的值都相等，则将其纵轴范围扩大1
      // if (isAllEqual(values)) {
      //   min = values[0] - 1;
      //   max = values[0] + 1;
      // }
      if (min < activeChild.yRange[0] || activeChild.yRange[0] === null) {
        activeChild.yRange[0] = Number(min.toFixed(3));
      }
      if (max > activeChild.yRange[1] || activeChild.yRange[1] === null) {
        activeChild.yRange[1] = Number(max.toFixed(3));
      }
    }
  });
}

/**
 * 创建实例id
 *
 * @param data
 * @returns {*}
 */
function getUuid() {
  var s = [];
  var hexDigits = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4';
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = '-';
  let uuid = s.join('');
  return uuid;
}
/**
 * 更新y轴范围
 * @param {*} chart
 */
function updateYRange(chart, activeChart, data) {
  let min = null;
  let max = null;
  // 曲线类型-区分y(t)和y(x)
  const type = chart.view.type;
  const x = type == 'time' ? type + '(s)' : activeChart.data.columns[0];
  if (activeChart.data.columns.length > 1) {
    activeChart.data.columns.forEach((column, colIndex) => {
      if (colIndex !== 0) {
        const result = activeChart.data.rows
          .find(item => item.name == column)
          .data.map(item => item[1])
          .filter(item => item);
        let tempMin = Math.min.apply(Math, result);
        let tempMax = Math.max.apply(Math, result);
        if (min === null || tempMin < min) {
          min = tempMin;
        }
        if (max === null || tempMax > max) {
          max = tempMax;
        }
      }
    });
    min = Number(min.toFixed(3));
    max = Number(max.toFixed(3));
  } else {
    min = null;
    max = null;
    activeChart.xValues = null;
  }
  activeChart.yRange[0] = min;
  activeChart.yRange[1] = max;
}
// 判断数组中的值是否都相等
function isAllEqual(array) {
  if (array.length > 0) {
    return !array.some(function(value, index) {
      return value !== array[0];
    });
  } else {
    return true;
  }
}

export default {
  namespaced: true,
  state: {
    charts: [],
    log: {
      action: null, // 日志类型
      taskId: null, // 日志id(任务id)
      content: '', // 日志内容
      status: null // 日志任务状态
    } // 仿真日志
  },
  mutations: {
    ADD: (state, value) => {
      state.charts.push(value);
    },
    DELETE: (state, value) => {
      // value 删除数据集合
      const ids = value.map(item => item.id);
      state.charts = state.charts.filter(item => !ids.includes(item.id));
      console.log(value);
      // 将vardata也清除
    },
    ADD_CHILD: (state, value) => {
      const currentCharts = getActiveChart(state.charts);
      if (currentCharts) {
        currentCharts.data.push(value);
      }
      // 这里进行排序
      currentCharts.data.sort((a, b) => {
        if (a.x !== b.x) {
          return a.x - b.x;
        } else {
          return a.y - b.y;
        }
      });
    },
    DELETE_CHILD: (state, index) => {
      const currentCharts = getActiveChart(state.charts);
      if (currentCharts.data[index].isActive) {
        currentCharts.data.splice(index, 1);
        currentCharts.data[0].isActive = true;
      } else {
        currentCharts.data.splice(index, 1);
      }
    },
    DELETE_ALL: (state, value) => {
      if (value) {
        const ids = value.map(item => item.id);
        state.charts = state.charts.filter(item => ids.includes(item.id));
        return;
      }
      state.charts = [];
    },
    ACTIVE_CHILD: (state, value) => {
      const currentCharts = getActiveChart(state.charts);
      const activeChild = getActiveChildForChart(currentCharts);
      if (activeChild) {
        activeChild.isActive = false;
      }
      currentCharts.data[value.childIndex].isActive = true;
    },
    UPDATE: (state, value) => {
      const i = state.charts.findIndex(item => item.id == value.id);
      state.charts[i] = value;
    },
    CLEAR: (state, value) => {
      state.charts = [];
    },
    CLEAR_CHILD: state => {
      const currentCharts = getActiveChart(state.charts);
      const activeChild = getActiveChildForChart(currentCharts);
      if (activeChild) {
        activeChild.data.columns = [];
        activeChild.data.rows = [];
        activeChild.data.varDatas = [];
        activeChild.xValues = null;
        activeChild.yRange[0] = 0;
        activeChild.yRange[1] = 1;
      }
    },
    LOG: (state, value) => {
      state.log = Object.assign({}, state.log, value);
    },
    OPERATE: (state, value) => {
      state.action = value.action;
      if (value.taskId) {
        state.taskId = value.taskId;
      }
    }
  },
  getters: {
    charts: state => state.charts,
    activeChart: state => {
      return getActiveChart(state.charts);
    },
    activeChildChart: (state, getters) => {
      return getActiveChildForChart(getters.activeChart);
    },
    log: state => state.log
  },
  actions: {
    // 添加图表实例并且绑定视图
    add({ commit, state, dispatch }, value) {
      return new Promise(resolve => {
        const { view, varData } = value;
        const taskId = varData ? varData.taskId : '';
        const chart = createChart(value, taskId);
        if (varData) {
          loadVariable(chart, varData);
        }
        commit('ADD', chart);
        resolve(chart);
      });
    },
    addChild({ commit, state, dispatch }, value) {
      return new Promise(resolve => {
        const { view } = value;
        const currentCharts = getActiveChart(state.charts);
        // 这里循环删除数据,倒序删除防止下标错误
        let i = currentCharts.data.length;
        while (i--) {
          if (
            currentCharts.data[i].x > view.px ||
            currentCharts.data[i].y > view.py
          ) {
            commit('DELETE_CHILD', i);
          }
        }
        // 这里循环添加数据
        for (let i = 0; i <= view.px; i++) {
          for (let j = 0; j <= view.py; j++) {
            const tempChild = currentCharts.data.find(
              item => item.x === i && item.y === j
            );
            if (!tempChild) {
              const chart = createChartChild(i, j);
              commit('ADD_CHILD', chart);
            }
          }
        }

        resolve(currentCharts);
      });
    },
    // 删除当前
    delete({ commit, state, dispatch }, value) {
      return new Promise(resolve => {
        commit('DELETE', value);
        resolve();
      });
    },
    // 删除其他、删除所有
    deleteAll({ commit, state, dispatch }, value) {
      return new Promise(resolve => {
        commit('DELETE_ALL', value);
        resolve();
      });
    },
    // 更新chart数据
    update({ commit, state, dispatch }, value) {
      return new Promise(resolve => {
        // 这里chart不应该传进来，直接取activeChart
        const { varData, isChecked } = value;
        const currentCharts = getActiveChart(state.charts);
        let activeChart = getActiveChildForChart(currentCharts);
        if (
          currentCharts.taskId &&
          varData.taskId &&
          varData.taskId != currentCharts.taskId
        ) {
          notify(i18n.t('store.diff'), 'warning');
          return;
        }
        if (isChecked) {
          // 判断是否包含变量
          loadVariable(currentCharts, varData);
        } else {
          let y = varData.path;
          activeChart.data.columns = activeChart.data.columns.filter(
            (item, index) => item != y || index === 0
          );
          // 这里要将rows也清掉,否则tooltip异常,也要处理y轴Range
          activeChart.data.rows = activeChart.data.rows.filter(
            item => item.name != y
          );
          // 这里将varDatas也清理
          activeChart.data.varDatas = activeChart.data.varDatas.filter(
            item => item.path != y
          );
          // 删除之后重新计算yRange
          updateYRange(currentCharts, activeChart, varData);
        }
        // 更新当前曲线的任务id
        const childs = currentCharts.data;
        let hasData = false;
        for (let i = 0; i < childs.length; i++) {
          if (childs[i].data.varDatas.length) {
            hasData = true;
            break;
          }
        }
        if (hasData) {
          if (!currentCharts.taskId) {
            currentCharts.taskId = varData.taskId;
            commit('UPDATE', currentCharts);
          }
        } else {
          currentCharts.taskId = '';
          commit('UPDATE', currentCharts);
        }
      });
    },
    activeChild({ commit, dispatch, state }, value) {
      return new Promise(resolve => {
        commit('ACTIVE_CHILD', value);
        resolve();
      });
    },
    clear({ commit, dispatch, state }, value) {
      commit('CLEAR');
    },
    clearChild({ commit, dispatch, state }, value) {
      commit('CLEAR_CHILD');
    },
    // 仿真日志
    log({ commit, dispatch, state }, value) {
      commit('LOG', value);
    },
    // 移除子窗口，行、列（row/column）
    removeChild({ commit, dispatch, state }, type) {
      const activeTab = store.getters['tabs/activeTab'];
      const currentCharts = getActiveChart(state.charts);
      const activeChild = getActiveChildForChart(currentCharts);
      // 这里循环删除数据,倒序删除防止下标错误
      let i = currentCharts.data.length;
      if (type === 'row' && activeTab.px >= 1) {
        while (i--) {
          if (currentCharts.data[i].x === activeChild.x) {
            commit('DELETE_CHILD', i);
          }
        }

        // 修改tab 以及将 后面的x-1
        activeTab.px -= 1;
        currentCharts.data.forEach(item => {
          if (item.x > activeChild.x) {
            item.x -= 1;
          }
        });
      } else if (type === 'column' && activeTab.py >= 1) {
        while (i--) {
          if (currentCharts.data[i].y === activeChild.y) {
            commit('DELETE_CHILD', i);
          }
        }

        // 修改tab 以及将 后面的x-1
        activeTab.py -= 1;
        currentCharts.data.forEach(item => {
          if (item.y > activeChild.y) {
            item.y -= 1;
          }
        });
      }
    }
  }
};
