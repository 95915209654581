/**
 * 创建曲线窗口实例id
 * @param data
 * @returns {*}
 */
import store from '@/store';

function createId(data) {
  let id;
  let ids = []; // 当前id集合
  data.forEach(item => {
    ids.push(item.id);
  });
  for (let i = 1; i < 10000000; i++) {
    if (!ids.includes(i)) {
      id = i;
      break;
    }
  }
  return id;
}

/**
 * 创建曲线窗口实例
 * @param data --曲线窗口数据
 * @returns {*}
 */
function createTab(data) {
  const temp = {
    type: data.type,
    label: data.label,
    id: data.id,
    // FIXME： showGrid,showTooltip 不用维护在数据中，放在plot中即可，后期修改
    isActive: true,
    showGrid: true,
    showTooltip: false,
    x: data.x,
    y: data.y,
    px: 0,
    py: 0,
    z: 'auto'
  };
  return temp;
}

/**
 * @param {*} getActiveTab
 */
function getActiveTab(tabs) {
  return tabs.find(item => item.isActive);
}

/**
 * 计算tab的位置信息
 * @param {*} state
 */
function getTabPosition(state) {
  const x = state.layout.y + 1;
  const y = state.layout.x + 1;
  let tabs = state.tabs;
  let layout = {
    x: 0,
    y: 0
  };
  for (let j = 1; j <= y; j++) {
    for (let i = 1; i <= x; i++) {
      let index = tabs.findIndex(item => item.x === i && item.y === j);
      if (index === -1) {
        layout.x = i;
        layout.y = j;
        return layout;
      }
    }
  }
}

export default {
  namespaced: true,
  state: {
    tabs: [],
    layout: {
      x: 0,
      y: 0
    }
  },
  mutations: {
    ADD: (state, value) => {
      state.tabs.forEach(item => {
        // if (item.type == value.type) {
        item.isActive = false;
        // }
      });
      state.tabs.push(value);
    },
    DELETE: (state, value) => {
      state.tabs = state.tabs.filter(item => item.id != value.id);
    },
    DELETE_ALL: (state, value) => {
      const { id, type } = value;
      if (id) {
        state.tabs = state.tabs.filter(
          item => item.type != type || item.id == id
        );
      } else {
        state.tabs = state.tabs.filter(item => item.type != type);
      }
    },
    ACTIVE: (state, value) => {
      let { id, type } = value;
      if (!id) {
        // 默认最后一个为当前状态
        let i = state.tabs.findIndex(
          // item => item.type == type && item.isActive
          item => item.isActive
        );
        if (i <= -1 && state.tabs.length) {
          id = state.tabs[state.tabs.length - 1].id;
        } else {
          return false;
        }
      }
      state.tabs.forEach(item => {
        // if (item.type == type) {
        if (item.id == id) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
        // }
      });
    },
    CLEAR: (state, value) => {
      state.tabs = [];
    },
    UPDATE_ATTR: (state, key) => {
      const activeTab = getActiveTab(state.tabs);
      activeTab[key] = !activeTab[key];
    },
    CHANGE_LAYOUT: (state, layout) => {
      state.layout.x = layout.x;
      state.layout.y = layout.y;
      let x = layout.y + 1;
      let y = layout.x + 1;
      // 这里循环删除大于窗口数量的数据,倒序删除防止下标错误
      let i = state.tabs.length;
      while (i--) {
        if (state.tabs[i].x > x || state.tabs[i].y > y) {
          // 删除之前将simulate中的charts也删掉
          let charts = store.getters['simulate/charts'];
          let content = charts.filter(item => item.view.id == state.tabs[i].id);
          if (content) {
            store.commit('simulate/DELETE', content);
          }
          state.tabs.splice(i, 1);
        }
      }
    }
  },
  getters: {
    tabs: state => state.tabs,
    activeTab: state => {
      return getActiveTab(state.tabs);
    },
    layout: state => state.layout
  },
  actions: {
    add({ commit, state, dispatch }, value) {
      return new Promise(resolve => {
        if ((state.layout.x + 1) * (state.layout.y + 1) > state.tabs.length) {
          // const s = (state.tabs.length % 5) * 20;
          value.id = value.id || createId(state.tabs);
          if (value.x === undefined || value.y === undefined) {
            const layout = getTabPosition(state);
            value.x = layout.x;
            value.y = layout.y;
          }
          // 20220615 修改布局，给每个tab添加坐标,这里将原来的x,y修改，多出X*Y这里不再创建窗口
          const temp = createTab(value);
          commit('ADD', temp);
          resolve(temp);
        } else {
          resolve(false);
        }
      });
    },
    delete({ commit, state, dispatch }, value) {
      return new Promise(resolve => {
        commit('DELETE', value);
        dispatch('active');
        resolve();
      });
    },
    deleteAll({ commit, state, dispatch }, value) {
      return new Promise(resolve => {
        commit('DELETE_ALL', value);
        resolve();
      });
    },
    active({ commit, state, dispatch }, value = {}) {
      return new Promise(resolve => {
        commit('ACTIVE', value);
        resolve();
      });
    },
    clear({ commit, dispatch, state }, value) {
      commit('CLEAR');
    },
    updateAttr({ commit, dispatch, state }, key) {
      return new Promise(resolve => {
        commit('UPDATE_ATTR', key);
        resolve();
      });
    },
    changeLayout({ commit, dispatch, state }, layout) {
      commit('CHANGE_LAYOUT', layout);
    }
  }
};
