<template>
  <div class="page">
    <img src="../../assets/images/505.png" />
    <div class="content">
      <div class="title">
        {{ $t('app.message505') }}
      </div>
      <el-button class="btn" type="primary" @click="handleClick">{{
        $t('app.goBack')
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    handleClick() {
      window.location.href = '/appStore';
    }
  }
};
</script>

<style scoped lang="scss">
.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 36px;
  font-weight: 600;
}
.btn {
  margin-top: 60px;
}
</style>
