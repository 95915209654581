import { Notification, Message, MessageBox } from 'element-ui';
import i18n from '../core/lang';
/**
 * 通知
 * @param {type} 通知类型
 * @param {message} 通知内容
 */
window.notify = function(message, type = 'success', duration = 2000) {
  let title;
  switch (type) {
    case 'warning':
      title = i18n.t('common.tip');
      break;
    case 'info':
      title = i18n.t('common.info');
      break;
    case 'error':
      title = i18n.t('common.fail');
      break;
    default:
      title = i18n.t('common.success');
  }
  Notification({
    type,
    title,
    message,
    duration,
    dangerouslyUseHTMLString: true
  });
};

/**
 * 消息
 * @param {type} 消息类型
 * @param {message} 消息内容
 */
window.message = function(type, message, duration = 2000) {
  Message({
    type,
    message,
    duration
  });
};

window.ctrlKey = navigator.appVersion.indexOf('Mac') > 0 ? 'Cmd' : 'Ctrl';
window.IS_WIN = navigator.appVersion.indexOf('Win') > 0;
window.IS_IOS = /iP(hone|od|ad)/.test(navigator.platform);
window.IS_IE =
  navigator.userAgent != null && navigator.userAgent.indexOf('MSIE') >= 0;

/**
 * confirm
 * @desc 确认弹窗
 * @param {message} 确认内容
 *
 */
window.confirm = function(message, title = i18n.t('common.tip'), options) {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(message, title, options)
      .then(res => {
        console.log(res, 'res');
        resolve(res);
      })
      .catch(err => {
        console.log(err, 'err');
        reject(err);
      });
  }).catch(() => {});
};
